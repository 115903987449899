<template>
    <v-container>
      <span class="bg"></span>
    				     <v-col cols="12" class="mt-n7 mb-15 px-3" align="center">
      <v-btn-toggle dark tile>
        <v-btn elevation="0" tile min-width="130px" :ripple="false" color="#202025" style="opacity: 0.8; border: none;" to="/documentation/intro">Intro</v-btn>
        <v-btn elevation="0" tile min-width="130px" :ripple="false" color="#202025" style="opacity: 0.8; border: none;" to="/documentation/commands">Commands</v-btn>
	  </v-btn-toggle>
          <v-btn-toggle dark tile>
        <v-btn elevation="0" tile min-width="130px" :ripple="false" color="#202025" style="opacity: 0.8; border: none;" to="/documentation/advanced">Advanced</v-btn>
        <v-btn elevation="0" tile min-width="130px" :ripple="false" color="#202025" style="opacity: 0.8; border: none;" to="/documentation/reference">Reference</v-btn>
	  </v-btn-toggle>
    <v-btn-toggle dark tile>
        <v-btn elevation="0" tile min-width="130px" :ripple="false" color="#202025" style="opacity: 0.8; border: none;" to="/documentation/guides">Guides</v-btn>
        <v-btn elevation="0" tile min-width="130px" :ripple="false" color="red lighten-1" style="opacity: 1; border: none;" to="/documentation/api">API</v-btn>
	  </v-btn-toggle>
	  
	  
    </v-col>
  <v-card height="100%" color="primary" class="mt-n8 mx-3">
    <v-card-text style="color: white;">
      <v-expansion-panels flat multiple tile dark class="my-2 px-0">
        <v-expansion-panel active-class="panel" class="my-1">
                  <v-expansion-panel-header style="font-size: 18px;" color="#18191c">
                    <v-row>
                      <v-col>
                    <v-col cols="12" class="mb-n5">
                      <span>Authorization</span>
                    </v-col>
                    <v-col cols="12" class="mb-0">
                    <span style="color:gray; font-size: 15px;">How to authorize for requests that require authorization.</span>
                    </v-col>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content style="background-color: #373a3e;" class="pt-5">
                   Some requests will require you to authorize because the retrieved data is sensitive. Every server has an API key that only members with admin or manage server permissions can access.<br>The API key can be shown and refreshed with the /apikey command in your server.
                   If you accidentally shared your servers API key with someone that you don't trust, you should immediately refresh the API key to prevent any abuse.<br><br>
                   <span style="font-weight: 800; color: lime;">The authorization is done by adding a header to your request with the name "Authorization" and the API key as the value.</span>
                  </v-expansion-panel-content>
              </v-expansion-panel>
              <h1 class="mb-7 mt-10">Endpoints</h1>
              <v-expansion-panel active-class="panel" class="my-1">
                  <v-expansion-panel-header style="font-size: 15px;" color="#18191c">
                    <v-row>
                      <v-col cols="auto" class="mb-0" style="min-width: 90px; text-align: center; justify-content: center; padding-top: 35px;">
                        <div style="font-weight: 800; font-size: 20px; color: #2596be; margin: auto; text-align: center;">GET</div>
                      </v-col>
                      <v-col>
                    <v-col cols="12" class="mb-n4">
                      <span style="word-break: break-word;">https://raid-helper.dev/api/v2/events/<span style="color: purple;">EVENTID</span></span>
                    </v-col>
                    <v-col cols="12" class="mb-0">
                    <span style="color:gray; font-size: 15px;">Fetch the full data of a single event. Does not require authorization!</span>
                    </v-col>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content style="background-color: #373a3e;" class="pt-5">
                                    <v-row justify="center">
                  <v-btn style="text-transform: none;" elevation="3" depressed x-small color="purple darken-2" class="pa-2 mr-3 mb-8 mt-5" @click="copyText('https://raid-helper.dev/api/v2/events/EVENTID')">Copy URL</v-btn>
                  <v-btn style="text-transform: none;" elevation="3" depressed x-small color="purple darken-2" class="pa-2 mr-3 mb-8 mt-5" @click="copyText('curl --request GET \
  --url https://raid-helper.dev/api/v2/events/EVENTID')">Copy cURL</v-btn>
                </v-row>	
                   This request will return you the full data of the specified event. No authorization is required. Because this is a GET request it can be easily accessed via the browser. Here is an <a href="https://raid-helper.dev/api/v2/events/998707032230203474" target="_blank" style="color: lightblue;">example</a>.
                   If you wish to view the JSON in a structured way inside your browser, consider installing a chrome extension like <a href="https://chrome.google.com/webstore/detail/jsonvue/chklaanhfefbnpoihckbnefhakgolnmc" target="_blank" style="color: lightblue;">JSONVue</a> to format the data properly for you.
                   
                   <h1 class="mt-10 mb-7">Request Structure</h1>
                   <v-expansion-panel class="my-1">
                  <v-expansion-panel-header style="font-size: 18px;" color="#18191c">
                    <v-row>
                      <div><span class='px-2 py-1 field2'>Headers</span> <span style="color: grey; font-size: 15px;" class="mx-2"></span></div><br>
                    </v-row>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content style="background-color: #1c1d21a6; line-height: 30px;" class="pt-5">
                    <span class='px-2 py-1 pill1'>Authorization</span><span class='px-2 py-1 pill2'>&lt;apikey&gt;</span>
                        <v-tooltip top max-width="600" transition="v-scale-transition" color="#0f0f0f">
                          <template v-slot:activator="{ on }">
                            <span v-on="on" class='ml-1 px-1 py-1 pill3'><v-icon style="margin-bottom: 2px;" x-small color="green">mdi-help</v-icon></span>
                          </template>
                          <span style="color: lightgreen;">This is not a required field, it can still be provided if you want the ratelimiting to be applied to the key instead of your IP. Useful when using google sheets for example.</span>
                        </v-tooltip>
                     - Your servers api key which you can get and refresh via /apikey.                         

                  </v-expansion-panel-content>
              </v-expansion-panel>
                   
                   <h1 class="mt-10 mb-7">Response Structure</h1>
                   <v-expansion-panel class="my-1">
                  <v-expansion-panel-header style="font-size: 18px;" color="#18191c">
                    <v-row>
                      <div><span class='px-2 py-1 field2'>Body</span></div><br>
                    </v-row>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content style="background-color: #1c1d21a6; line-height: 30px;" class="pt-5">

                            <span class='px-2 py-1 field1'>id</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The message id of this event.<br>
                    <span class='px-2 py-1 field1'>serverId</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The server id of this event.<br>
                    <span class='px-2 py-1 field1'>leaderId</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The user id of this events leader.<br>
                    <span class='px-2 py-1 field1'>leaderName</span><span class='px-2 py-1 field2'>&lt;string&gt;</span>- The name of events leader.<br>
                    <span class='px-2 py-1 field1'>channelId</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - This events channel id.<br>
                    <span class='px-2 py-1 field1'>channelName</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - This events channel name.<br>
                    <span class='px-2 py-1 field1'>channelType</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The type of this events channel.<br>
                    <span class='px-2 py-1 field1'>templateId</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The id of this events template.<br>
                    <span class='px-2 py-1 field1'>templateEmoteId</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id of the emote used to represent the template of this event.<br>
                    <span class='px-2 py-1 field1'>title</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The event title.<br>
                    <span class='px-2 py-1 field1'>description</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The event description.<br>
                    <span class='px-2 py-1 field1'>startTime</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The unix timestamp of when this event will start.<br>
                    <span class='px-2 py-1 field1'>endTime</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The unix timestamp of when this event will end.<br>
                    <span class='px-2 py-1 field1'>closingTime</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The unix timestamp of when this event will close and deny further sign-ups.<br>
                    <span class='px-2 py-1 field1'>date</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The raw date string of when this event will start.<br>
                    <span class='px-2 py-1 field1'>time</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The raw time string of when this event will start.<br>
                    <span class='px-2 py-1 field1'>advancedSettings</span><span class='px-2 py-1 field2'><button @click="advancedSettings = !advancedSettings" label>
                            <span style="color: #adb1b6;">&lt;object&gt;</span><v-icon v-if="!advancedSettings" small color="#adb1b6">mdi-chevron-down</v-icon><v-icon v-else small color="#adb1b6">mdi-chevron-up</v-icon>
                          </button></span> - The advanced settings for this event.<br>
                          <div style="border-left: 2px solid grey; line-height: 30px;" class="ml-5 px-3 my-2 mb-5" v-if="advancedSettings">
                            <span class='px-2 py-1 field1'>duration</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The duration of the event in minutes.<br>
                            <span class='px-2 py-1 field1'>deadline</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The deadline in hours before the event. <br>
                            <span class='px-2 py-1 field1'>limit</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The maximum amount of active sign-ups.<br>
                            <span class='px-2 py-1 field1'>lock_at_limit</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether the event will lock when the limit is reached.<br>
                            <span class='px-2 py-1 field1'>limit_per_user</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The maximum amount of sign-ups per member.<br>
                            <span class='px-2 py-1 field1'>lower_limit</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The minimum amount of sign-ups required for the event to happen.<br>
                            <span class='px-2 py-1 field1'>allow_duplicate</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether a member can sign up multiple times to the same class.<br>
                            <span class='px-2 py-1 field1'>horizontal_mode</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether sign-ups will be displayed horizontally instead of vertically.<br>
                            <span class='px-2 py-1 field1'>bench_overflow</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether sign-ups past limits will be benched or simply denied.<br>
                            <span class='px-2 py-1 field1'>queue_bench</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - Changes the bench behaviour to a queue.<br>
                            <span class='px-2 py-1 field1'>vacuum</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - Clears the last 10 non-event messages in the channel upon event creation.<br>
                            <span class='px-2 py-1 field1'>force_reminders</span><span class='px-2 py-1 field2'>&lt;boolean/number&gt;</span> - Force personal reminders. Time in minutes before the event.<br>
                            <span class='px-2 py-1 field1'>pin_message</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether this event should be pinned upon creation.<br>
                            <span class='px-2 py-1 field1'>deletion</span><span class='px-2 py-1 field2'>&lt;boolean/number&gt;</span> - The amount of hours this event will be deleted after it concluded.<br>
                            <span class='px-2 py-1 field1'>mention_mode</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - Mentions the members instead of displaying the plain name.<br>
                            <span class='px-2 py-1 field1'>preserve_order</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - Determines the behaviour of the order numbers when members change their sign-up.<br>
                            <span class='px-2 py-1 field1'>apply_unregister</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether the unregister role will be applied.<br>
                            <span class='px-2 py-1 field1'>apply_specreset</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - whether the reaction/button to reset the saved spec should be applied to the event.<br>
                            <span class='px-2 py-1 field1'>spec_saving</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether the bot should remember a members spec choice and apply it automatically.<br>
                            <span class='px-2 py-1 field1'>font_style</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The font style for the event title. Only applies if the title is shorter than 19 characters.<br>
                            <span class='px-2 py-1 field1'>alt_names</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether members will be allowed to enter a custom name when signing up.<br>
                            <span class='px-2 py-1 field1'>defaults_pre_req</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether a primary role is required to sign up with a default role (bench, late, tentative).<br>
                            <span class='px-2 py-1 field1'>show_on_overview</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether the event will be displayed on the /overview.<br>
                            <span class='px-2 py-1 field1'>mention_leader</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether the leaders name will be displayed as a mention.<br>
                            <span class='px-2 py-1 field1'>attendance</span><span class='px-2 py-1 field2'>&lt;boolean/string&gt;</span> - whether this event will count towards attendance. Enter a string to set an attendance tag.<br>
                            <span class='px-2 py-1 field1'>show_title</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether to show the title of this event.<br>
                            <span class='px-2 py-1 field1'>show_info</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether the info row will be shown (contains date, time and number of sign-ups).<br>
                            <span class='px-2 py-1 field1'>show_leader</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether the event leader will be shown.<br>
                            <span class='px-2 py-1 field1'>show_counter</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether the sign-ups counter will be shown.<br>
                            <span class='px-2 py-1 field1'>show_roles</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether to show the role counters above the sign-up content.<br>
                            <span class='px-2 py-1 field1'>show_content</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether the sign-ups will be displayed.<br>
                            <span class='px-2 py-1 field1'>show_classes</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether the class fields will always be shown.<br>
                            <span class='px-2 py-1 field1'>show_emotes</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether to show the spec emotes in front of sign-ups.<br>
                            <span class='px-2 py-1 field1'>show_numbering</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether to show the order number in front of sign-ups.<br>
                            <span class='px-2 py-1 field1'>show_allowed</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether to show the allowed roles in the footer if any are set.<br>
                            <span class='px-2 py-1 field1'>show_footer</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether to show the event footer.<br>
                            <span class='px-2 py-1 field1'>info_variant</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - whether the info field will be displayed in a short or long format.<br>
                            <span class='px-2 py-1 field1'>date_variant</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - whether the date & time on the event will be shown in the users local or zoned time.<br>
                            <span class='px-2 py-1 field1'>show_countdown</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether to show a countdown to the event start.<br>
                            <span class='px-2 py-1 field1'>disable_archiving</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - If archiving is enabled on your server you can exempt a specific event with this setting.<br>
                            <span class='px-2 py-1 field1'>bold_all</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - Set to false to not count consecutive sign-ups by a user to be counted towards limits.<br>
                            <span class='px-2 py-1 field1'>bench_emote</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id that will be used for the bench role. Set to "remove" to disable the role.<br>
                            <span class='px-2 py-1 field1'>late_emote</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id that will be used for the late role. Set to "remove" to disable the role.<br>
                            <span class='px-2 py-1 field1'>tentative_emote</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id that will be used for the tentative role. Set to "remove" to disable the role.<br>
                            <span class='px-2 py-1 field1'>absence_emote</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id that will be used for the absence role. Set to "remove" to disable the role.<br>
                            <span class='px-2 py-1 field1'>leader_emote</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id that will be used for the leader icon.<br>
                            <span class='px-2 py-1 field1'>signups1_emote</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id that will be used for the signups icon.<br>
                            <span class='px-2 py-1 field1'>signups2_emote</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id that will be used for the signups icon.<br>
                            <span class='px-2 py-1 field1'>date1_emote</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id that will be used for the date icon.<br>
                            <span class='px-2 py-1 field1'>date2_emote</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id that will be used for the date icon.<br>
                            <span class='px-2 py-1 field1'>time1_emote</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id that will be used for the time icon.<br>
                            <span class='px-2 py-1 field1'>time2_emote</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id that will be used for the time icon.<br>
                            <span class='px-2 py-1 field1'>countdown1_emote</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id that will be used for the countdown icon.<br>
                            <span class='px-2 py-1 field1'>countdown2_emote</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id that will be used for the countdown icon.<br>
                            <span class='px-2 py-1 field1'>event_type</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - whether this event will use interactions or reactions.<br>
                            <span class='px-2 py-1 field1'>reminder</span><span class='px-2 py-1 field2'>&lt;number/boolean&gt;</span> - Set the amount of minutes before the event a reminder will be sent.<br>
                            <span class='px-2 py-1 field1'>create_discordevent</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether a discord integrated event should be created (requires premium).<br>
                            <span class='px-2 py-1 field1'>create_thread</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether a thread should be created on the event.<br>
                            <span class='px-2 py-1 field1'>delete_thread</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether the attached thread should be deleted if the event gets deleted.<br>
                            <span class='px-2 py-1 field1'>voice_channel</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The voicechannel used for this event.<br>
                            <span class='px-2 py-1 field1'>temp_voicechannel</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The name for a voicechannel that will be temporarily created.<br>
                            <span class='px-2 py-1 field1'>color</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The embed color for this event.<br>
                            <span class='px-2 py-1 field1'>response</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - Enter text that will be sent to the member when they sign up.<br>
                            <span class='px-2 py-1 field1'>temp_role</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The name of a discord role that will be assigned to the member upon signing up.<br>
                            <span class='px-2 py-1 field1'>allowed_roles</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The role names that are allowed to sign up. Separate multiple names by comma.<br>
                            <span class='px-2 py-1 field1'>forum_tags</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The names of the forum tags that will be applied to the post. Separate multiple names by comma.<br>
                            <span class='px-2 py-1 field1'>banned_roles</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - the role names that are banned from signing up. Separate multiple names by comma.<br>
                            <span class='px-2 py-1 field1'>opt_out</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The id of an existing event to copy the sign-ups from upon event creation.<br>
                            <span class='px-2 py-1 field1'>mentions</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The role/member names to mention upon event creation. Separate multiple names by comma.<br>
                            <span class='px-2 py-1 field1'>image</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The URL of an image that will be displayed at the bottom of the event embed.<br>
                            <span class='px-2 py-1 field1'>thumbnail</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The URL to an image which will be displayed as a thumbnail on the event embed.<br>
                            <span class='px-2 py-1 field1'>use_nicknames</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - whether the members server nickname will be used or the global name.<br>

                          </div>
                    <span class='px-2 py-1 field1'>classes</span><span class='px-2 py-1 field2'><button @click="classes = !classes" label>
                            <span style="color: #adb1b6;">&lt;array of objects&gt;</span><v-icon v-if="!classes" small color="#adb1b6">mdi-chevron-down</v-icon><v-icon v-else small color="#adb1b6">mdi-chevron-up</v-icon>
                          </button></span> - The classes that are applied to this event.<br>
                          <div style="border-left: 2px solid grey; line-height: 30px;" class="ml-5 px-3 my-2 mb-5" v-if="classes">
                            <span class='px-2 py-1 field1'>name</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The name of this class.<br>
                            <span class='px-2 py-1 field1'>limit</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The maximum allowed sign-ups for this class.<br>
                            <span class='px-2 py-1 field1'>emoteId</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id of this class.<br>
                            <span class='px-2 py-1 field1'>type</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The type (primary/default) of this class.<br>
                            <span class='px-2 py-1 field1'>specs</span><span class='px-2 py-1 field2'><button @click="specs = !specs" label>
                            <span style="color: #adb1b6;">&lt;array of objects&gt;</span><v-icon v-if="!specs" small color="#adb1b6">mdi-chevron-down</v-icon><v-icon v-else small color="#adb1b6">mdi-chevron-up</v-icon>
                          </button></span> - The classes that are applied to this event.<br>
                          <div style="border-left: 2px solid grey; line-height: 30px;" class="ml-5 px-3 my-2 mb-5" v-if="specs">
                            <span class='px-2 py-1 field1'>name</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The name of this spec.<br>
                            <span class='px-2 py-1 field1'>emoteId</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id of this spec.<br>
                            <span class='px-2 py-1 field1'>roleName</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The name of the role that this spec belongs to.<br>
                            <span class='px-2 py-1 field1'>roleEmoteId</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id of the role that this spec belongs to.<br>
                          </div>
                          </div>
                    <span class='px-2 py-1 field1'>roles</span><span class='px-2 py-1 field2'><button @click="roles = !roles" label>
                            <span style="color: #adb1b6;">&lt;array of objects&gt;</span><v-icon v-if="!roles" small color="#adb1b6">mdi-chevron-down</v-icon><v-icon v-else small color="#adb1b6">mdi-chevron-up</v-icon>
                          </button></span> - The roles that are applied to this event.<br>
                          <div style="border-left: 2px solid grey; line-height: 30px;" class="ml-5 px-3 my-2 mb-5" v-if="roles">
                            <span class='px-2 py-1 field1'>name</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The name of this role.<br>
                            <span class='px-2 py-1 field1'>limit</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The maximum allowed sign-ups for this role.<br>
                            <span class='px-2 py-1 field1'>emoteId</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id of this role.<br>
                          </div>
                    <span class='px-2 py-1 field1'>signUps</span><span class='px-2 py-1 field2'><button @click="signUps = !signUps" label>
                            <span style="color: #adb1b6;">&lt;array of objects&gt;</span><v-icon v-if="!signUps" small color="#adb1b6">mdi-chevron-down</v-icon><v-icon v-else small color="#adb1b6">mdi-chevron-up</v-icon>
                          </button></span> - The current sign-ups on this event.<br>
                          <div style="border-left: 2px solid grey; line-height: 30px;" class="ml-5 px-3 my-2 mb-5" v-if="signUps">
                            <span class='px-2 py-1 field1'>name</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The name of the user.<br>
                            <span class='px-2 py-1 field1'>id</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The id of this sign-up. <br>
                            <span class='px-2 py-1 field1'>userId</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The Discord id of the user. <br>
                            <span class='px-2 py-1 field1'>className</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The class name of the sign-up.<br>
                            <span class='px-2 py-1 field1'>classEmoteId</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The class emote id of the sign-up.<br>
                            <span class='px-2 py-1 field1'>specName</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - the spec name of the sign-up.<br>
                            <span class='px-2 py-1 field1'>specEmoteId</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The spec emote id of the sign-up.<br>
                            <span class='px-2 py-1 field1'>roleName</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The role name of the sign-up.<br>
                            <span class='px-2 py-1 field1'>roleEmoteId</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The role emote id of the sign-up.<br>
                            <span class='px-2 py-1 field1'>status</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The status (primary/queued) of the sign-up.<br>
                            <span class='px-2 py-1 field1'>entryTime</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The unix timestamp of the registration time.<br>
                            <span class='px-2 py-1 field1'>position</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The order number of this sign-up.<br>
                          </div>
                    <span class='px-2 py-1 field1'>lastUpdated</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The unix timestamp of when this event was updated last.<br>
                    <span class='px-2 py-1 field1'>softresId</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The softres id attached to this event.<br>
                    <span class='px-2 py-1 field1'>color</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The current embed color in RGB format.<br>
                    

                  </v-expansion-panel-content>
              </v-expansion-panel>
           
                  </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel active-class="panel" class="my-1">
                  <v-expansion-panel-header style="font-size: 15px;" color="#18191c">
                    <v-row>
                      <v-col cols="auto" class="mb-0" style="min-width: 90px; text-align: center; justify-content: center; padding-top: 35px;">
                        <div style="font-weight: 800; font-size: 20px; color: #2596be; margin: auto; text-align: center;">GET</div>
                      </v-col>
                      <v-col>
                    <v-col cols="12" class="mb-n4">
                      <span style="word-break: break-word;">https://raid-helper.dev/api/v3/servers/<span style="color: purple;">SERVERID</span>/events</span>
                    </v-col>
                    <v-col cols="12" class="mb-0">
                    <span style="color:gray; font-size: 15px;">Fetch general data of all events on your server.</span>
                    </v-col>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content style="background-color: #373a3e;" class="pt-5">
                                                        <v-row justify="center">
                  <v-btn style="text-transform: none;" elevation="3" depressed x-small color="purple darken-2" class="pa-2 mr-3 mb-8 mt-5" @click="copyText('https://raid-helper.dev/api/v3/servers/SERVERID/events')">Copy URL</v-btn>
                  <v-btn style="text-transform: none;" elevation="3" depressed x-small color="purple darken-2" class="pa-2 mr-3 mb-8 mt-5" @click="copyText('curl --request GET   --url https://raid-helper.dev/api/v3/servers/SERVERID/events   --header \'Authorization: enter your token here\'')">Copy cURL</v-btn>
                </v-row>	
                   This request will return an array of your posted events. The response will never contain more than 1000 events. If you have more than that please use the Page header to navigate to subsequent pages. The current page and event count as well as the overall pages and event count are returned in the body.
                   <h1 class="mt-10 mb-7">Request Structure</h1>
                   <v-expansion-panel class="my-1">
                  <v-expansion-panel-header style="font-size: 18px;" color="#18191c">
                    <v-row>
                      <div><span class='px-2 py-1 field2'>Headers</span> <span style="color: grey; font-size: 15px;" class="mx-2"></span></div><br>
                    </v-row>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content style="background-color: #1c1d21a6; line-height: 30px;" class="pt-5">
                    <span class='px-2 py-1 pill1'>Authorization</span><span class='px-2 py-1 pill2'>&lt;apikey&gt;</span>
                        <v-tooltip top max-width="600" transition="v-scale-transition" color="#0f0f0f">
                          <template v-slot:activator="{ on }">
                            <span v-on="on" class='ml-1 px-1 py-1 pill3'><v-icon style="margin-bottom: 2px;" x-small color="yellow">mdi-asterisk</v-icon></span>
                          </template>
                          <span style="color: yellow;">Required Field</span>
                        </v-tooltip>
                     - Your servers api key which you can get and refresh via /apikey.<br>                    
                     <span class='px-2 py-1 field1'>Page</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The page number to return. Maximum of 1000 events per page.<br>   
                     <span class='px-2 py-1 field1'>IncludeSignUps</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether to include the Sign-Ups of each event in the response.<br>
                    <span class='px-2 py-1 field1'>ChannelFilter</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The channel id to filter by. All channels are included if none provided.<br>
                    <span class='px-2 py-1 field1'>StartTimeFilter</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The unix timestamp of the earliest event to include.<br>
                    <span class='px-2 py-1 field1'>EndTimeFilter</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The unix timestamp of the latest event to include.<br>
                  </v-expansion-panel-content>
              </v-expansion-panel>
                   <h1 class="mt-10 mb-7">Response Structure</h1>
              <v-expansion-panel class="my-1">
                  <v-expansion-panel-header style="font-size: 18px;" color="#18191c">
                    <v-row>
                      <div><span class='px-2 py-1 field2'>Body</span> <span style="color: grey; font-size: 15px;" class="mx-2"></span></div><br>
                    </v-row>
                  </v-expansion-panel-header>

                  <v-expansion-panel-content style="background-color: #1c1d21a6; line-height: 30px;" class="pt-5">
                    <span class='px-2 py-1 field1'>pages</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The amount of pages available.<br>
                    <span class='px-2 py-1 field1'>currentPage</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The page number of the response.<br>
                    <span class='px-2 py-1 field1'>eventCountOverall</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The overall event count on your server.<br>
                    <span class='px-2 py-1 field1'>eventCountTransmitted</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The amount of events in this response.<br>
                    <span class='px-2 py-1 field1'>postedEvents</span><span class='px-2 py-1 field2'><button @click="postedEvents = !postedEvents" label>
                            <span style="color: #adb1b6;">&lt;array of objects&gt;</span><v-icon v-if="!postedEvents" small color="#adb1b6">mdi-chevron-down</v-icon><v-icon v-else small color="#adb1b6">mdi-chevron-up</v-icon>
                          </button></span> - Contains the objects of all posted events on this server.<br>
                          <div style="border-left: 2px solid grey; line-height: 30px;" class="ml-5 px-3 my-2 mb-5" v-if="postedEvents">
                            <span class='px-2 py-1 field1'>id</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The message id of this event.<br>
                            <span class='px-2 py-1 field1'>channelId</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The channel id of the channel where this event will be posted.<br>
                            <span class='px-2 py-1 field1'>leaderId</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The Discord id of the user that is set as this events leader.<br>
                            <span class='px-2 py-1 field1'>leaderName</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The name of the user that is set as this events leader.<br>
                            <span class='px-2 py-1 field1'>title</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The event title.<br>
                            <span class='px-2 py-1 field1'>description</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The description.<br>
                            <span class='px-2 py-1 field1'>startTime</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The unix timestamp (in seconds) of when this event will start.<br>
                            <span class='px-2 py-1 field1'>endTime</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The unix timestamp (in seconds) of when this event will end.<br>
                            <span class='px-2 py-1 field1'>closingTime</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The unix timestamp (in seconds) of when this event will close and deny further sign-ups.<br>
                            <span class='px-2 py-1 field1'>templateId</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The id of this events template.<br>
                            <span class='px-2 py-1 field1'>color</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The current embed color in RGB format.<br>
                            <span class='px-2 py-1 field1'>imageUrl</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The URL of the image attached to this event. Will not exist if no image is attached<br>
                            <span class='px-2 py-1 field1'>softresId</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The softres.it id of this event, if a softres is attached.<br>
                            <span class='px-2 py-1 field1'>lastUpdated</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The unix timestamp of when this event was updated last.<br>
                    <span class='px-2 py-1 field1'>signUps</span><span class='px-2 py-1 field2'><button @click="signUps = !signUps" label>
                            <span style="color: #adb1b6;">&lt;array of objects&gt;</span><v-icon v-if="!signUps" small color="#adb1b6">mdi-chevron-down</v-icon><v-icon v-else small color="#adb1b6">mdi-chevron-up</v-icon>
                          </button></span> - The current sign-ups on this event.<br>
                          <div style="border-left: 2px solid grey; line-height: 30px;" class="ml-5 px-3 my-2 mb-5" v-if="signUps">
                            <span class='px-2 py-1 field1'>name</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The name of the user.<br>
                            <span class='px-2 py-1 field1'>id</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The id of this sign-up. <br>
                            <span class='px-2 py-1 field1'>userId</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The Discord id of the user.<br>
                            <span class='px-2 py-1 field1'>className</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The class name of the sign-up.<br>
                            <span class='px-2 py-1 field1'>specName</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - the spec name of the sign-up.<br>
                            <span class='px-2 py-1 field1'>entryTime</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The unix timestamp of the entry time.<br>

                          </div>
                          </div>

                  </v-expansion-panel-content>
                  

                  
              </v-expansion-panel>
              
                  </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel active-class="panel" class="my-1">
                  <v-expansion-panel-header style="font-size: 15px;" color="#18191c">
                    <v-row>
                      <v-col cols="auto" class="mb-0" style="min-width: 90px; text-align: center; justify-content: center; padding-top: 35px;">
                        <div style="font-weight: 800; font-size: 20px; color: #2596be; margin: auto; text-align: center;">GET</div>
                      </v-col>
                      <v-col>
                    <v-col cols="12" class="mb-n4">
                      <span style="word-break: break-word;">https://raid-helper.dev/api/v3/servers/<span style="color: purple;">SERVERID</span>/scheduledevents</span>
                    </v-col>
                    <v-col cols="12" class="mb-0">
                    <span style="color:gray; font-size: 15px;">Fetch general data of all scheduled events on your server.</span>
                    </v-col>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content style="background-color: #373a3e;" class="pt-5">
                                                        <v-row justify="center">
                  <v-btn style="text-transform: none;" elevation="3" depressed x-small color="purple darken-2" class="pa-2 mr-3 mb-8 mt-5" @click="copyText('https://raid-helper.dev/api/v3/servers/SERVERID/scheduledevents')">Copy URL</v-btn>
                  <v-btn style="text-transform: none;" elevation="3" depressed x-small color="purple darken-2" class="pa-2 mr-3 mb-8 mt-5" @click="copyText('curl --request GET   --url https://raid-helper.dev/api/v3/servers/SERVERID/scheduledevents   --header \'Authorization: enter your token here\'')">Copy cURL</v-btn>
                </v-row>	
                   This request will return an array of your scheduled events.
                   <h1 class="mt-10 mb-7">Request Structure</h1>
                   <v-expansion-panel class="my-1">
                  <v-expansion-panel-header style="font-size: 18px;" color="#18191c">
                    <v-row>
                      <div><span class='px-2 py-1 field2'>Headers</span> <span style="color: grey; font-size: 15px;" class="mx-2"></span></div><br>
                    </v-row>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content style="background-color: #1c1d21a6; line-height: 30px;" class="pt-5">
                    <span class='px-2 py-1 pill1'>Authorization</span><span class='px-2 py-1 pill2'>&lt;apikey&gt;</span>
                        <v-tooltip top max-width="600" transition="v-scale-transition" color="#0f0f0f">
                          <template v-slot:activator="{ on }">
                            <span v-on="on" class='ml-1 px-1 py-1 pill3'><v-icon style="margin-bottom: 2px;" x-small color="yellow">mdi-asterisk</v-icon></span>
                          </template>
                          <span style="color: yellow;">Required Field</span>
                        </v-tooltip>
                     - Your servers api key which you can get and refresh via /apikey.                 
                  </v-expansion-panel-content>
              </v-expansion-panel>
                   <h1 class="mt-10 mb-7">Response Structure</h1>
              <v-expansion-panel class="my-1">
                  <v-expansion-panel-header style="font-size: 18px;" color="#18191c">
                    <v-row>
                      <div><span class='px-2 py-1 field2'>Body</span> <span style="color: grey; font-size: 15px;" class="mx-2"></span></div><br>
                    </v-row>
                  </v-expansion-panel-header>

                  <v-expansion-panel-content style="background-color: #1c1d21a6; line-height: 30px;" class="pt-5">

                          <span class='px-2 py-1 field1'>scheduledEvents</span><span class='px-2 py-1 field2'><button @click="scheduledEvents = !scheduledEvents" label>
                            <span style="color: #adb1b6;">&lt;array of objects&gt;</span><v-icon v-if="!scheduledEvents" small color="#adb1b6">mdi-chevron-down</v-icon><v-icon v-else small color="#adb1b6">mdi-chevron-up</v-icon>
                          </button></span> - Contains the objects of all scheduled and recurring events on this server.<br>
                          <div style="border-left: 2px solid grey; line-height: 30px;" class="ml-5 px-3 my-2 mb-5" v-if="scheduledEvents">
                            <span class='px-2 py-1 field1'>id</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The id of this scheduled or recurring event.<br>
                            <span class='px-2 py-1 field1'>channelId</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The channel id of the channel where this event will be posted.<br>
                            <span class='px-2 py-1 field1'>channelName</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The name of this events channel.<br>
                            <span class='px-2 py-1 field1'>channelType</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The type of the channel, i.e. text, forum, thread etc.<br>
                            <span class='px-2 py-1 field1'>leaderId</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The Discord id of the user that is set as this events leader.<br>
                            <span class='px-2 py-1 field1'>leaderName</span><span class='px-2 py-1 field2'>&lt;string&gt;</span>- The name of the user that is set as this events leader.<br>
                            <span class='px-2 py-1 field1'>title</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The event title.<br>
                            <span class='px-2 py-1 field1'>description</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The description.<br>
                            <span class='px-2 py-1 field1'>startTime</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The unix timestamp (in seconds) of when this event will start.<br>
                            <span class='px-2 py-1 field1'>endTime</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The unix timestamp (in seconds) of when this event will end.<br>
                            <span class='px-2 py-1 field1'>closingTime</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The unix timestamp (in seconds) of when this event will close and deny further sign-ups.<br>
                            <span class='px-2 py-1 field1'>postTime</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The unix timestamp (in seconds) of the next post time for this event.<br>
                            <span class='px-2 py-1 field1'>isScheduled</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether this event will repeat or not.<br>
                            <span class='px-2 py-1 field1'>recurringInterval</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The interval in days for this recurring event. 0 if it does not repeat.<br>
                            <span class='px-2 py-1 field1'>templateId</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The id of this events template.<br>
                            <span class='px-2 py-1 field1'>templateEmoteId</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id of the emote used to represent the template of this event.<br>
                            <span class='px-2 py-1 field1'>color</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The current embed color in RGB format.<br>
                            <span class='px-2 py-1 field1'>imageUrl</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The URL of the image attached to this event. Will not exist if no image is attached<br>
        
                          </div>
                  </v-expansion-panel-content>
                  

                  
              </v-expansion-panel>
              
                  </v-expansion-panel-content>
              </v-expansion-panel>


              <v-expansion-panel active-class="panel" class="my-1">
                  <v-expansion-panel-header style="font-size: 15px;" color="#18191c">
                    <v-row>
                      <v-col cols="auto" class="mb-0" style="min-width: 90px; text-align: center; justify-content: center; padding-top: 35px;">
                        <div style="font-weight: 800; font-size: 20px; color: yellow; margin: auto; text-align: center;">POST</div>
                      </v-col>
                      <v-col>
                    <v-col cols="12" class="mb-n4">
                      <span style="word-break: break-word;">https://raid-helper.dev/api/v2/servers/<span style="color: purple;">SERVERID</span>/channels/<span style="color: purple;">CHANNELID</span>/event</span>
                    </v-col>
                    <v-col cols="12" class="mb-0">
                    <span style="color:gray; font-size: 15px;">Create a new event.</span>
                    </v-col>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content style="background-color: #373a3e;" class="pt-5">
                      <v-row justify="center">
                  <v-btn style="text-transform: none;" elevation="3" depressed x-small color="purple darken-2" class="pa-2 mr-3 mb-8 mt-5" @click="copyText('https://raid-helper.dev/api/v2/servers/SERVERID/channels/CHANNELID/event')">Copy URL</v-btn>
                  <v-btn style="text-transform: none;" elevation="3" depressed x-small color="purple darken-2" class="pa-2 mr-3 mb-8 mt-5" @click="copyText(curl1)">Copy cURL</v-btn>
                      </v-row>	
                   This request is used to create an event. The server and channel are defined in the route. All other options are defined in the JSON body.
                   <h1 class="mt-10 mb-7">Request Structure</h1>
                   <v-expansion-panel class="my-1">
                  <v-expansion-panel-header style="font-size: 18px;" color="#18191c">
                    <v-row>
                      <div><span class='px-2 py-1 field2'>Headers</span> <span style="color: grey; font-size: 15px;" class="mx-2"></span></div><br>
                    </v-row>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content style="background-color: #1c1d21a6; line-height: 30px;" class="pt-5">
                    <span class='px-2 py-1 pill1'>Authorization</span><span class='px-2 py-1 pill2'>&lt;apikey&gt;</span>
                          <v-tooltip top max-width="600" transition="v-scale-transition" color="#0f0f0f">
                          <template v-slot:activator="{ on }">
                            <span v-on="on" class='ml-1 px-1 py-1 pill3'><v-icon style="margin-bottom: 2px;" x-small color="yellow">mdi-asterisk</v-icon></span>
                          </template>
                          <span style="color: yellow;">Required Field</span>
                        </v-tooltip> - Your servers api key which you can get and refresh via /apikey.                         
                      <br>
                      <span class='px-2 py-1 pill1'>Content-Type</span><span class='px-2 py-1 pill2'>application/json; charset=utf-8</span>
                            <v-tooltip top max-width="600" transition="v-scale-transition" color="#0f0f0f">
                          <template v-slot:activator="{ on }">
                            <span v-on="on" class='ml-1 px-1 py-1 pill3'><v-icon style="margin-bottom: 2px;" x-small color="yellow">mdi-asterisk</v-icon></span>
                          </template>
                          <span style="color: yellow;">Required Field</span>
                        </v-tooltip> - Defines the content type.                         
                          <br>
                  </v-expansion-panel-content>
              </v-expansion-panel>
                   <v-expansion-panel class="my-1">
                  <v-expansion-panel-header style="font-size: 18px;" color="#18191c">
                    <v-row>
                      <div><span class='px-2 py-1 field2'>Body</span> <span style="color: grey; font-size: 15px;" class="mx-2"></span></div><br>
                    </v-row>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content style="background-color: #1c1d21a6; line-height: 30px;" class="pt-5">
                    <span class='px-2 py-1 pill1'>leaderId</span><span class='px-2 py-1 pill2'>&lt;string&gt;</span>
                          <v-tooltip top max-width="600" transition="v-scale-transition" color="#0f0f0f">
                          <template v-slot:activator="{ on }">
                            <span v-on="on" class='ml-1 px-1 py-1 pill3'><v-icon style="margin-bottom: 2px;" x-small color="yellow">mdi-asterisk</v-icon></span>
                          </template>
                          <span style="color: yellow;">Required Field</span>
                        </v-tooltip> - The Discord id of the user that will be set as this events leader.                         
                <br>

                    <span class='px-2 py-1 field1'>templateId</span><span class='px-2 py-1 field2'><button @click="showTemplates = !showTemplates" label>
                            <span style="color: #adb1b6;">&lt;number&gt;</span><v-icon v-if="!showTemplates" small color="#adb1b6">mdi-chevron-down</v-icon><v-icon v-else small color="#adb1b6">mdi-chevron-up</v-icon>
                          </button></span> - The template id that will apply to this event.<br>
                    <div style="border-left: 2px solid grey; line-height: 25px;" class="ml-5 px-3 my-2 mb-5" v-if="showTemplates"><span v-for="(n, template) in templates" :key="template"><span style="background-color: #181a1c; padding: 3px; border-radius: 2px;">{{n.id}}</span> <img class="mb-n1 mx-1" :src="'https://cdn.discordapp.com/emojis/' + n.image + '.png?v=1'" width="20" height="20"/> {{n.name}}<br></span></div>
                    <span class='px-2 py-1 field1'>date</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The event date in your servers input format. You can also provide a unix timestamp.<br>
                    <span class='px-2 py-1 field1'>time</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The event time. You can also provide a unix timestamp.<br>
                    <span class='px-2 py-1 field1'>title</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The title for this event.<br>
                    <span class='px-2 py-1 field1'>description</span><span class='px-2 py-1 field2'>&lt;string&gt;</span>- The description for this event.<br>
                    <span class='px-2 py-1 field1'>schedule</span><span class='px-2 py-1 field2'>&lt;number&gt;</span>- The unix timestamp of when this event is to be posted.<br>
                    <span class='px-2 py-1 field1'>advancedSettings</span><span class='px-2 py-1 field2'><button @click="advancedSettings = !advancedSettings" label>
                            <span style="color: #adb1b6;">&lt;object&gt;</span><v-icon v-if="!advancedSettings" small color="#adb1b6">mdi-chevron-down</v-icon><v-icon v-else small color="#adb1b6">mdi-chevron-up</v-icon>
                          </button></span> - The advanced settings for this event.<br>
                          <div style="border-left: 2px solid grey; line-height: 30px;" class="ml-5 px-3 my-2 mb-5" v-if="advancedSettings">
                            <span class='px-2 py-1 field1'>duration</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The duration of the event in minutes.<br>
                            <span class='px-2 py-1 field1'>deadline</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The deadline in hours before the event. <br>
                            <span class='px-2 py-1 field1'>limit</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The maximum amount of active sign-ups.<br>
                            <span class='px-2 py-1 field1'>lock_at_limit</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether the event will lock when the limit is reached.<br>
                            <span class='px-2 py-1 field1'>limit_per_user</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The maximum amount of sign-ups per member.<br>
                            <span class='px-2 py-1 field1'>lower_limit</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The minimum amount of sign-ups required for the event to happen.<br>
                            <span class='px-2 py-1 field1'>allow_duplicate</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether a member can sign up multiple times to the same class.<br>
                            <span class='px-2 py-1 field1'>horizontal_mode</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether sign-ups will be displayed horizontally instead of vertically.<br>
                            <span class='px-2 py-1 field1'>bench_overflow</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether sign-ups past limits will be benched or simply denied.<br>
                            <span class='px-2 py-1 field1'>queue_bench</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - Changes the bench behaviour to a queue.<br>
                            <span class='px-2 py-1 field1'>vacuum</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - Clears the last 10 non-event messages in the channel upon event creation.<br>
                            <span class='px-2 py-1 field1'>force_reminders</span><span class='px-2 py-1 field2'>&lt;boolean/number&gt;</span> - Force personal reminders. Time in minutes before the event.<br>
                            <span class='px-2 py-1 field1'>pin_message</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether this event should be pinned upon creation.<br>
                            <span class='px-2 py-1 field1'>deletion</span><span class='px-2 py-1 field2'>&lt;boolean/number&gt;</span> - The amount of hours this event will be deleted after it concluded.<br>
                            <span class='px-2 py-1 field1'>mention_mode</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - Mentions the members instead of displaying the plain name.<br>
                            <span class='px-2 py-1 field1'>preserve_order</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - Determines the behaviour of the order numbers when members change their sign-up.<br>
                            <span class='px-2 py-1 field1'>apply_unregister</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether the unregister role will be applied.<br>
                            <span class='px-2 py-1 field1'>apply_specreset</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - whether the reaction/button to reset the saved spec should be applied to the event.<br>
                            <span class='px-2 py-1 field1'>spec_saving</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether the bot should remember a members spec choice and apply it automatically.<br>
                            <span class='px-2 py-1 field1'>font_style</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The font style for the event title. Only applies if the title is shorter than 19 characters.<br>
                            <span class='px-2 py-1 field1'>alt_names</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether members will be allowed to enter a custom name when signing up.<br>
                            <span class='px-2 py-1 field1'>defaults_pre_req</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether a primary role is required to sign up with a default role (bench, late, tentative).<br>
                            <span class='px-2 py-1 field1'>show_on_overview</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether the event will be displayed on the /overview.<br>
                            <span class='px-2 py-1 field1'>mention_leader</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether the leaders name will be displayed as a mention.<br>
                            <span class='px-2 py-1 field1'>attendance</span><span class='px-2 py-1 field2'>&lt;boolean/string&gt;</span> - whether this event will count towards attendance. Enter a string to set an attendance tag.<br>
                            <span class='px-2 py-1 field1'>show_title</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether to show the title of this event.<br>
                            <span class='px-2 py-1 field1'>show_info</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether the info row will be shown (contains date, time and number of sign-ups).<br>
                            <span class='px-2 py-1 field1'>show_leader</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether the event leader will be shown.<br>
                            <span class='px-2 py-1 field1'>show_counter</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether the sign-ups counter will be shown.<br>
                            <span class='px-2 py-1 field1'>show_roles</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether to show the role counters above the sign-up content.<br>
                            <span class='px-2 py-1 field1'>show_content</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether the sign-ups will be displayed.<br>
                            <span class='px-2 py-1 field1'>show_classes</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether the class fields will always be shown.<br>
                            <span class='px-2 py-1 field1'>show_emotes</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether to show the spec emotes in front of sign-ups.<br>
                            <span class='px-2 py-1 field1'>show_numbering</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether to show the order number in front of sign-ups.<br>
                            <span class='px-2 py-1 field1'>show_allowed</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether to show the allowed roles in the footer if any are set.<br>
                            <span class='px-2 py-1 field1'>show_footer</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether to show the event footer.<br>
                            <span class='px-2 py-1 field1'>info_variant</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - whether the info field will be displayed in a short or long format.<br>
                            <span class='px-2 py-1 field1'>date_variant</span><span class='px-2 py-1 field2'>local/zoned</span> - whether the date & time on the event will be shown in the users local or zoned time.<br>
                            <span class='px-2 py-1 field1'>show_countdown</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether to show a countdown to the event start.<br>
                            <span class='px-2 py-1 field1'>disable_archiving</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - If archiving is enabled on your server you can exempt a specific event with this setting.<br>
                            <span class='px-2 py-1 field1'>bold_all</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - Set to false to not count consecutive sign-ups by a user to be counted towards limits.<br>
                            <span class='px-2 py-1 field1'>bench_emote</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id that will be used for the bench role. Set to "remove" to disable the role.<br>
                            <span class='px-2 py-1 field1'>late_emote</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id that will be used for the late role. Set to "remove" to disable the role.<br>
                            <span class='px-2 py-1 field1'>tentative_emote</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id that will be used for the tentative role. Set to "remove" to disable the role.<br>
                            <span class='px-2 py-1 field1'>absence_emote</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id that will be used for the absence role. Set to "remove" to disable the role.<br>
                            <span class='px-2 py-1 field1'>leader_emote</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id that will be used for the leader icon.<br>
                            <span class='px-2 py-1 field1'>signups1_emote</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id that will be used for the signups icon.<br>
                            <span class='px-2 py-1 field1'>signups2_emote</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id that will be used for the signups icon.<br>
                            <span class='px-2 py-1 field1'>date1_emote</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id that will be used for the date icon.<br>
                            <span class='px-2 py-1 field1'>date2_emote</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id that will be used for the date icon.<br>
                            <span class='px-2 py-1 field1'>time1_emote</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id that will be used for the time icon.<br>
                            <span class='px-2 py-1 field1'>time2_emote</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id that will be used for the time icon.<br>
                            <span class='px-2 py-1 field1'>countdown1_emote</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id that will be used for the countdown icon.<br>
                            <span class='px-2 py-1 field1'>countdown2_emote</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id that will be used for the countdown icon.<br>
                            <span class='px-2 py-1 field1'>event_type</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - whether this event will use interactions or reactions.<br>
                            <span class='px-2 py-1 field1'>reminder</span><span class='px-2 py-1 field2'>&lt;number/boolean&gt;</span> - Set the amount of minutes before the event a reminder will be sent.<br>
                            <span class='px-2 py-1 field1'>create_discordevent</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether a discord integrated event should be created (requires premium).<br>
                            <span class='px-2 py-1 field1'>create_thread</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether a thread should be created on the event.<br>
                            <span class='px-2 py-1 field1'>delete_thread</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether the attached thread should be deleted if the event gets deleted.<br>
                            <span class='px-2 py-1 field1'>voice_channel</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The voicechannel used for this event.<br>
                            <span class='px-2 py-1 field1'>temp_voicechannel</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The name for a voicechannel that will be temporarily created.<br>
                            <span class='px-2 py-1 field1'>color</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The embed color for this event.<br>
                            <span class='px-2 py-1 field1'>response</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - Enter text that will be sent to the member when they sign up.<br>
                            <span class='px-2 py-1 field1'>temp_role</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The name of a discord role that will be assigned to the member upon signing up.<br>
                            <span class='px-2 py-1 field1'>allowed_roles</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The role names that are allowed to sign up. Separate multiple names by comma.<br>
                            <span class='px-2 py-1 field1'>forum_tags</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The names of the forum tags that will be applied to the post. Separate multiple names by comma.<br>
                            <span class='px-2 py-1 field1'>banned_roles</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - the role names that are banned from signing up. Separate multiple names by comma.<br>
                            <span class='px-2 py-1 field1'>opt_out</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The id of an existing event to copy the sign-ups from upon event creation.<br>
                            <span class='px-2 py-1 field1'>mentions</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The role/member names to mention upon event creation. Separate multiple names by comma.<br>
                            <span class='px-2 py-1 field1'>image</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The URL of an image that will be displayed at the bottom of the event embed.<br>
                            <span class='px-2 py-1 field1'>thumbnail</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The URL to an image which will be displayed as a thumbnail on the event embed.<br>
                            <span class='px-2 py-1 field1'>use_nicknames</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - whether the members server nickname will be used or the global name.<br>

                          </div>
                  
                          <span class='px-2 py-1 field1'>classLimits</span><span class='px-2 py-1 field2'><button @click="classLimits = !classLimits" label>
                            <span style="color: #adb1b6;">&lt;object&gt;</span><v-icon v-if="!classLimits" small color="#adb1b6">mdi-chevron-down</v-icon><v-icon v-else small color="#adb1b6">mdi-chevron-up</v-icon>
                          </button></span> - The classes that you want to set individual limits to.<br>
                          <div style="border-left: 2px solid grey; line-height: 30px;" class="ml-5 px-3 my-2 mb-5" v-if="classLimits">
                            <span class='px-2 py-1 field1'>&lt;className&gt;</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The limit for the specified class.<br>
                          </div>
                          <span class='px-2 py-1 field1'>roleLimits</span><span class='px-2 py-1 field2'><button @click="roleLimits = !roleLimits" label>
                            <span style="color: #adb1b6;">&lt;object&gt;</span><v-icon v-if="!roleLimits" small color="#adb1b6">mdi-chevron-down</v-icon><v-icon v-else small color="#adb1b6">mdi-chevron-up</v-icon>
                          </button></span> - The roles that you want to set individual limits to.<br>
                          <div style="border-left: 2px solid grey; line-height: 30px;" class="ml-5 px-3 my-2 mb-5" v-if="roleLimits">
                            <span class='px-2 py-1 field1'>&lt;roleName&gt;</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The limit for the specified role.<br>
                          </div>
                          <span class='px-2 py-1 field1'>softres</span><span class='px-2 py-1 field2'><button @click="softres = !softres" label>
                            <span style="color: #adb1b6;">&lt;object&gt;</span><v-icon v-if="!softres" small color="#adb1b6">mdi-chevron-down</v-icon><v-icon v-else small color="#adb1b6">mdi-chevron-up</v-icon>
                          </button></span> - The softres object that you want to be created.<br>
                          <div style="border-left: 2px solid grey; line-height: 30px;" class="ml-5 px-3 my-2 mb-5" v-if="softres">
                
                            <span class='px-2 py-1 field1'>instance</span><span class='px-2 py-1 field2'><button @click="softresInstance = !softresInstance" label>
                            <span style="color: #adb1b6;">&lt;string&gt;</span><v-icon v-if="!softresInstance" small color="#adb1b6">mdi-chevron-down</v-icon><v-icon v-else small color="#adb1b6">mdi-chevron-up</v-icon>
                          </button></span> - The raid instance this softres sheet will apply.<br>
                    <div style="border-left: 2px solid grey; line-height: 25px;" class="ml-5 px-3 my-2 mb-5" v-if="softresInstance">
                      <span style="background-color: #181a1c; padding: 3px; border-radius: 2px;">zg</span> - Zul'Gurub (Classic)<br>
                      <span style="background-color: #181a1c; padding: 3px; border-radius: 2px;">mc</span> - Molten Core (Classic)<br>
                      <span style="background-color: #181a1c; padding: 3px; border-radius: 2px;">onyxia</span> - Onyxia's Lair (Classic)<br>
                      <span style="background-color: #181a1c; padding: 3px; border-radius: 2px;">bwl</span> - Blackwing Lair (Classic)<br>
                      <span style="background-color: #181a1c; padding: 3px; border-radius: 2px;">aq20</span> - Ahn'Qiraj 20 (Classic)<br>
                      <span style="background-color: #181a1c; padding: 3px; border-radius: 2px;">aq40</span> - Ahn'Qiraj 40 (Classic)<br>
                      <span style="background-color: #181a1c; padding: 3px; border-radius: 2px;">naxxramas</span> - Naxxramas (Classic)<br>
                      <span style="background-color: #181a1c; padding: 3px; border-radius: 2px;">azuregos</span> - Azuregos (Classic)<br>
                      <span style="background-color: #181a1c; padding: 3px; border-radius: 2px;">lordkazzak</span> - Lord Kazzak (Classic)<br>
                      <span style="background-color: #181a1c; padding: 3px; border-radius: 2px;">bfdsod</span> - Blackfathom Deeps (SoD)<br>
                      <span style="background-color: #181a1c; padding: 3px; border-radius: 2px;">gnomeregansod</span> - Gnomeregan (SoD)<br>
                      <span style="background-color: #181a1c; padding: 3px; border-radius: 2px;">sunkentemplesod</span> - Sunken Temple (SoD)<br>
                      <span style="background-color: #181a1c; padding: 3px; border-radius: 2px;">azuregossod</span> - Azuregos (SoD)<br>
                      <span style="background-color: #181a1c; padding: 3px; border-radius: 2px;">lordkazzaksod</span> - Lord Kazzak (SoD)<br>
                      <span style="background-color: #181a1c; padding: 3px; border-radius: 2px;">mcsod</span> - Molten Core (SoD)<br>
                      <span style="background-color: #181a1c; padding: 3px; border-radius: 2px;">onyxiasod</span> - Onyxia (SoD)<br>
                      <span style="background-color: #181a1c; padding: 3px; border-radius: 2px;">bwlsod</span> - Blackwing Lair (SoD)<br>
                      <span style="background-color: #181a1c; padding: 3px; border-radius: 2px;">zgsod</span> - Zul'Gurub (SoD)<br>
                      <span style="background-color: #181a1c; padding: 3px; border-radius: 2px;">crystalvale</span> - The Crystal Vale (SoD)<br>
                      <span style="background-color: #181a1c; padding: 3px; border-radius: 2px;">kara</span> - Karazhan (TBC)<br>
                      <span style="background-color: #181a1c; padding: 3px; border-radius: 2px;">gruul</span> - Gruul's Lair (TBC)<br>
                      <span style="background-color: #181a1c; padding: 3px; border-radius: 2px;">magtheridon</span> - Magtheridon's Lair (TBC)<br>
                      <span style="background-color: #181a1c; padding: 3px; border-radius: 2px;">gruulmag</span> - Gruul + Magtheridon (TBC)<br>
                      <span style="background-color: #181a1c; padding: 3px; border-radius: 2px;">ssc</span> - Serpentshrine Cavern (TBC)<br>
                      <span style="background-color: #181a1c; padding: 3px; border-radius: 2px;">tempestkeep</span> - Tempest Keep (TBC)<br>
                      <span style="background-color: #181a1c; padding: 3px; border-radius: 2px;">hyjal</span> - Mount Hyjal (TBC)<br>
                      <span style="background-color: #181a1c; padding: 3px; border-radius: 2px;">blacktemple</span> - Black Temple (TBC)<br>
                      <span style="background-color: #181a1c; padding: 3px; border-radius: 2px;">bthyjal</span> - Black Temple + Hyjal (TBC)<br>
                      <span style="background-color: #181a1c; padding: 3px; border-radius: 2px;">za</span> - Zul'Aman (TBC)<br>
                      <span style="background-color: #181a1c; padding: 3px; border-radius: 2px;">sunwellplateau</span> - Sunwell Plateau (TBC)<br>
                      <span style="background-color: #181a1c; padding: 3px; border-radius: 2px;">wotlknaxx10p2</span> - Naxxramas 10 (WotLK)<br>
                      <span style="background-color: #181a1c; padding: 3px; border-radius: 2px;">wotlknaxx25</span> - Naxxramas 25 (WotLK)<br>
                      <span style="background-color: #181a1c; padding: 3px; border-radius: 2px;">wyrmrest10p2</span> - Wyrmrest Temple 10 (WotLK)<br>
                      <span style="background-color: #181a1c; padding: 3px; border-radius: 2px;">wyrmrest25</span> - Wyrmrest Temple 25 (WotLK)<br>
                      <span style="background-color: #181a1c; padding: 3px; border-radius: 2px;">naxxdragons10p2</span> - Naxxramas + Dragons 10 (WotLK)<br>
                      <span style="background-color: #181a1c; padding: 3px; border-radius: 2px;">naxxdragons25</span> - Naxxramas + Dragons 25 (WotLK)<br>
                      <span style="background-color: #181a1c; padding: 3px; border-radius: 2px;">obsidiansanctum10p2</span> - Obsidian Sanctum 10 (WotLK)<br>
                      <span style="background-color: #181a1c; padding: 3px; border-radius: 2px;">obsidiansanctum25</span> - Obsidian Sanctum 25 (WotLK)<br>
                      <span style="background-color: #181a1c; padding: 3px; border-radius: 2px;">eyeofeternity10p2</span> - Eye of Eternity 10 (WotLK)<br>
                      <span style="background-color: #181a1c; padding: 3px; border-radius: 2px;">eyeofeternity25</span> - Eye of Eternity 25 (WotLK)<br>

                      <span style="background-color: #181a1c; padding: 3px; border-radius: 2px;">ulduar10p3</span> - Ulduar 10 (WotLK)<br>
                      <span style="background-color: #181a1c; padding: 3px; border-radius: 2px;">ulduar25</span> - Ulduar 25 (WotLK)<br>
                      <span style="background-color: #181a1c; padding: 3px; border-radius: 2px;">toc10</span> - Trial of the Crusader 10 (WotLK)<br>
                      <span style="background-color: #181a1c; padding: 3px; border-radius: 2px;">toc25</span> - Trial of the Crusader 25 (WotLK)<br>
                      <span style="background-color: #181a1c; padding: 3px; border-radius: 2px;">toculduar10p3</span> - Trial of the Crusader + Ulduar 10 (WotLK)<br>
                      <span style="background-color: #181a1c; padding: 3px; border-radius: 2px;">toculduar25</span> - Trial of the Crusader + Ulduar 25 (WotLK)<br>
                      <span style="background-color: #181a1c; padding: 3px; border-radius: 2px;">wotlkonyxia10</span> - Onyxia's Lair 10 (WotLK)<br>
                      <span style="background-color: #181a1c; padding: 3px; border-radius: 2px;">wotlkonyxia25</span> - Onyxia's Lair 25 (WotLK)<br>
                      <span style="background-color: #181a1c; padding: 3px; border-radius: 2px;">toconyxia10</span> - Trial of the Crusader + Onyxia 10 (WotLK)<br>
                      <span style="background-color: #181a1c; padding: 3px; border-radius: 2px;">toconyxia25</span> - Trial of the Crusader + Onyxia 25 (WotLK)<br>
                      <span style="background-color: #181a1c; padding: 3px; border-radius: 2px;">icc10</span> - Icecrown Citadel 10 (WotLK)<br>
                      <span style="background-color: #181a1c; padding: 3px; border-radius: 2px;">icc25</span> - Icecrown Citadel 25 (WotLK)<br>
                      <span style="background-color: #181a1c; padding: 3px; border-radius: 2px;">rubysanctum10</span> - Ruby Sanctum 10 (WotLK)<br>
                      <span style="background-color: #181a1c; padding: 3px; border-radius: 2px;">rubysanctum25</span> - Ruby Sanctum 25 (WotLK)<br>
                      <span style="background-color: #181a1c; padding: 3px; border-radius: 2px;">iccrubysanctum10</span> - ICC + Ruby Sanctum 10 (WotLK)<br>
                      <span style="background-color: #181a1c; padding: 3px; border-radius: 2px;">iccrubysanctum25</span> - ICC + Ruby Sanctum 25 (WotLK)<br>
                      <span style="background-color: #181a1c; padding: 3px; border-radius: 2px;">baradinhold</span> - Baradin Hold (Cata)<br>
                      <span style="background-color: #181a1c; padding: 3px; border-radius: 2px;">blackwingdescent</span> - Blackwing Descent (Cata)<br>
                      <span style="background-color: #181a1c; padding: 3px; border-radius: 2px;">bastionoftwilight</span> - Bastion of Twilight (Cata)<br>
                      <span style="background-color: #181a1c; padding: 3px; border-radius: 2px;">throneoffourwinds</span> - Throne of Four Winds (Cata)<br>
                    </div>
                            <span class='px-2 py-1 field1'>faction</span><span class='px-2 py-1 field2'><button @click="softresFaction = !softresFaction" label>
                            <span style="color: #adb1b6;">&lt;string&gt;</span><v-icon v-if="!softresFaction" small color="#adb1b6">mdi-chevron-down</v-icon><v-icon v-else small color="#adb1b6">mdi-chevron-up</v-icon>
                          </button></span> - The faction of your team.<br>
                    <div style="border-left: 2px solid grey; line-height: 25px;" class="ml-5 px-3 my-2 mb-5" v-if="softresFaction">
                      <span style="background-color: #181a1c; padding: 3px; border-radius: 2px;">Horde</span><br>
                      <span style="background-color: #181a1c; padding: 3px; border-radius: 2px;">Alliance</span><br>
                    </div>
                            <span class='px-2 py-1 field1'>resLimitPerCharacter</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The maximum amount of reserves per character (0-10).<br>
                            <span class='px-2 py-1 field1'>resLimitPerItem</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The maximum amount of reserves per item (0-10).<br>
                            <span class='px-2 py-1 field1'>discordProtection</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether this softres sheet will require discord authentication.<br>
                            <span class='px-2 py-1 field1'>hideReserves</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether the reserves will be hidden on the softres sheet.<br>
             
                            <span class='px-2 py-1 field1'>botBehaviour</span><span class='px-2 py-1 field2'><button @click="softresBotBehaviour = !softresBotBehaviour" label>
                            <span style="color: #adb1b6;">&lt;string&gt;</span><v-icon v-if="!softresBotBehaviour" small color="#adb1b6">mdi-chevron-down</v-icon><v-icon v-else small color="#adb1b6">mdi-chevron-up</v-icon>
                          </button></span> - The behaviour of the bot when a user signs up.<br>
                    <div style="border-left: 2px solid grey; line-height: 25px;" class="ml-5 px-3 my-2 mb-5" v-if="softresBotBehaviour">
                      <span style="background-color: #181a1c; padding: 3px; border-radius: 2px;">dm</span> - The bot will send a DM to the user notifying him of the existing softres.<br>
                      <span style="background-color: #181a1c; padding: 3px; border-radius: 2px;">req</span> - The bot will require the user to have a reserve placed before signing up.<br>
                      <span style="background-color: #181a1c; padding: 3px; border-radius: 2px;">none</span> - The bot will not send a DM and not require a reserve.<br>
                    </div>
                          </div>
                          <span class='px-2 py-1 field1'>announcement</span><span class='px-2 py-1 field2'><button @click="announcement = !announcement" label>
                            <span style="color: #adb1b6;">&lt;object&gt;</span><v-icon v-if="!announcement" small color="#adb1b6">mdi-chevron-down</v-icon><v-icon v-else small color="#adb1b6">mdi-chevron-up</v-icon>
                          </button></span> - Set an announcement for this event.<br>
                          <div style="border-left: 2px solid grey; line-height: 30px;" class="ml-5 px-3 my-2 mb-5" v-if="announcement">
                            <span class='px-2 py-1 field1'>channel</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The channel id or keyword for this announcement.<br>
                            <span class='px-2 py-1 field1'>time</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The time in minutes before the event to send this announcement.<br>
                            <span class='px-2 py-1 field1'>message</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The message that the announcement will send.<br>
                          </div>
                  </v-expansion-panel-content>
              </v-expansion-panel>
                   <h1 class="mt-10 mb-7">Response Structure</h1>

                   <v-expansion-panel class="my-1">
                  <v-expansion-panel-header style="font-size: 18px;" color="#18191c">
                    <v-row>
                      <div><span class='px-2 py-1 field2'>Body</span></div><br>
                    </v-row>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content style="background-color: #1c1d21a6; line-height: 30px;" class="pt-5">
                    <span class='px-2 py-1 field1'>status</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - Indicates the success or failure of the request.<br>
                    <span class='px-2 py-1 field1'>event</span><span class='px-2 py-1 field2'><button @click="event = !event" label>
                            <span style="color: #adb1b6;">&lt;object&gt;</span><v-icon v-if="!event" small color="#adb1b6">mdi-chevron-down</v-icon><v-icon v-else small color="#adb1b6">mdi-chevron-up</v-icon>
                          </button></span> - The event object.<br>
                          <div style="border-left: 2px solid grey; line-height: 30px;" class="ml-5 px-3 my-2 mb-5" v-if="event">
                            <span class='px-2 py-1 field1'>id</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The message id of this event.<br>
                    <span class='px-2 py-1 field1'>serverId</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The server id of this event.<br>
                    <span class='px-2 py-1 field1'>leaderId</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The user id of this events leader.<br>
                    <span class='px-2 py-1 field1'>leaderName</span><span class='px-2 py-1 field2'>&lt;string&gt;</span>- The name of events leader.<br>
                    <span class='px-2 py-1 field1'>channelId</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - This events channel id.<br>
                    <span class='px-2 py-1 field1'>channelName</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - This events channel name.<br>
                    <span class='px-2 py-1 field1'>channelType</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The type of this events channel.<br>
                    <span class='px-2 py-1 field1'>templateId</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The id of this events template.<br>
                    <span class='px-2 py-1 field1'>templateEmoteId</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id of the emote used to represent the template of this event.<br>
                    <span class='px-2 py-1 field1'>title</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The event title.<br>
                    <span class='px-2 py-1 field1'>description</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The event description.<br>
                    <span class='px-2 py-1 field1'>startTime</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The unix timestamp of when this event will start.<br>
                    <span class='px-2 py-1 field1'>endTime</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The unix timestamp of when this event will end.<br>
                    <span class='px-2 py-1 field1'>closingTime</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The unix timestamp of when this event will close and deny further sign-ups.<br>
                    <span class='px-2 py-1 field1'>date</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The raw date string of when this event will start.<br>
                    <span class='px-2 py-1 field1'>time</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The raw time string of when this event will start.<br>
                    <span class='px-2 py-1 field1'>advancedSettings</span><span class='px-2 py-1 field2'><button @click="advancedSettings = !advancedSettings" label>
                            <span style="color: #adb1b6;">&lt;object&gt;</span><v-icon v-if="!advancedSettings" small color="#adb1b6">mdi-chevron-down</v-icon><v-icon v-else small color="#adb1b6">mdi-chevron-up</v-icon>
                          </button></span> - The advanced settings for this event.<br>
                          <div style="border-left: 2px solid grey; line-height: 30px;" class="ml-5 px-3 my-2 mb-5" v-if="advancedSettings">
                            <span class='px-2 py-1 field1'>duration</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The duration of the event in minutes.<br>
                            <span class='px-2 py-1 field1'>deadline</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The deadline in hours before the event. <br>
                            <span class='px-2 py-1 field1'>limit</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The maximum amount of active sign-ups.<br>
                            <span class='px-2 py-1 field1'>lock_at_limit</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether the event will lock when the limit is reached.<br>
                            <span class='px-2 py-1 field1'>limit_per_user</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The maximum amount of sign-ups per member.<br>
                            <span class='px-2 py-1 field1'>lower_limit</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The minimum amount of sign-ups required for the event to happen.<br>
                            <span class='px-2 py-1 field1'>allow_duplicate</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether a member can sign up multiple times to the same class.<br>
                            <span class='px-2 py-1 field1'>horizontal_mode</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether sign-ups will be displayed horizontally instead of vertically.<br>
                            <span class='px-2 py-1 field1'>bench_overflow</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether sign-ups past limits will be benched or simply denied.<br>
                            <span class='px-2 py-1 field1'>queue_bench</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - Changes the bench behaviour to a queue.<br>
                            <span class='px-2 py-1 field1'>vacuum</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - Clears the last 10 non-event messages in the channel upon event creation.<br>
                            <span class='px-2 py-1 field1'>force_reminders</span><span class='px-2 py-1 field2'>&lt;boolean/number&gt;</span> - Force personal reminders. Time in minutes before the event.<br>
                            <span class='px-2 py-1 field1'>pin_message</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether this event should be pinned upon creation.<br>
                            <span class='px-2 py-1 field1'>deletion</span><span class='px-2 py-1 field2'>&lt;boolean/number&gt;</span> - The amount of hours this event will be deleted after it concluded.<br>
                            <span class='px-2 py-1 field1'>mention_mode</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - Mentions the members instead of displaying the plain name.<br>
                            <span class='px-2 py-1 field1'>preserve_order</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - Determines the behaviour of the order numbers when members change their sign-up.<br>
                            <span class='px-2 py-1 field1'>apply_unregister</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether the unregister role will be applied.<br>
                            <span class='px-2 py-1 field1'>apply_specreset</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - whether the reaction/button to reset the saved spec should be applied to the event.<br>
                            <span class='px-2 py-1 field1'>spec_saving</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether the bot should remember a members spec choice and apply it automatically.<br>
                            <span class='px-2 py-1 field1'>font_style</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The font style for the event title. Only applies if the title is shorter than 19 characters.<br>
                            <span class='px-2 py-1 field1'>alt_names</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether members will be allowed to enter a custom name when signing up.<br>
                            <span class='px-2 py-1 field1'>defaults_pre_req</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether a primary role is required to sign up with a default role (bench, late, tentative).<br>
                            <span class='px-2 py-1 field1'>show_on_overview</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether the event will be displayed on the /overview.<br>
                            <span class='px-2 py-1 field1'>mention_leader</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether the leaders name will be displayed as a mention.<br>
                            <span class='px-2 py-1 field1'>attendance</span><span class='px-2 py-1 field2'>&lt;boolean/string&gt;</span> - whether this event will count towards attendance. Enter a string to set an attendance tag.<br>
                            <span class='px-2 py-1 field1'>show_title</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether to show the title of this event.<br>
                            <span class='px-2 py-1 field1'>show_info</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether the info row will be shown (contains date, time and number of sign-ups).<br>
                            <span class='px-2 py-1 field1'>show_leader</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether the event leader will be shown.<br>
                            <span class='px-2 py-1 field1'>show_counter</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether the sign-ups counter will be shown.<br>
                            <span class='px-2 py-1 field1'>show_roles</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether to show the role counters above the sign-up content.<br>
                            <span class='px-2 py-1 field1'>show_content</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether the sign-ups will be displayed.<br>
                            <span class='px-2 py-1 field1'>show_classes</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether the class fields will always be shown.<br>
                            <span class='px-2 py-1 field1'>show_emotes</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether to show the spec emotes in front of sign-ups.<br>
                            <span class='px-2 py-1 field1'>show_numbering</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether to show the order number in front of sign-ups.<br>
                            <span class='px-2 py-1 field1'>show_allowed</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether to show the allowed roles in the footer if any are set.<br>
                            <span class='px-2 py-1 field1'>show_footer</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether to show the event footer.<br>
                            <span class='px-2 py-1 field1'>info_variant</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - whether the info field will be displayed in a short or long format.<br>
                            <span class='px-2 py-1 field1'>date_variant</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - whether the date & time on the event will be shown in the users local or zoned time.<br>
                            <span class='px-2 py-1 field1'>show_countdown</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether to show a countdown to the event start.<br>
                            <span class='px-2 py-1 field1'>disable_archiving</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - If archiving is enabled on your server you can exempt a specific event with this setting.<br>
                            <span class='px-2 py-1 field1'>bold_all</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - Set to false to not count consecutive sign-ups by a user to be counted towards limits.<br>
                            <span class='px-2 py-1 field1'>bench_emote</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id that will be used for the bench role. Set to "remove" to disable the role.<br>
                            <span class='px-2 py-1 field1'>late_emote</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id that will be used for the late role. Set to "remove" to disable the role.<br>
                            <span class='px-2 py-1 field1'>tentative_emote</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id that will be used for the tentative role. Set to "remove" to disable the role.<br>
                            <span class='px-2 py-1 field1'>absence_emote</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id that will be used for the absence role. Set to "remove" to disable the role.<br>
                            <span class='px-2 py-1 field1'>leader_emote</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id that will be used for the leader icon.<br>
                            <span class='px-2 py-1 field1'>signups1_emote</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id that will be used for the signups icon.<br>
                            <span class='px-2 py-1 field1'>signups2_emote</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id that will be used for the signups icon.<br>
                            <span class='px-2 py-1 field1'>date1_emote</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id that will be used for the date icon.<br>
                            <span class='px-2 py-1 field1'>date2_emote</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id that will be used for the date icon.<br>
                            <span class='px-2 py-1 field1'>time1_emote</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id that will be used for the time icon.<br>
                            <span class='px-2 py-1 field1'>time2_emote</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id that will be used for the time icon.<br>
                            <span class='px-2 py-1 field1'>countdown1_emote</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id that will be used for the countdown icon.<br>
                            <span class='px-2 py-1 field1'>countdown2_emote</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id that will be used for the countdown icon.<br>
                            <span class='px-2 py-1 field1'>event_type</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - whether this event will use interactions or reactions.<br>
                            <span class='px-2 py-1 field1'>reminder</span><span class='px-2 py-1 field2'>&lt;number/boolean&gt;</span> - Set the amount of minutes before the event a reminder will be sent.<br>
                            <span class='px-2 py-1 field1'>create_discordevent</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether a discord integrated event should be created (requires premium).<br>
                            <span class='px-2 py-1 field1'>create_thread</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether a thread should be created on the event.<br>
                            <span class='px-2 py-1 field1'>delete_thread</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether the attached thread should be deleted if the event gets deleted.<br>
                            <span class='px-2 py-1 field1'>voice_channel</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The voicechannel used for this event.<br>
                            <span class='px-2 py-1 field1'>temp_voicechannel</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The name for a voicechannel that will be temporarily created.<br>
                            <span class='px-2 py-1 field1'>color</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The embed color for this event.<br>
                            <span class='px-2 py-1 field1'>response</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - Enter text that will be sent to the member when they sign up.<br>
                            <span class='px-2 py-1 field1'>temp_role</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The name of a discord role that will be assigned to the member upon signing up.<br>
                            <span class='px-2 py-1 field1'>allowed_roles</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The role names that are allowed to sign up. Separate multiple names by comma.<br>
                            <span class='px-2 py-1 field1'>forum_tags</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The names of the forum tags that will be applied to the post. Separate multiple names by comma.<br>
                            <span class='px-2 py-1 field1'>banned_roles</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - the role names that are banned from signing up. Separate multiple names by comma.<br>
                            <span class='px-2 py-1 field1'>opt_out</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The id of an existing event to copy the sign-ups from upon event creation.<br>
                            <span class='px-2 py-1 field1'>mentions</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The role/member names to mention upon event creation. Separate multiple names by comma.<br>
                            <span class='px-2 py-1 field1'>image</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The URL of an image that will be displayed at the bottom of the event embed.<br>
                            <span class='px-2 py-1 field1'>thumbnail</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The URL to an image which will be displayed as a thumbnail on the event embed.<br>
                            <span class='px-2 py-1 field1'>use_nicknames</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - whether the members server nickname will be used or the global name.<br>

                          </div>
                    <span class='px-2 py-1 field1'>classes</span><span class='px-2 py-1 field2'><button @click="classes = !classes" label>
                            <span style="color: #adb1b6;">&lt;array of objects&gt;</span><v-icon v-if="!classes" small color="#adb1b6">mdi-chevron-down</v-icon><v-icon v-else small color="#adb1b6">mdi-chevron-up</v-icon>
                          </button></span> - The classes that are applied to this event.<br>
                          <div style="border-left: 2px solid grey; line-height: 30px;" class="ml-5 px-3 my-2 mb-5" v-if="classes">
                            <span class='px-2 py-1 field1'>name</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The name of this class.<br>
                            <span class='px-2 py-1 field1'>limit</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The maximum allowed sign-ups for this class.<br>
                            <span class='px-2 py-1 field1'>emoteId</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id of this class.<br>
                            <span class='px-2 py-1 field1'>type</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The type (primary/default) of this class.<br>
                            <span class='px-2 py-1 field1'>specs</span><span class='px-2 py-1 field2'><button @click="specs = !specs" label>
                            <span style="color: #adb1b6;">&lt;array of objects&gt;</span><v-icon v-if="!specs" small color="#adb1b6">mdi-chevron-down</v-icon><v-icon v-else small color="#adb1b6">mdi-chevron-up</v-icon>
                          </button></span> - The classes that are applied to this event.<br>
                          <div style="border-left: 2px solid grey; line-height: 30px;" class="ml-5 px-3 my-2 mb-5" v-if="specs">
                            <span class='px-2 py-1 field1'>name</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The name of this spec.<br>
                            <span class='px-2 py-1 field1'>emoteId</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id of this spec.<br>
                            <span class='px-2 py-1 field1'>roleName</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The name of the role that this spec belongs to.<br>
                            <span class='px-2 py-1 field1'>roleEmoteId</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id of the role that this spec belongs to.<br>
                          </div>
                          </div>
                    <span class='px-2 py-1 field1'>roles</span><span class='px-2 py-1 field2'><button @click="roles = !roles" label>
                            <span style="color: #adb1b6;">&lt;array of objects&gt;</span><v-icon v-if="!roles" small color="#adb1b6">mdi-chevron-down</v-icon><v-icon v-else small color="#adb1b6">mdi-chevron-up</v-icon>
                          </button></span> - The roles that are applied to this event.<br>
                          <div style="border-left: 2px solid grey; line-height: 30px;" class="ml-5 px-3 my-2 mb-5" v-if="roles">
                            <span class='px-2 py-1 field1'>name</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The name of this role.<br>
                            <span class='px-2 py-1 field1'>limit</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The maximum allowed sign-ups for this role.<br>
                            <span class='px-2 py-1 field1'>emoteId</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id of this role.<br>
                          </div>
                    <span class='px-2 py-1 field1'>signUps</span><span class='px-2 py-1 field2'><button @click="signUps = !signUps" label>
                            <span style="color: #adb1b6;">&lt;array of objects&gt;</span><v-icon v-if="!signUps" small color="#adb1b6">mdi-chevron-down</v-icon><v-icon v-else small color="#adb1b6">mdi-chevron-up</v-icon>
                          </button></span> - The current sign-ups on this event.<br>
                          <div style="border-left: 2px solid grey; line-height: 30px;" class="ml-5 px-3 my-2 mb-5" v-if="signUps">
                            <span class='px-2 py-1 field1'>name</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The name of the user.<br>
                            <span class='px-2 py-1 field1'>id</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The Discord id of the user. <br>
                            <span class='px-2 py-1 field1'>className</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The class name of the sign-up.<br>
                            <span class='px-2 py-1 field1'>classEmoteId</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The class emote id of the sign-up.<br>
                            <span class='px-2 py-1 field1'>specName</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - the spec name of the sign-up.<br>
                            <span class='px-2 py-1 field1'>specEmoteId</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The spec emote id of the sign-up.<br>
                            <span class='px-2 py-1 field1'>roleName</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The role name of the sign-up.<br>
                            <span class='px-2 py-1 field1'>roleEmoteId</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The role emote id of the sign-up.<br>
                            <span class='px-2 py-1 field1'>status</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The status (primary/queued) of the sign-up.<br>
                            <span class='px-2 py-1 field1'>entryTime</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The unix timestamp of the registration time.<br>
                            <span class='px-2 py-1 field1'>position</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The order number of this sign-up.<br>
                          </div>
                    <span class='px-2 py-1 field1'>lastUpdated</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The unix timestamp of when this event was updated last.<br>
                    <span class='px-2 py-1 field1'>softresId</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The softres id attached to this event.<br>
                    <span class='px-2 py-1 field1'>color</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The current embed color in RGB format.<br>
                          </div>

                  </v-expansion-panel-content>
              </v-expansion-panel>
           
                  </v-expansion-panel-content>
              </v-expansion-panel>



               <v-expansion-panel active-class="panel" class="my-1">
                  <v-expansion-panel-header style="font-size: 15px;" color="#18191c">
                    <v-row>
                      <v-col cols="auto" class="mb-0" style="min-width: 90px; text-align: center; justify-content: center; padding-top: 35px;">
                        <div style="font-weight: 800; font-size: 20px; color: #339933; margin: auto; text-align: center;">PATCH</div>
                      </v-col>
                      <v-col>
                    <v-col cols="12" class="mb-n4">
                      <span style="word-break: break-word;">https://raid-helper.dev/api/v2/events/<span style="color: purple;">EVENTID</span></span>
                    </v-col>
                    <v-col cols="12" class="mb-0">
                    <span style="color:gray; font-size: 15px;">Edit an event.</span>
                    </v-col>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content style="background-color: #373a3e;" class="pt-5">
                      <v-row justify="center">
                  <v-btn style="text-transform: none;" elevation="3" depressed x-small color="purple darken-2" class="pa-2 mr-3 mb-8 mt-5" @click="copyText('https://raid-helper.dev/api/v2/events/EVENTID')">Copy URL</v-btn>
                  <v-btn style="text-transform: none;" elevation="3" depressed x-small color="purple darken-2" class="pa-2 mr-3 mb-8 mt-5" @click="copyText(curl5)">Copy cURL</v-btn>
                      </v-row>	
                   This request is used to edit an event. The event is defined in the route. All other options are defined in the JSON body.
                   <h1 class="mt-10 mb-7">Request Structure</h1>
                   <v-expansion-panel class="my-1">
                  <v-expansion-panel-header style="font-size: 18px;" color="#18191c">
                    <v-row>
                      <div><span class='px-2 py-1 field2'>Headers</span> <span style="color: grey; font-size: 15px;" class="mx-2"></span></div><br>
                    </v-row>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content style="background-color: #1c1d21a6; line-height: 30px;" class="pt-5">
                    <span class='px-2 py-1 pill1'>Authorization</span><span class='px-2 py-1 pill2'>&lt;apikey&gt;</span>
                          <v-tooltip top max-width="600" transition="v-scale-transition" color="#0f0f0f">
                          <template v-slot:activator="{ on }">
                            <span v-on="on" class='ml-1 px-1 py-1 pill3'><v-icon style="margin-bottom: 2px;" x-small color="yellow">mdi-asterisk</v-icon></span>
                          </template>
                          <span style="color: yellow;">Required Field</span>
                        </v-tooltip> - Your servers api key which you can get and refresh via /apikey.                         
                      <br>
                      <span class='px-2 py-1 pill1'>Content-Type</span><span class='px-2 py-1 pill2'>application/json; charset=utf-8</span>
                            <v-tooltip top max-width="600" transition="v-scale-transition" color="#0f0f0f">
                          <template v-slot:activator="{ on }">
                            <span v-on="on" class='ml-1 px-1 py-1 pill3'><v-icon style="margin-bottom: 2px;" x-small color="yellow">mdi-asterisk</v-icon></span>
                          </template>
                          <span style="color: yellow;">Required Field</span>
                        </v-tooltip> - Defines the content type.                         
                          <br>
                  </v-expansion-panel-content>
              </v-expansion-panel>
                   <v-expansion-panel class="my-1">
                  <v-expansion-panel-header style="font-size: 18px;" color="#18191c">
                    <v-row>
                      <div><span class='px-2 py-1 field2'>Body</span> <span style="color: grey; font-size: 15px;" class="mx-2"></span></div><br>
                    </v-row>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content style="background-color: #1c1d21a6; line-height: 30px;" class="pt-5">
                  <span class='px-2 py-1 field1'>leaderId</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The id of the user that will be set as this events leader.<br>
                  <span class='px-2 py-1 field1'>leaderName</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The name of the user that will be set as this events leader.<br>
                    <span class='px-2 py-1 field1'>title</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The title for this event.<br>
                    <span class='px-2 py-1 field1'>description</span><span class='px-2 py-1 field2'>&lt;string&gt;</span>- The description for this event.<br>
                    <span class='px-2 py-1 field1'>date</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The events start date in your servers input format.<br>
                    <span class='px-2 py-1 field1'>time</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The events start time.<br>
                    <span class='px-2 py-1 field1'>startTime</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The events start time as a unix timestamp.<br>
                    <span class='px-2 py-1 field1'>endTime</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The events end time as a unix timestamp<br>
                    <span class='px-2 py-1 field1'>channelId</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The id of the channel where this event will be moved to.<br>
                    <span class='px-2 py-1 field1'>channelName</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The name of the channel where this event will be moved to.<br>

                    <span class='px-2 py-1 field1'>advancedSettings</span><span class='px-2 py-1 field2'><button @click="advancedSettings = !advancedSettings" label>
                            <span style="color: #adb1b6;">&lt;object&gt;</span><v-icon v-if="!advancedSettings" small color="#adb1b6">mdi-chevron-down</v-icon><v-icon v-else small color="#adb1b6">mdi-chevron-up</v-icon>
                          </button></span> - The advanced settings for this event.<br>
                          <div style="border-left: 2px solid grey; line-height: 30px;" class="ml-5 px-3 my-2 mb-5" v-if="advancedSettings">
                            <span class='px-2 py-1 field1'>duration</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The duration of the event in minutes.<br>
                            <span class='px-2 py-1 field1'>deadline</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The deadline in hours before the event. <br>
                            <span class='px-2 py-1 field1'>limit</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The maximum amount of active sign-ups.<br>
                            <span class='px-2 py-1 field1'>lock_at_limit</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether the event will lock when the limit is reached.<br>
                            <span class='px-2 py-1 field1'>limit_per_user</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The maximum amount of sign-ups per member.<br>
                            <span class='px-2 py-1 field1'>lower_limit</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The minimum amount of sign-ups required for the event to happen.<br>
                            <span class='px-2 py-1 field1'>allow_duplicate</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether a member can sign up multiple times to the same class.<br>
                            <span class='px-2 py-1 field1'>horizontal_mode</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether sign-ups will be displayed horizontally instead of vertically.<br>
                            <span class='px-2 py-1 field1'>bench_overflow</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether sign-ups past limits will be benched or simply denied.<br>
                            <span class='px-2 py-1 field1'>queue_bench</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - Changes the bench behaviour to a queue.<br>
                            <span class='px-2 py-1 field1'>vacuum</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - Clears the last 10 non-event messages in the channel upon event creation.<br>
                            <span class='px-2 py-1 field1'>force_reminders</span><span class='px-2 py-1 field2'>&lt;boolean/number&gt;</span> - Force personal reminders. Time in minutes before the event.<br>
                            <span class='px-2 py-1 field1'>pin_message</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether this event should be pinned upon creation.<br>
                            <span class='px-2 py-1 field1'>deletion</span><span class='px-2 py-1 field2'>&lt;boolean/number&gt;</span> - The amount of hours this event will be deleted after it concluded.<br>
                            <span class='px-2 py-1 field1'>mention_mode</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - Mentions the members instead of displaying the plain name.<br>
                            <span class='px-2 py-1 field1'>preserve_order</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - Determines the behaviour of the order numbers when members change their sign-up.<br>
                            <span class='px-2 py-1 field1'>apply_unregister</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether the unregister role will be applied.<br>
                            <span class='px-2 py-1 field1'>apply_specreset</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - whether the reaction/button to reset the saved spec should be applied to the event.<br>
                            <span class='px-2 py-1 field1'>spec_saving</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether the bot should remember a members spec choice and apply it automatically.<br>
                            <span class='px-2 py-1 field1'>font_style</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The font style for the event title. Only applies if the title is shorter than 19 characters.<br>
                            <span class='px-2 py-1 field1'>alt_names</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether members will be allowed to enter a custom name when signing up.<br>
                            <span class='px-2 py-1 field1'>defaults_pre_req</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether a primary role is required to sign up with a default role (bench, late, tentative).<br>
                            <span class='px-2 py-1 field1'>show_on_overview</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether the event will be displayed on the /overview.<br>
                            <span class='px-2 py-1 field1'>mention_leader</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether the leaders name will be displayed as a mention.<br>
                            <span class='px-2 py-1 field1'>attendance</span><span class='px-2 py-1 field2'>&lt;boolean/string&gt;</span> - whether this event will count towards attendance. Enter a string to set an attendance tag.<br>
                            <span class='px-2 py-1 field1'>show_title</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether to show the title of this event.<br>
                            <span class='px-2 py-1 field1'>show_info</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether the info row will be shown (contains date, time and number of sign-ups).<br>
                            <span class='px-2 py-1 field1'>show_leader</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether the event leader will be shown.<br>
                            <span class='px-2 py-1 field1'>show_counter</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether the sign-ups counter will be shown.<br>
                            <span class='px-2 py-1 field1'>show_roles</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether to show the role counters above the sign-up content.<br>
                            <span class='px-2 py-1 field1'>show_content</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether the sign-ups will be displayed.<br>
                            <span class='px-2 py-1 field1'>show_classes</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether the class fields will always be shown.<br>
                            <span class='px-2 py-1 field1'>show_emotes</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether to show the spec emotes in front of sign-ups.<br>
                            <span class='px-2 py-1 field1'>show_numbering</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether to show the order number in front of sign-ups.<br>
                            <span class='px-2 py-1 field1'>show_allowed</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether to show the allowed roles in the footer if any are set.<br>
                            <span class='px-2 py-1 field1'>show_footer</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether to show the event footer.<br>
                            <span class='px-2 py-1 field1'>info_variant</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - whether the info field will be displayed in a short or long format.<br>
                            <span class='px-2 py-1 field1'>date_variant</span><span class='px-2 py-1 field2'>local/zoned</span> - whether the date & time on the event will be shown in the users local or zoned time.<br>
                            <span class='px-2 py-1 field1'>show_countdown</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether to show a countdown to the event start.<br>
                            <span class='px-2 py-1 field1'>disable_archiving</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - If archiving is enabled on your server you can exempt a specific event with this setting.<br>
                            <span class='px-2 py-1 field1'>bold_all</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - Set to false to not count consecutive sign-ups by a user to be counted towards limits.<br>
                            <span class='px-2 py-1 field1'>bench_emote</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id that will be used for the bench role. Set to "remove" to disable the role.<br>
                            <span class='px-2 py-1 field1'>late_emote</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id that will be used for the late role. Set to "remove" to disable the role.<br>
                            <span class='px-2 py-1 field1'>tentative_emote</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id that will be used for the tentative role. Set to "remove" to disable the role.<br>
                            <span class='px-2 py-1 field1'>absence_emote</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id that will be used for the absence role. Set to "remove" to disable the role.<br>
                            <span class='px-2 py-1 field1'>leader_emote</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id that will be used for the leader icon.<br>
                            <span class='px-2 py-1 field1'>signups1_emote</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id that will be used for the signups icon.<br>
                            <span class='px-2 py-1 field1'>signups2_emote</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id that will be used for the signups icon.<br>
                            <span class='px-2 py-1 field1'>date1_emote</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id that will be used for the date icon.<br>
                            <span class='px-2 py-1 field1'>date2_emote</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id that will be used for the date icon.<br>
                            <span class='px-2 py-1 field1'>time1_emote</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id that will be used for the time icon.<br>
                            <span class='px-2 py-1 field1'>time2_emote</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id that will be used for the time icon.<br>
                            <span class='px-2 py-1 field1'>countdown1_emote</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id that will be used for the countdown icon.<br>
                            <span class='px-2 py-1 field1'>countdown2_emote</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id that will be used for the countdown icon.<br>
                            <span class='px-2 py-1 field1'>event_type</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - whether this event will use interactions or reactions.<br>
                            <span class='px-2 py-1 field1'>reminder</span><span class='px-2 py-1 field2'>&lt;number/boolean&gt;</span> - Set the amount of minutes before the event a reminder will be sent.<br>
                            <span class='px-2 py-1 field1'>create_discordevent</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether a discord integrated event should be created (requires premium).<br>
                            <span class='px-2 py-1 field1'>create_thread</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether a thread should be created on the event.<br>
                            <span class='px-2 py-1 field1'>forum_tags</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The names of the forum tags that will be applied to the post. Separate multiple names by comma.<br>
                            <span class='px-2 py-1 field1'>delete_thread</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether the attached thread should be deleted if the event gets deleted.<br>
                            <span class='px-2 py-1 field1'>voice_channel</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The voicechannel used for this event.<br>
                            <span class='px-2 py-1 field1'>temp_voicechannel</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The name for a voicechannel that will be temporarily created.<br>
                            <span class='px-2 py-1 field1'>color</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The embed color for this event.<br>
                            <span class='px-2 py-1 field1'>response</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - Enter text that will be sent to the member when they sign up.<br>
                            <span class='px-2 py-1 field1'>temp_role</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The name of a discord role that will be assigned to the member upon signing up.<br>
                            <span class='px-2 py-1 field1'>allowed_roles</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The role names that are allowed to sign up. Separate multiple names by comma.<br>
                            <span class='px-2 py-1 field1'>banned_roles</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - the role names that are banned from signing up. Separate multiple names by comma.<br>
                            <span class='px-2 py-1 field1'>opt_out</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The id of an existing event to copy the sign-ups from upon event creation.<br>
                            <span class='px-2 py-1 field1'>mentions</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The role/member names to mention upon event creation. Separate multiple names by comma.<br>
                            <span class='px-2 py-1 field1'>image</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The URL of an image that will be displayed at the bottom of the event embed.<br>
                            <span class='px-2 py-1 field1'>thumbnail</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The URL to an image which will be displayed as a thumbnail on the event embed.<br>
                            <span class='px-2 py-1 field1'>use_nicknames</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - whether the members server nickname will be used or the global name.<br>

                          </div>
                  
                          <span class='px-2 py-1 field1'>classes</span><span class='px-2 py-1 field2'><button @click="classLimits = !classLimits" label>
                            <span style="color: #adb1b6;">&lt;object&gt;</span><v-icon v-if="!classLimits" small color="#adb1b6">mdi-chevron-down</v-icon><v-icon v-else small color="#adb1b6">mdi-chevron-up</v-icon>
                          </button></span> - The classes that you want to edit.<br>
                          <div style="border-left: 2px solid grey; line-height: 30px;" class="ml-5 px-3 my-2 mb-5" v-if="classLimits">
                            <span class='px-2 py-1 field1'>&lt;name&gt;</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The name of the class that you want to edit.<br>
                            <span class='px-2 py-1 field1'>&lt;limit&gt;</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The limit of this class.<br>
                          </div>

                          <span class='px-2 py-1 field1'>roles</span><span class='px-2 py-1 field2'><button @click="roleLimits = !roleLimits" label>
                            <span style="color: #adb1b6;">&lt;object&gt;</span><v-icon v-if="!roleLimits" small color="#adb1b6">mdi-chevron-down</v-icon><v-icon v-else small color="#adb1b6">mdi-chevron-up</v-icon>
                          </button></span> - The roles that you want to edit.<br>
                          <div style="border-left: 2px solid grey; line-height: 30px;" class="ml-5 px-3 my-2 mb-5" v-if="roleLimits">
                            <span class='px-2 py-1 field1'>&lt;name&gt;</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The name of the role that you want to edit.<br>
                            <span class='px-2 py-1 field1'>&lt;limit&gt;</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The limit of this role.<br>
                          </div>

                          <span class='px-2 py-1 field1'>softres</span><span class='px-2 py-1 field2'><button @click="softres = !softres" label>
                            <span style="color: #adb1b6;">&lt;object&gt;</span><v-icon v-if="!softres" small color="#adb1b6">mdi-chevron-down</v-icon><v-icon v-else small color="#adb1b6">mdi-chevron-up</v-icon>
                          </button></span> - The softres object that you want to be created.<br>
                          <div style="border-left: 2px solid grey; line-height: 30px;" class="ml-5 px-3 my-2 mb-5" v-if="softres">
                
                            <span class='px-2 py-1 field1'>instance</span><span class='px-2 py-1 field2'><button @click="softresInstance = !softresInstance" label>
                            <span style="color: #adb1b6;">&lt;string&gt;</span><v-icon v-if="!softresInstance" small color="#adb1b6">mdi-chevron-down</v-icon><v-icon v-else small color="#adb1b6">mdi-chevron-up</v-icon>
                          </button></span> - The raid instance this softres sheet will apply.<br>
                    <div style="border-left: 2px solid grey; line-height: 25px;" class="ml-5 px-3 my-2 mb-5" v-if="softresInstance">
                      <span style="background-color: #181a1c; padding: 3px; border-radius: 2px;">zg</span> - Zul'Gurub (Classic)<br>
                      <span style="background-color: #181a1c; padding: 3px; border-radius: 2px;">mc</span> - Molten Core (Classic)<br>
                      <span style="background-color: #181a1c; padding: 3px; border-radius: 2px;">onyxia</span> - Onyxia's Lair (Classic)<br>
                      <span style="background-color: #181a1c; padding: 3px; border-radius: 2px;">bwl</span> - Blackwing Lair (Classic)<br>
                      <span style="background-color: #181a1c; padding: 3px; border-radius: 2px;">aq20</span> - Ahn'Qiraj 20 (Classic)<br>
                      <span style="background-color: #181a1c; padding: 3px; border-radius: 2px;">aq40</span> - Ahn'Qiraj 40 (Classic)<br>
                      <span style="background-color: #181a1c; padding: 3px; border-radius: 2px;">naxxramas</span> - Naxxramas (Classic)<br>
                      <span style="background-color: #181a1c; padding: 3px; border-radius: 2px;">azuregos</span> - Azuregos (Classic)<br>
                      <span style="background-color: #181a1c; padding: 3px; border-radius: 2px;">lordkazzak</span> - Lord Kazzak (Classic)<br>
                      <span style="background-color: #181a1c; padding: 3px; border-radius: 2px;">bfdsod</span> - Blackfathom Deeps (SoD)<br>
                      <span style="background-color: #181a1c; padding: 3px; border-radius: 2px;">gnomeregansod</span> - Gnomeregan (SoD)<br>
                      <span style="background-color: #181a1c; padding: 3px; border-radius: 2px;">sunkentemplesod</span> - Sunken Temple (SoD)<br>
                      <span style="background-color: #181a1c; padding: 3px; border-radius: 2px;">azuregossod</span> - Azuregos (SoD)<br>
                      <span style="background-color: #181a1c; padding: 3px; border-radius: 2px;">lordkazzaksod</span> - Lord Kazzak (SoD)<br>
                      <span style="background-color: #181a1c; padding: 3px; border-radius: 2px;">mcsod</span> - Molten Core (SoD)<br>
                      <span style="background-color: #181a1c; padding: 3px; border-radius: 2px;">onyxiasod</span> - Onyxia (SoD)<br>
                      <span style="background-color: #181a1c; padding: 3px; border-radius: 2px;">bwlsod</span> - Blackwing Lair (SoD)<br>
                      <span style="background-color: #181a1c; padding: 3px; border-radius: 2px;">zgsod</span> - Zul'Gurub (SoD)<br>
                      <span style="background-color: #181a1c; padding: 3px; border-radius: 2px;">crystalvale</span> - The Crystal Vale (SoD)<br>
                      <span style="background-color: #181a1c; padding: 3px; border-radius: 2px;">kara</span> - Karazhan<br>
                      <span style="background-color: #181a1c; padding: 3px; border-radius: 2px;">gruul</span> - Gruul's Lair<br>
                      <span style="background-color: #181a1c; padding: 3px; border-radius: 2px;">magtheridon</span> - Magtheridon's Lair<br>
                      <span style="background-color: #181a1c; padding: 3px; border-radius: 2px;">gruulmag</span> - Gruul + Magtheridon<br>
                      <span style="background-color: #181a1c; padding: 3px; border-radius: 2px;">ssc</span> - Serpentshrine Cavern<br>
                      <span style="background-color: #181a1c; padding: 3px; border-radius: 2px;">tempestkeep</span> - Tempest Keep<br>
                      <span style="background-color: #181a1c; padding: 3px; border-radius: 2px;">hyjal</span> - Mount Hyjal<br>
                      <span style="background-color: #181a1c; padding: 3px; border-radius: 2px;">blacktemple</span> - Black Temple<br>
                      <span style="background-color: #181a1c; padding: 3px; border-radius: 2px;">bthyjal</span> - Black Temple + Hyjal<br>
                      <span style="background-color: #181a1c; padding: 3px; border-radius: 2px;">za</span> - Zul'Aman<br>
                      <span style="background-color: #181a1c; padding: 3px; border-radius: 2px;">sunwellplateau</span> - Sunwell Plateau<br>
                      <span style="background-color: #181a1c; padding: 3px; border-radius: 2px;">wotlknaxx10p2</span> - Naxxramas 10<br>
                      <span style="background-color: #181a1c; padding: 3px; border-radius: 2px;">wotlknaxx25</span> - Naxxramas 25<br>
                      <span style="background-color: #181a1c; padding: 3px; border-radius: 2px;">wyrmrest10p2</span> - Wyrmrest Temple 10<br>
                      <span style="background-color: #181a1c; padding: 3px; border-radius: 2px;">wyrmrest25</span> - Wyrmrest Temple 25<br>
                      <span style="background-color: #181a1c; padding: 3px; border-radius: 2px;">naxxdragons10p2</span> - Naxxramas + Dragons 10<br>
                      <span style="background-color: #181a1c; padding: 3px; border-radius: 2px;">naxxdragons25</span> - Naxxramas + Dragons 25<br>
                      <span style="background-color: #181a1c; padding: 3px; border-radius: 2px;">obsidiansanctum10p2</span> - Obsidian Sanctum 10<br>
                      <span style="background-color: #181a1c; padding: 3px; border-radius: 2px;">obsidiansanctum25</span> - Obsidian Sanctum 25<br>
                      <span style="background-color: #181a1c; padding: 3px; border-radius: 2px;">eyeofeternity10p2</span> - Eye of Eternity 10<br>
                      <span style="background-color: #181a1c; padding: 3px; border-radius: 2px;">eyeofeternity25</span> - Eye of Eternity 25<br>
                    </div>
                            <span class='px-2 py-1 field1'>faction</span><span class='px-2 py-1 field2'><button @click="softresFaction = !softresFaction" label>
                            <span style="color: #adb1b6;">&lt;string&gt;</span><v-icon v-if="!softresFaction" small color="#adb1b6">mdi-chevron-down</v-icon><v-icon v-else small color="#adb1b6">mdi-chevron-up</v-icon>
                          </button></span> - The faction of your team.<br>
                    <div style="border-left: 2px solid grey; line-height: 25px;" class="ml-5 px-3 my-2 mb-5" v-if="softresFaction">
                      <span style="background-color: #181a1c; padding: 3px; border-radius: 2px;">Horde</span><br>
                      <span style="background-color: #181a1c; padding: 3px; border-radius: 2px;">Alliance</span><br>
                    </div>
                            <span class='px-2 py-1 field1'>resLimitPerCharacter</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The maximum amount of reserves per character (0-10).<br>
                            <span class='px-2 py-1 field1'>resLimitPerItem</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The maximum amount of reserves per item (0-10).<br>
                            <span class='px-2 py-1 field1'>discordProtection</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether this softres sheet will require discord authentication.<br>
                            <span class='px-2 py-1 field1'>hideReserves</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether the reserves will be hidden on the softres sheet.<br>
             
                            <span class='px-2 py-1 field1'>botBehaviour</span><span class='px-2 py-1 field2'><button @click="softresBotBehaviour = !softresBotBehaviour" label>
                            <span style="color: #adb1b6;">&lt;string&gt;</span><v-icon v-if="!softresBotBehaviour" small color="#adb1b6">mdi-chevron-down</v-icon><v-icon v-else small color="#adb1b6">mdi-chevron-up</v-icon>
                          </button></span> - The behaviour of the bot when a user signs up.<br>
                    <div style="border-left: 2px solid grey; line-height: 25px;" class="ml-5 px-3 my-2 mb-5" v-if="softresBotBehaviour">
                      <span style="background-color: #181a1c; padding: 3px; border-radius: 2px;">dm</span> - The bot will send a DM to the user notifying him of the existing softres.<br>
                      <span style="background-color: #181a1c; padding: 3px; border-radius: 2px;">req</span> - The bot will require the user to have a reserve placed before signing up.<br>
                      <span style="background-color: #181a1c; padding: 3px; border-radius: 2px;">none</span> - The bot will not send a DM and not require a reserve.<br>
                    </div>
                          </div>
                          <span class='px-2 py-1 field1'>announcement</span><span class='px-2 py-1 field2'><button @click="announcement = !announcement" label>
                            <span style="color: #adb1b6;">&lt;object&gt;</span><v-icon v-if="!announcement" small color="#adb1b6">mdi-chevron-down</v-icon><v-icon v-else small color="#adb1b6">mdi-chevron-up</v-icon>
                          </button></span> - Set the announcement for this event.<br>
                          <div style="border-left: 2px solid grey; line-height: 30px;" class="ml-5 px-3 my-2 mb-5" v-if="announcement">
                            <span class='px-2 py-1 field1'>channel</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The channel id or keyword for this announcement.<br>
                            <span class='px-2 py-1 field1'>time</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The time in minutes before the event to send this announcement.<br>
                            <span class='px-2 py-1 field1'>message</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The message that the announcement will send.<br>
                          </div>
                  </v-expansion-panel-content>
              </v-expansion-panel>
                   <h1 class="mt-10 mb-7">Response Structure</h1>

                   <v-expansion-panel class="my-1">
                  <v-expansion-panel-header style="font-size: 18px;" color="#18191c">
                    <v-row>
                      <div><span class='px-2 py-1 field2'>Body</span></div><br>
                    </v-row>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content style="background-color: #1c1d21a6; line-height: 30px;" class="pt-5">
                    <span class='px-2 py-1 field1'>status</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - Indicates the success or failure of the request.<br>
                    <span class='px-2 py-1 field1'>event</span><span class='px-2 py-1 field2'><button @click="event = !event" label>
                            <span style="color: #adb1b6;">&lt;object&gt;</span><v-icon v-if="!event" small color="#adb1b6">mdi-chevron-down</v-icon><v-icon v-else small color="#adb1b6">mdi-chevron-up</v-icon>
                          </button></span> - The event object.<br>
                          <div style="border-left: 2px solid grey; line-height: 30px;" class="ml-5 px-3 my-2 mb-5" v-if="event">
                            <span class='px-2 py-1 field1'>id</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The message id of this event.<br>
                    <span class='px-2 py-1 field1'>serverId</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The server id of this event.<br>
                    <span class='px-2 py-1 field1'>leaderId</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The user id of this events leader.<br>
                    <span class='px-2 py-1 field1'>leaderName</span><span class='px-2 py-1 field2'>&lt;string&gt;</span>- The name of events leader.<br>
                    <span class='px-2 py-1 field1'>channelId</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - This events channel id.<br>
                    <span class='px-2 py-1 field1'>channelName</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - This events channel name.<br>
                    <span class='px-2 py-1 field1'>channelType</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The type of this events channel.<br>
                    <span class='px-2 py-1 field1'>templateId</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The id of this events template.<br>
                    <span class='px-2 py-1 field1'>templateEmoteId</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id of the emote used to represent the template of this event.<br>
                    <span class='px-2 py-1 field1'>title</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The event title.<br>
                    <span class='px-2 py-1 field1'>description</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The event description.<br>
                    <span class='px-2 py-1 field1'>startTime</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The unix timestamp of when this event will start.<br>
                    <span class='px-2 py-1 field1'>endTime</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The unix timestamp of when this event will end.<br>
                    <span class='px-2 py-1 field1'>closingTime</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The unix timestamp of when this event will close and deny further sign-ups.<br>
                    <span class='px-2 py-1 field1'>date</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The raw date string of when this event will start.<br>
                    <span class='px-2 py-1 field1'>time</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The raw time string of when this event will start.<br>
                    <span class='px-2 py-1 field1'>advancedSettings</span><span class='px-2 py-1 field2'><button @click="advancedSettings = !advancedSettings" label>
                            <span style="color: #adb1b6;">&lt;object&gt;</span><v-icon v-if="!advancedSettings" small color="#adb1b6">mdi-chevron-down</v-icon><v-icon v-else small color="#adb1b6">mdi-chevron-up</v-icon>
                          </button></span> - The advanced settings for this event.<br>
                          <div style="border-left: 2px solid grey; line-height: 30px;" class="ml-5 px-3 my-2 mb-5" v-if="advancedSettings">
                            <span class='px-2 py-1 field1'>duration</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The duration of the event in minutes.<br>
                            <span class='px-2 py-1 field1'>deadline</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The deadline in hours before the event. <br>
                            <span class='px-2 py-1 field1'>limit</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The maximum amount of active sign-ups.<br>
                            <span class='px-2 py-1 field1'>lock_at_limit</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether the event will lock when the limit is reached.<br>
                            <span class='px-2 py-1 field1'>limit_per_user</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The maximum amount of sign-ups per member.<br>
                            <span class='px-2 py-1 field1'>lower_limit</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The minimum amount of sign-ups required for the event to happen.<br>
                            <span class='px-2 py-1 field1'>allow_duplicate</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether a member can sign up multiple times to the same class.<br>
                            <span class='px-2 py-1 field1'>horizontal_mode</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether sign-ups will be displayed horizontally instead of vertically.<br>
                            <span class='px-2 py-1 field1'>bench_overflow</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether sign-ups past limits will be benched or simply denied.<br>
                            <span class='px-2 py-1 field1'>queue_bench</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - Changes the bench behaviour to a queue.<br>
                            <span class='px-2 py-1 field1'>vacuum</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - Clears the last 10 non-event messages in the channel upon event creation.<br>
                            <span class='px-2 py-1 field1'>force_reminders</span><span class='px-2 py-1 field2'>&lt;boolean/number&gt;</span> - Force personal reminders. Time in minutes before the event.<br>
                            <span class='px-2 py-1 field1'>pin_message</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether this event should be pinned upon creation.<br>
                            <span class='px-2 py-1 field1'>deletion</span><span class='px-2 py-1 field2'>&lt;boolean/number&gt;</span> - The amount of hours this event will be deleted after it concluded.<br>
                            <span class='px-2 py-1 field1'>mention_mode</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - Mentions the members instead of displaying the plain name.<br>
                            <span class='px-2 py-1 field1'>preserve_order</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - Determines the behaviour of the order numbers when members change their sign-up.<br>
                            <span class='px-2 py-1 field1'>apply_unregister</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether the unregister role will be applied.<br>
                            <span class='px-2 py-1 field1'>apply_specreset</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - whether the reaction/button to reset the saved spec should be applied to the event.<br>
                            <span class='px-2 py-1 field1'>spec_saving</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether the bot should remember a members spec choice and apply it automatically.<br>
                            <span class='px-2 py-1 field1'>font_style</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The font style for the event title. Only applies if the title is shorter than 19 characters.<br>
                            <span class='px-2 py-1 field1'>alt_names</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether members will be allowed to enter a custom name when signing up.<br>
                            <span class='px-2 py-1 field1'>defaults_pre_req</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether a primary role is required to sign up with a default role (bench, late, tentative).<br>
                            <span class='px-2 py-1 field1'>show_on_overview</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether the event will be displayed on the /overview.<br>
                            <span class='px-2 py-1 field1'>mention_leader</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether the leaders name will be displayed as a mention.<br>
                            <span class='px-2 py-1 field1'>attendance</span><span class='px-2 py-1 field2'>&lt;boolean/string&gt;</span> - whether this event will count towards attendance. Enter a string to set an attendance tag.<br>
                            <span class='px-2 py-1 field1'>show_title</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether to show the title of this event.<br>
                            <span class='px-2 py-1 field1'>show_info</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether the info row will be shown (contains date, time and number of sign-ups).<br>
                            <span class='px-2 py-1 field1'>show_leader</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether the event leader will be shown.<br>
                            <span class='px-2 py-1 field1'>show_counter</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether the sign-ups counter will be shown.<br>
                            <span class='px-2 py-1 field1'>show_roles</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether to show the role counters above the sign-up content.<br>
                            <span class='px-2 py-1 field1'>show_content</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether the sign-ups will be displayed.<br>
                            <span class='px-2 py-1 field1'>show_classes</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether the class fields will always be shown.<br>
                            <span class='px-2 py-1 field1'>show_emotes</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether to show the spec emotes in front of sign-ups.<br>
                            <span class='px-2 py-1 field1'>show_numbering</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether to show the order number in front of sign-ups.<br>
                            <span class='px-2 py-1 field1'>show_allowed</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether to show the allowed roles in the footer if any are set.<br>
                            <span class='px-2 py-1 field1'>show_footer</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether to show the event footer.<br>
                            <span class='px-2 py-1 field1'>info_variant</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - whether the info field will be displayed in a short or long format.<br>
                            <span class='px-2 py-1 field1'>date_variant</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - whether the date & time on the event will be shown in the users local or zoned time.<br>
                            <span class='px-2 py-1 field1'>show_countdown</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether to show a countdown to the event start.<br>
                            <span class='px-2 py-1 field1'>disable_archiving</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - If archiving is enabled on your server you can exempt a specific event with this setting.<br>
                            <span class='px-2 py-1 field1'>bold_all</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - Set to false to not count consecutive sign-ups by a user to be counted towards limits.<br>
                            <span class='px-2 py-1 field1'>bench_emote</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id that will be used for the bench role. Set to "remove" to disable the role.<br>
                            <span class='px-2 py-1 field1'>late_emote</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id that will be used for the late role. Set to "remove" to disable the role.<br>
                            <span class='px-2 py-1 field1'>tentative_emote</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id that will be used for the tentative role. Set to "remove" to disable the role.<br>
                            <span class='px-2 py-1 field1'>absence_emote</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id that will be used for the absence role. Set to "remove" to disable the role.<br>
                            <span class='px-2 py-1 field1'>leader_emote</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id that will be used for the leader icon.<br>
                            <span class='px-2 py-1 field1'>signups1_emote</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id that will be used for the signups icon.<br>
                            <span class='px-2 py-1 field1'>signups2_emote</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id that will be used for the signups icon.<br>
                            <span class='px-2 py-1 field1'>date1_emote</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id that will be used for the date icon.<br>
                            <span class='px-2 py-1 field1'>date2_emote</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id that will be used for the date icon.<br>
                            <span class='px-2 py-1 field1'>time1_emote</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id that will be used for the time icon.<br>
                            <span class='px-2 py-1 field1'>time2_emote</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id that will be used for the time icon.<br>
                            <span class='px-2 py-1 field1'>countdown1_emote</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id that will be used for the countdown icon.<br>
                            <span class='px-2 py-1 field1'>countdown2_emote</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id that will be used for the countdown icon.<br>
                            <span class='px-2 py-1 field1'>event_type</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - whether this event will use interactions or reactions.<br>
                            <span class='px-2 py-1 field1'>reminder</span><span class='px-2 py-1 field2'>&lt;number/boolean&gt;</span> - Set the amount of minutes before the event a reminder will be sent.<br>
                            <span class='px-2 py-1 field1'>create_discordevent</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether a discord integrated event should be created (requires premium).<br>
                            <span class='px-2 py-1 field1'>create_thread</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether a thread should be created on the event.<br>
                            <span class='px-2 py-1 field1'>delete_thread</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether the attached thread should be deleted if the event gets deleted.<br>
                            <span class='px-2 py-1 field1'>voice_channel</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The voicechannel used for this event.<br>
                            <span class='px-2 py-1 field1'>temp_voicechannel</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The name for a voicechannel that will be temporarily created.<br>
                            <span class='px-2 py-1 field1'>color</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The embed color for this event.<br>
                            <span class='px-2 py-1 field1'>response</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - Enter text that will be sent to the member when they sign up.<br>
                            <span class='px-2 py-1 field1'>temp_role</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The name of a discord role that will be assigned to the member upon signing up.<br>
                            <span class='px-2 py-1 field1'>allowed_roles</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The role names that are allowed to sign up. Separate multiple names by comma.<br>
                            <span class='px-2 py-1 field1'>forum_tags</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The names of the forum tags that will be applied to the post. Separate multiple names by comma.<br>
                            <span class='px-2 py-1 field1'>banned_roles</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - the role names that are banned from signing up. Separate multiple names by comma.<br>
                            <span class='px-2 py-1 field1'>opt_out</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The id of an existing event to copy the sign-ups from upon event creation.<br>
                            <span class='px-2 py-1 field1'>mentions</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The role/member names to mention upon event creation. Separate multiple names by comma.<br>
                            <span class='px-2 py-1 field1'>image</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The URL of an image that will be displayed at the bottom of the event embed.<br>
                            <span class='px-2 py-1 field1'>thumbnail</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The URL to an image which will be displayed as a thumbnail on the event embed.<br>
                            <span class='px-2 py-1 field1'>use_nicknames</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - whether the members server nickname will be used or the global name.<br>

                          </div>
                    <span class='px-2 py-1 field1'>classes</span><span class='px-2 py-1 field2'><button @click="classes = !classes" label>
                            <span style="color: #adb1b6;">&lt;array of objects&gt;</span><v-icon v-if="!classes" small color="#adb1b6">mdi-chevron-down</v-icon><v-icon v-else small color="#adb1b6">mdi-chevron-up</v-icon>
                          </button></span> - The classes that are applied to this event.<br>
                          <div style="border-left: 2px solid grey; line-height: 30px;" class="ml-5 px-3 my-2 mb-5" v-if="classes">
                            <span class='px-2 py-1 field1'>name</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The name of this class.<br>
                            <span class='px-2 py-1 field1'>limit</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The maximum allowed sign-ups for this class.<br>
                            <span class='px-2 py-1 field1'>emoteId</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id of this class.<br>
                            <span class='px-2 py-1 field1'>type</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The type (primary/default) of this class.<br>
                            <span class='px-2 py-1 field1'>specs</span><span class='px-2 py-1 field2'><button @click="specs = !specs" label>
                            <span style="color: #adb1b6;">&lt;array of objects&gt;</span><v-icon v-if="!specs" small color="#adb1b6">mdi-chevron-down</v-icon><v-icon v-else small color="#adb1b6">mdi-chevron-up</v-icon>
                          </button></span> - The classes that are applied to this event.<br>
                          <div style="border-left: 2px solid grey; line-height: 30px;" class="ml-5 px-3 my-2 mb-5" v-if="specs">
                            <span class='px-2 py-1 field1'>name</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The name of this spec.<br>
                            <span class='px-2 py-1 field1'>emoteId</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id of this spec.<br>
                            <span class='px-2 py-1 field1'>roleName</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The name of the role that this spec belongs to.<br>
                            <span class='px-2 py-1 field1'>roleEmoteId</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id of the role that this spec belongs to.<br>
                          </div>
                          </div>
                    <span class='px-2 py-1 field1'>roles</span><span class='px-2 py-1 field2'><button @click="roles = !roles" label>
                            <span style="color: #adb1b6;">&lt;array of objects&gt;</span><v-icon v-if="!roles" small color="#adb1b6">mdi-chevron-down</v-icon><v-icon v-else small color="#adb1b6">mdi-chevron-up</v-icon>
                          </button></span> - The roles that are applied to this event.<br>
                          <div style="border-left: 2px solid grey; line-height: 30px;" class="ml-5 px-3 my-2 mb-5" v-if="roles">
                            <span class='px-2 py-1 field1'>name</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The name of this role.<br>
                            <span class='px-2 py-1 field1'>limit</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The maximum allowed sign-ups for this role.<br>
                            <span class='px-2 py-1 field1'>emoteId</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id of this role.<br>
                          </div>
                    <span class='px-2 py-1 field1'>signUps</span><span class='px-2 py-1 field2'><button @click="signUps = !signUps" label>
                            <span style="color: #adb1b6;">&lt;array of objects&gt;</span><v-icon v-if="!signUps" small color="#adb1b6">mdi-chevron-down</v-icon><v-icon v-else small color="#adb1b6">mdi-chevron-up</v-icon>
                          </button></span> - The current sign-ups on this event.<br>
                          <div style="border-left: 2px solid grey; line-height: 30px;" class="ml-5 px-3 my-2 mb-5" v-if="signUps">
                            <span class='px-2 py-1 field1'>name</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The name of the user.<br>
                            <span class='px-2 py-1 field1'>id</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The Discord id of the user. <br>
                            <span class='px-2 py-1 field1'>className</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The class name of the sign-up.<br>
                            <span class='px-2 py-1 field1'>classEmoteId</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The class emote id of the sign-up.<br>
                            <span class='px-2 py-1 field1'>specName</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - the spec name of the sign-up.<br>
                            <span class='px-2 py-1 field1'>specEmoteId</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The spec emote id of the sign-up.<br>
                            <span class='px-2 py-1 field1'>roleName</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The role name of the sign-up.<br>
                            <span class='px-2 py-1 field1'>roleEmoteId</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The role emote id of the sign-up.<br>
                            <span class='px-2 py-1 field1'>status</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The status (primary/queued) of the sign-up.<br>
                            <span class='px-2 py-1 field1'>entryTime</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The unix timestamp of the registration time.<br>
                            <span class='px-2 py-1 field1'>position</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The order number of this sign-up.<br>
                          </div>
                    <span class='px-2 py-1 field1'>lastUpdated</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The unix timestamp of when this event was updated last.<br>
                    <span class='px-2 py-1 field1'>softresId</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The softres id attached to this event.<br>
                    <span class='px-2 py-1 field1'>color</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The current embed color in RGB format.<br>
                          </div>

                  </v-expansion-panel-content>
              </v-expansion-panel>
           
                  </v-expansion-panel-content>
              </v-expansion-panel>





<v-expansion-panel active-class="panel" class="my-1">
                  <v-expansion-panel-header style="font-size: 15px;" color="#18191c">
                    <v-row>
                      <v-col cols="auto" class="mb-0" style="min-width: 90px; text-align: center; justify-content: center; padding-top: 35px;">
                        <div style="font-weight: 800; font-size: 20px; color: red; margin: auto; text-align: center;">DELETE</div>
                      </v-col>
                      <v-col>
                    <v-col cols="12" class="mb-n4">
                      <span style="word-break: break-word;">https://raid-helper.dev/api/v2/events/<span style="color: purple;">EVENTID</span></span>
                    </v-col>
                    <v-col cols="12" class="mb-0">
                    <span style="color:gray; font-size: 15px;">Delete an event.</span>
                    </v-col>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content style="background-color: #373a3e;" class="pt-5">
                      <v-row justify="center">
                  <v-btn style="text-transform: none;" elevation="3" depressed x-small color="purple darken-2" class="pa-2 mr-3 mb-8 mt-5" @click="copyText('https://raid-helper.dev/api/v2/events/EVENTID')">Copy URL</v-btn>
                  <v-btn style="text-transform: none;" elevation="3" depressed x-small color="purple darken-2" class="pa-2 mr-3 mb-8 mt-5" @click="copyText(curl6)">Copy cURL</v-btn>
                      </v-row>	
                   This request is used to delete an event. The event is defined in the route.
                   <h1 class="mt-10 mb-7">Request Structure</h1>
                   <v-expansion-panel class="my-1">
                  <v-expansion-panel-header style="font-size: 18px;" color="#18191c">
                    <v-row>
                      <div><span class='px-2 py-1 field2'>Headers</span> <span style="color: grey; font-size: 15px;" class="mx-2"></span></div><br>
                    </v-row>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content style="background-color: #1c1d21a6; line-height: 30px;" class="pt-5">
                    <span class='px-2 py-1 pill1'>Authorization</span><span class='px-2 py-1 pill2'>&lt;apikey&gt;</span>
                          <v-tooltip top max-width="600" transition="v-scale-transition" color="#0f0f0f">
                          <template v-slot:activator="{ on }">
                            <span v-on="on" class='ml-1 px-1 py-1 pill3'><v-icon style="margin-bottom: 2px;" x-small color="yellow">mdi-asterisk</v-icon></span>
                          </template>
                          <span style="color: yellow;">Required Field</span>
                        </v-tooltip> - Your servers api key which you can get and refresh via /apikey.                         
                   
                  </v-expansion-panel-content>
              </v-expansion-panel>
                   
                   <h1 class="mt-10 mb-7">Response Structure</h1>

                   <v-expansion-panel class="my-1">
                  <v-expansion-panel-header style="font-size: 18px;" color="#18191c">
                    <v-row>
                      <div><span class='px-2 py-1 field2'>Body</span></div><br>
                    </v-row>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content style="background-color: #1c1d21a6; line-height: 30px;" class="pt-5">
                    <span class='px-2 py-1 field1'>status</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - Indicates the success or failure of the request.<br>
                  </v-expansion-panel-content>
              </v-expansion-panel>
           
                  </v-expansion-panel-content>
              </v-expansion-panel>







<v-expansion-panel active-class="panel" class="my-1">
                  <v-expansion-panel-header style="font-size: 15px;" color="#18191c">
                    <v-row>
                      <v-col cols="auto" class="mb-0" style="min-width: 90px; text-align: center; justify-content: center; padding-top: 35px;">
                        <div style="font-weight: 800; font-size: 20px; color: yellow; margin: auto; text-align: center;">POST</div>
                      </v-col>
                      <v-col>
                    <v-col cols="12" class="mb-n4">
                      <span style="word-break: break-word;">https://raid-helper.dev/api/v2/events/<span style="color: purple;">EVENTID</span>/signups</span>
                    </v-col>
                    <v-col cols="12" class="mb-0">
                    <span style="color:gray; font-size: 15px;">Add a sign-up to an event.</span>
                    </v-col>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content style="background-color: #373a3e;" class="pt-5">
                      <v-row justify="center">
                  <v-btn style="text-transform: none;" elevation="3" depressed x-small color="purple darken-2" class="pa-2 mr-3 mb-8 mt-5" @click="copyText('https://raid-helper.dev/api/v2/events/EVENTID/signups')">Copy URL</v-btn>
                  <v-btn style="text-transform: none;" elevation="3" depressed x-small color="purple darken-2" class="pa-2 mr-3 mb-8 mt-5" @click="copyText(curl4)">Copy cURL</v-btn>
                      </v-row>	
                   This request is used to add a sign-up to an event. The event is defined in the url. All other options are defined in the JSON body.
                   <h1 class="mt-10 mb-7">Request Structure</h1>
                   <v-expansion-panel class="my-1">
                  <v-expansion-panel-header style="font-size: 18px;" color="#18191c">
                    <v-row>
                      <div><span class='px-2 py-1 field2'>Headers</span> <span style="color: grey; font-size: 15px;" class="mx-2"></span></div><br>
                    </v-row>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content style="background-color: #1c1d21a6; line-height: 30px;" class="pt-5">
                    <span class='px-2 py-1 pill1'>Authorization</span><span class='px-2 py-1 pill2'>&lt;apikey&gt;</span>
                          <v-tooltip top max-width="600" transition="v-scale-transition" color="#0f0f0f">
                          <template v-slot:activator="{ on }">
                            <span v-on="on" class='ml-1 px-1 py-1 pill3'><v-icon style="margin-bottom: 2px;" x-small color="yellow">mdi-asterisk</v-icon></span>
                          </template>
                          <span style="color: yellow;">Required Field</span>
                        </v-tooltip> - Your servers api key which you can get and refresh via /apikey.                         
                      <br>
                      <span class='px-2 py-1 pill1'>Content-Type</span><span class='px-2 py-1 pill2'>application/json; charset=utf-8</span>
                            <v-tooltip top max-width="600" transition="v-scale-transition" color="#0f0f0f">
                          <template v-slot:activator="{ on }">
                            <span v-on="on" class='ml-1 px-1 py-1 pill3'><v-icon style="margin-bottom: 2px;" x-small color="yellow">mdi-asterisk</v-icon></span>
                          </template>
                          <span style="color: yellow;">Required Field</span>
                        </v-tooltip> - Defines the content type.                         
                          <br>
                  </v-expansion-panel-content>
              </v-expansion-panel>
                   <v-expansion-panel class="my-1">
                  <v-expansion-panel-header style="font-size: 18px;" color="#18191c">
                    <v-row>
                      <div><span class='px-2 py-1 field2'>Body</span> <span style="color: grey; font-size: 15px;" class="mx-2"></span></div><br>
                    </v-row>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content style="background-color: #1c1d21a6; line-height: 30px;" class="pt-5">
                    <span class='px-2 py-1 pill1'>userId</span><span class='px-2 py-1 pill2'>&lt;string&gt;</span>
                          <v-tooltip top max-width="600" transition="v-scale-transition" color="#0f0f0f">
                          <template v-slot:activator="{ on }">
                            <span v-on="on" class='ml-1 px-1 py-1 pill3'><v-icon style="margin-bottom: 2px;" x-small color="yellow">mdi-asterisk</v-icon></span>
                          </template>
                          <span style="color: yellow;">Required Field</span>
                        </v-tooltip> - The Discord id of the user that will added to the event.<br>

                     <span class='px-2 py-1 pill1'>className</span><span class='px-2 py-1 pill2'>&lt;string&gt;</span>
                          <v-tooltip top max-width="600" transition="v-scale-transition" color="#0f0f0f">
                          <template v-slot:activator="{ on }">
                            <span v-on="on" class='ml-1 px-1 py-1 pill3'><v-icon style="margin-bottom: 2px;" x-small color="yellow">mdi-asterisk</v-icon></span>
                          </template>
                          <span style="color: yellow;">Required Field</span>
                        </v-tooltip> - The class name this sign-up will be assigned to.<br>                       

                   <span class='px-2 py-1 field1'>specName</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The spec name this sign-up will be assigned to.<br>
                    <span class='px-2 py-1 field1'>name</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The name for this sign-up, defaults to members name.<br>
                    <span class='px-2 py-1 field1'>position</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The position number for this sign-up, defaults to highest.<br>
                    <span class='px-2 py-1 field1'>notify</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether the user will be notified, defaults to false.<br>
                    <span class='px-2 py-1 field1'>reason</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - A reason that will be shown on the notification/logs.<br>
                    <span class='px-2 py-1 field1'>isFake</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - Set to true to create a fake user. Does not require userId to be set.<br>

                  </v-expansion-panel-content>
              </v-expansion-panel>
                   <h1 class="mt-10 mb-7">Response Structure</h1>

                   <v-expansion-panel class="my-1">
                  <v-expansion-panel-header style="font-size: 18px;" color="#18191c">
                    <v-row>
                      <div><span class='px-2 py-1 field2'>Body</span></div><br>
                    </v-row>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content style="background-color: #1c1d21a6; line-height: 30px;" class="pt-5">
                    <span class='px-2 py-1 field1'>status</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - Indicates the success or failure of the request.<br>
                    <span class='px-2 py-1 field1'>event</span><span class='px-2 py-1 field2'><button @click="event = !event" label>
                            <span style="color: #adb1b6;">&lt;object&gt;</span><v-icon v-if="!event" small color="#adb1b6">mdi-chevron-down</v-icon><v-icon v-else small color="#adb1b6">mdi-chevron-up</v-icon>
                          </button></span> - The event object.<br>
                          <div style="border-left: 2px solid grey; line-height: 30px;" class="ml-5 px-3 my-2 mb-5" v-if="event">
                            <span class='px-2 py-1 field1'>id</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The message id of this event.<br>
                    <span class='px-2 py-1 field1'>serverId</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The server id of this event.<br>
                    <span class='px-2 py-1 field1'>leaderId</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The user id of this events leader.<br>
                    <span class='px-2 py-1 field1'>leaderName</span><span class='px-2 py-1 field2'>&lt;string&gt;</span>- The name of events leader.<br>
                    <span class='px-2 py-1 field1'>channelId</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - This events channel id.<br>
                    <span class='px-2 py-1 field1'>channelName</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - This events channel name.<br>
                    <span class='px-2 py-1 field1'>channelType</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The type of this events channel.<br>
                    <span class='px-2 py-1 field1'>templateId</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The id of this events template.<br>
                    <span class='px-2 py-1 field1'>templateEmoteId</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id of the emote used to represent the template of this event.<br>
                    <span class='px-2 py-1 field1'>title</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The event title.<br>
                    <span class='px-2 py-1 field1'>description</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The event description.<br>
                    <span class='px-2 py-1 field1'>startTime</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The unix timestamp of when this event will start.<br>
                    <span class='px-2 py-1 field1'>endTime</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The unix timestamp of when this event will end.<br>
                    <span class='px-2 py-1 field1'>closingTime</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The unix timestamp of when this event will close and deny further sign-ups.<br>
                    <span class='px-2 py-1 field1'>date</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The raw date string of when this event will start.<br>
                    <span class='px-2 py-1 field1'>time</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The raw time string of when this event will start.<br>
                    <span class='px-2 py-1 field1'>advancedSettings</span><span class='px-2 py-1 field2'><button @click="advancedSettings = !advancedSettings" label>
                            <span style="color: #adb1b6;">&lt;object&gt;</span><v-icon v-if="!advancedSettings" small color="#adb1b6">mdi-chevron-down</v-icon><v-icon v-else small color="#adb1b6">mdi-chevron-up</v-icon>
                          </button></span> - The advanced settings for this event.<br>
                          <div style="border-left: 2px solid grey; line-height: 30px;" class="ml-5 px-3 my-2 mb-5" v-if="advancedSettings">
                            <span class='px-2 py-1 field1'>duration</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The duration of the event in minutes.<br>
                            <span class='px-2 py-1 field1'>deadline</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The deadline in hours before the event. <br>
                            <span class='px-2 py-1 field1'>limit</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The maximum amount of active sign-ups.<br>
                            <span class='px-2 py-1 field1'>lock_at_limit</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether the event will lock when the limit is reached.<br>
                            <span class='px-2 py-1 field1'>limit_per_user</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The maximum amount of sign-ups per member.<br>
                            <span class='px-2 py-1 field1'>lower_limit</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The minimum amount of sign-ups required for the event to happen.<br>
                            <span class='px-2 py-1 field1'>allow_duplicate</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether a member can sign up multiple times to the same class.<br>
                            <span class='px-2 py-1 field1'>horizontal_mode</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether sign-ups will be displayed horizontally instead of vertically.<br>
                            <span class='px-2 py-1 field1'>bench_overflow</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether sign-ups past limits will be benched or simply denied.<br>
                            <span class='px-2 py-1 field1'>queue_bench</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - Changes the bench behaviour to a queue.<br>
                            <span class='px-2 py-1 field1'>vacuum</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - Clears the last 10 non-event messages in the channel upon event creation.<br>
                            <span class='px-2 py-1 field1'>force_reminders</span><span class='px-2 py-1 field2'>&lt;boolean/number&gt;</span> - Force personal reminders. Time in minutes before the event.<br>
                            <span class='px-2 py-1 field1'>pin_message</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether this event should be pinned upon creation.<br>
                            <span class='px-2 py-1 field1'>deletion</span><span class='px-2 py-1 field2'>&lt;boolean/number&gt;</span> - The amount of hours this event will be deleted after it concluded.<br>
                            <span class='px-2 py-1 field1'>mention_mode</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - Mentions the members instead of displaying the plain name.<br>
                            <span class='px-2 py-1 field1'>preserve_order</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - Determines the behaviour of the order numbers when members change their sign-up.<br>
                            <span class='px-2 py-1 field1'>apply_unregister</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether the unregister role will be applied.<br>
                            <span class='px-2 py-1 field1'>apply_specreset</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - whether the reaction/button to reset the saved spec should be applied to the event.<br>
                            <span class='px-2 py-1 field1'>spec_saving</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether the bot should remember a members spec choice and apply it automatically.<br>
                            <span class='px-2 py-1 field1'>font_style</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The font style for the event title. Only applies if the title is shorter than 19 characters.<br>
                            <span class='px-2 py-1 field1'>alt_names</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether members will be allowed to enter a custom name when signing up.<br>
                            <span class='px-2 py-1 field1'>defaults_pre_req</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether a primary role is required to sign up with a default role (bench, late, tentative).<br>
                            <span class='px-2 py-1 field1'>show_on_overview</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether the event will be displayed on the /overview.<br>
                            <span class='px-2 py-1 field1'>mention_leader</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether the leaders name will be displayed as a mention.<br>
                            <span class='px-2 py-1 field1'>attendance</span><span class='px-2 py-1 field2'>&lt;boolean/string&gt;</span> - whether this event will count towards attendance. Enter a string to set an attendance tag.<br>
                            <span class='px-2 py-1 field1'>show_title</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether to show the title of this event.<br>
                            <span class='px-2 py-1 field1'>show_info</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether the info row will be shown (contains date, time and number of sign-ups).<br>
                            <span class='px-2 py-1 field1'>show_leader</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether the event leader will be shown.<br>
                            <span class='px-2 py-1 field1'>show_counter</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether the sign-ups counter will be shown.<br>
                            <span class='px-2 py-1 field1'>show_roles</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether to show the role counters above the sign-up content.<br>
                            <span class='px-2 py-1 field1'>show_content</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether the sign-ups will be displayed.<br>
                            <span class='px-2 py-1 field1'>show_classes</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether the class fields will always be shown.<br>
                            <span class='px-2 py-1 field1'>show_emotes</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether to show the spec emotes in front of sign-ups.<br>
                            <span class='px-2 py-1 field1'>show_numbering</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether to show the order number in front of sign-ups.<br>
                            <span class='px-2 py-1 field1'>show_allowed</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether to show the allowed roles in the footer if any are set.<br>
                            <span class='px-2 py-1 field1'>show_footer</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether to show the event footer.<br>
                            <span class='px-2 py-1 field1'>info_variant</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - whether the info field will be displayed in a short or long format.<br>
                            <span class='px-2 py-1 field1'>date_variant</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - whether the date & time on the event will be shown in the users local or zoned time.<br>
                            <span class='px-2 py-1 field1'>show_countdown</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether to show a countdown to the event start.<br>
                            <span class='px-2 py-1 field1'>disable_archiving</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - If archiving is enabled on your server you can exempt a specific event with this setting.<br>
                            <span class='px-2 py-1 field1'>bold_all</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - Set to false to not count consecutive sign-ups by a user to be counted towards limits.<br>
                            <span class='px-2 py-1 field1'>bench_emote</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id that will be used for the bench role. Set to "remove" to disable the role.<br>
                            <span class='px-2 py-1 field1'>late_emote</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id that will be used for the late role. Set to "remove" to disable the role.<br>
                            <span class='px-2 py-1 field1'>tentative_emote</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id that will be used for the tentative role. Set to "remove" to disable the role.<br>
                            <span class='px-2 py-1 field1'>absence_emote</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id that will be used for the absence role. Set to "remove" to disable the role.<br>
                            <span class='px-2 py-1 field1'>leader_emote</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id that will be used for the leader icon.<br>
                            <span class='px-2 py-1 field1'>signups1_emote</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id that will be used for the signups icon.<br>
                            <span class='px-2 py-1 field1'>signups2_emote</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id that will be used for the signups icon.<br>
                            <span class='px-2 py-1 field1'>date1_emote</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id that will be used for the date icon.<br>
                            <span class='px-2 py-1 field1'>date2_emote</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id that will be used for the date icon.<br>
                            <span class='px-2 py-1 field1'>time1_emote</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id that will be used for the time icon.<br>
                            <span class='px-2 py-1 field1'>time2_emote</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id that will be used for the time icon.<br>
                            <span class='px-2 py-1 field1'>countdown1_emote</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id that will be used for the countdown icon.<br>
                            <span class='px-2 py-1 field1'>countdown2_emote</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id that will be used for the countdown icon.<br>
                            <span class='px-2 py-1 field1'>event_type</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - whether this event will use interactions or reactions.<br>
                            <span class='px-2 py-1 field1'>reminder</span><span class='px-2 py-1 field2'>&lt;number/boolean&gt;</span> - Set the amount of minutes before the event a reminder will be sent.<br>
                            <span class='px-2 py-1 field1'>create_discordevent</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether a discord integrated event should be created (requires premium).<br>
                            <span class='px-2 py-1 field1'>create_thread</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether a thread should be created on the event.<br>
                            <span class='px-2 py-1 field1'>delete_thread</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether the attached thread should be deleted if the event gets deleted.<br>
                            <span class='px-2 py-1 field1'>voice_channel</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The voicechannel used for this event.<br>
                            <span class='px-2 py-1 field1'>temp_voicechannel</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The name for a voicechannel that will be temporarily created.<br>
                            <span class='px-2 py-1 field1'>color</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The embed color for this event.<br>
                            <span class='px-2 py-1 field1'>response</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - Enter text that will be sent to the member when they sign up.<br>
                            <span class='px-2 py-1 field1'>forum_tags</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The names of the forum tags that will be applied to the post. Separate multiple names by comma.<br>
                            <span class='px-2 py-1 field1'>allowed_roles</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The role names that are allowed to sign up. Separate multiple names by comma.<br>
                            <span class='px-2 py-1 field1'>banned_roles</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - the role names that are banned from signing up. Separate multiple names by comma.<br>
                            <span class='px-2 py-1 field1'>opt_out</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The id of an existing event to copy the sign-ups from upon event creation.<br>
                            <span class='px-2 py-1 field1'>mentions</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The role/member names to mention upon event creation. Separate multiple names by comma.<br>
                            <span class='px-2 py-1 field1'>image</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The URL of an image that will be displayed at the bottom of the event embed.<br>
                            <span class='px-2 py-1 field1'>thumbnail</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The URL to an image which will be displayed as a thumbnail on the event embed.<br>
                            <span class='px-2 py-1 field1'>use_nicknames</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - whether the members server nickname will be used or the global name.<br>

                          </div>
                    <span class='px-2 py-1 field1'>classes</span><span class='px-2 py-1 field2'><button @click="classes = !classes" label>
                            <span style="color: #adb1b6;">&lt;array of objects&gt;</span><v-icon v-if="!classes" small color="#adb1b6">mdi-chevron-down</v-icon><v-icon v-else small color="#adb1b6">mdi-chevron-up</v-icon>
                          </button></span> - The classes that are applied to this event.<br>
                          <div style="border-left: 2px solid grey; line-height: 30px;" class="ml-5 px-3 my-2 mb-5" v-if="classes">
                            <span class='px-2 py-1 field1'>name</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The name of this class.<br>
                            <span class='px-2 py-1 field1'>limit</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The maximum allowed sign-ups for this class.<br>
                            <span class='px-2 py-1 field1'>emoteId</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id of this class.<br>
                            <span class='px-2 py-1 field1'>type</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The type (primary/default) of this class.<br>
                            <span class='px-2 py-1 field1'>specs</span><span class='px-2 py-1 field2'><button @click="specs = !specs" label>
                            <span style="color: #adb1b6;">&lt;array of objects&gt;</span><v-icon v-if="!specs" small color="#adb1b6">mdi-chevron-down</v-icon><v-icon v-else small color="#adb1b6">mdi-chevron-up</v-icon>
                          </button></span> - The classes that are applied to this event.<br>
                          <div style="border-left: 2px solid grey; line-height: 30px;" class="ml-5 px-3 my-2 mb-5" v-if="specs">
                            <span class='px-2 py-1 field1'>name</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The name of this spec.<br>
                            <span class='px-2 py-1 field1'>emoteId</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id of this spec.<br>
                            <span class='px-2 py-1 field1'>roleName</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The name of the role that this spec belongs to.<br>
                            <span class='px-2 py-1 field1'>roleEmoteId</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id of the role that this spec belongs to.<br>
                          </div>
                          </div>
                    <span class='px-2 py-1 field1'>roles</span><span class='px-2 py-1 field2'><button @click="roles = !roles" label>
                            <span style="color: #adb1b6;">&lt;array of objects&gt;</span><v-icon v-if="!roles" small color="#adb1b6">mdi-chevron-down</v-icon><v-icon v-else small color="#adb1b6">mdi-chevron-up</v-icon>
                          </button></span> - The roles that are applied to this event.<br>
                          <div style="border-left: 2px solid grey; line-height: 30px;" class="ml-5 px-3 my-2 mb-5" v-if="roles">
                            <span class='px-2 py-1 field1'>name</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The name of this role.<br>
                            <span class='px-2 py-1 field1'>limit</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The maximum allowed sign-ups for this role.<br>
                            <span class='px-2 py-1 field1'>emoteId</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id of this role.<br>
                          </div>
                    <span class='px-2 py-1 field1'>signUps</span><span class='px-2 py-1 field2'><button @click="signUps = !signUps" label>
                            <span style="color: #adb1b6;">&lt;array of objects&gt;</span><v-icon v-if="!signUps" small color="#adb1b6">mdi-chevron-down</v-icon><v-icon v-else small color="#adb1b6">mdi-chevron-up</v-icon>
                          </button></span> - The current sign-ups on this event.<br>
                          <div style="border-left: 2px solid grey; line-height: 30px;" class="ml-5 px-3 my-2 mb-5" v-if="signUps">
                            <span class='px-2 py-1 field1'>name</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The name of the user.<br>
                            <span class='px-2 py-1 field1'>id</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The Discord id of the user. <br>
                            <span class='px-2 py-1 field1'>className</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The class name of the sign-up.<br>
                            <span class='px-2 py-1 field1'>classEmoteId</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The class emote id of the sign-up.<br>
                            <span class='px-2 py-1 field1'>specName</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - the spec name of the sign-up.<br>
                            <span class='px-2 py-1 field1'>specEmoteId</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The spec emote id of the sign-up.<br>
                            <span class='px-2 py-1 field1'>roleName</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The role name of the sign-up.<br>
                            <span class='px-2 py-1 field1'>roleEmoteId</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The role emote id of the sign-up.<br>
                            <span class='px-2 py-1 field1'>status</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The status (primary/queued) of the sign-up.<br>
                            <span class='px-2 py-1 field1'>entryTime</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The unix timestamp of the registration time.<br>
                            <span class='px-2 py-1 field1'>position</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The order number of this sign-up.<br>
                          </div>
                    <span class='px-2 py-1 field1'>lastUpdated</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The unix timestamp of when this event was updated last.<br>
                    <span class='px-2 py-1 field1'>softresId</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The softres id attached to this event.<br>
                    <span class='px-2 py-1 field1'>color</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The current embed color in RGB format.<br>
                          </div>

                  </v-expansion-panel-content>
              </v-expansion-panel>
           
                  </v-expansion-panel-content>
              </v-expansion-panel>



<v-expansion-panel active-class="panel" class="my-1">
                  <v-expansion-panel-header style="font-size: 15px;" color="#18191c">
                    <v-row>
                      <v-col cols="auto" class="mb-0" style="min-width: 90px; text-align: center; justify-content: center; padding-top: 35px;">
                        <div style="font-weight: 800; font-size: 20px; color: #339933; margin: auto; text-align: center;">PATCH</div>
                      </v-col>
                      <v-col>
                    <v-col cols="12" class="mb-n4">
                      <span style="word-break: break-word;">https://raid-helper.dev/api/v2/events/<span style="color: purple;">EVENTID</span>/signups/<span style="color: purple;">SIGNUPID</span></span>
                    </v-col>
                    <v-col cols="12" class="mb-0">
                    <span style="color:gray; font-size: 15px;">Edit a sign-up.</span>
                    </v-col>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content style="background-color: #373a3e;" class="pt-5">
                      <v-row justify="center">
                  <v-btn style="text-transform: none;" elevation="3" depressed x-small color="purple darken-2" class="pa-2 mr-3 mb-8 mt-5" @click="copyText('https://raid-helper.dev/api/v2/events/EVENTID/signups/SIGNUPID')">Copy URL</v-btn>
                  <v-btn style="text-transform: none;" elevation="3" depressed x-small color="purple darken-2" class="pa-2 mr-3 mb-8 mt-5" @click="copyText(curl6)">Copy cURL</v-btn>
                      </v-row>
                   This request is used to edit a sign-up. The event and sign-up are defined in the url. The sign-up can be specified by the id, position number or name of the user. All other options are defined in the JSON body.
                   <h1 class="mt-10 mb-7">Request Structure</h1>
                   <v-expansion-panel class="my-1">
                  <v-expansion-panel-header style="font-size: 18px;" color="#18191c">
                    <v-row>
                      <div><span class='px-2 py-1 field2'>Headers</span> <span style="color: grey; font-size: 15px;" class="mx-2"></span></div><br>
                    </v-row>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content style="background-color: #1c1d21a6; line-height: 30px;" class="pt-5">
                    <span class='px-2 py-1 pill1'>Authorization</span><span class='px-2 py-1 pill2'>&lt;apikey&gt;</span>
                          <v-tooltip top max-width="600" transition="v-scale-transition" color="#0f0f0f">
                          <template v-slot:activator="{ on }">
                            <span v-on="on" class='ml-1 px-1 py-1 pill3'><v-icon style="margin-bottom: 2px;" x-small color="yellow">mdi-asterisk</v-icon></span>
                          </template>
                          <span style="color: yellow;">Required Field</span>
                        </v-tooltip> - Your servers api key which you can get and refresh via /apikey.                         
                      <br>
                      <span class='px-2 py-1 pill1'>Content-Type</span><span class='px-2 py-1 pill2'>application/json; charset=utf-8</span>
                            <v-tooltip top max-width="600" transition="v-scale-transition" color="#0f0f0f">
                          <template v-slot:activator="{ on }">
                            <span v-on="on" class='ml-1 px-1 py-1 pill3'><v-icon style="margin-bottom: 2px;" x-small color="yellow">mdi-asterisk</v-icon></span>
                          </template>
                          <span style="color: yellow;">Required Field</span>
                        </v-tooltip> - Defines the content type.                         
                          <br>
                  </v-expansion-panel-content>
              </v-expansion-panel>
                   <v-expansion-panel class="my-1">
                  <v-expansion-panel-header style="font-size: 18px;" color="#18191c">
                    <v-row>
                      <div><span class='px-2 py-1 field2'>Body</span> <span style="color: grey; font-size: 15px;" class="mx-2"></span></div><br>
                    </v-row>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content style="background-color: #1c1d21a6; line-height: 30px;" class="pt-5">
                   <span class='px-2 py-1 field1'>className</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The class name this sign-up will be assigned to.<br>
                   <span class='px-2 py-1 field1'>specName</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The spec name this sign-up will be assigned to.<br>
                    <span class='px-2 py-1 field1'>name</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The name for this sign-up, defaults to members name.<br>
                    <span class='px-2 py-1 field1'>position</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The position number for this sign-up, defaults to highest.<br>
                    <span class='px-2 py-1 field1'>notify</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether the user will be notified, defaults to false.<br>
                    <span class='px-2 py-1 field1'>reason</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - A reason that will be shown on the notification/logs.<br>
                  </v-expansion-panel-content>
              </v-expansion-panel>
                   <h1 class="mt-10 mb-7">Response Structure</h1>

                   <v-expansion-panel class="my-1">
                  <v-expansion-panel-header style="font-size: 18px;" color="#18191c">
                    <v-row>
                      <div><span class='px-2 py-1 field2'>Body</span></div><br>
                    </v-row>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content style="background-color: #1c1d21a6; line-height: 30px;" class="pt-5">
                    <span class='px-2 py-1 field1'>status</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - Indicates the success or failure of the request.<br>
                    <span class='px-2 py-1 field1'>event</span><span class='px-2 py-1 field2'><button @click="event = !event" label>
                            <span style="color: #adb1b6;">&lt;object&gt;</span><v-icon v-if="!event" small color="#adb1b6">mdi-chevron-down</v-icon><v-icon v-else small color="#adb1b6">mdi-chevron-up</v-icon>
                          </button></span> - The event object.<br>
                          <div style="border-left: 2px solid grey; line-height: 30px;" class="ml-5 px-3 my-2 mb-5" v-if="event">
                            <span class='px-2 py-1 field1'>id</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The message id of this event.<br>
                    <span class='px-2 py-1 field1'>serverId</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The server id of this event.<br>
                    <span class='px-2 py-1 field1'>leaderId</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The user id of this events leader.<br>
                    <span class='px-2 py-1 field1'>leaderName</span><span class='px-2 py-1 field2'>&lt;string&gt;</span>- The name of events leader.<br>
                    <span class='px-2 py-1 field1'>channelId</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - This events channel id.<br>
                    <span class='px-2 py-1 field1'>channelName</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - This events channel name.<br>
                    <span class='px-2 py-1 field1'>channelType</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The type of this events channel.<br>
                    <span class='px-2 py-1 field1'>templateId</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The id of this events template.<br>
                    <span class='px-2 py-1 field1'>templateEmoteId</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id of the emote used to represent the template of this event.<br>
                    <span class='px-2 py-1 field1'>title</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The event title.<br>
                    <span class='px-2 py-1 field1'>description</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The event description.<br>
                    <span class='px-2 py-1 field1'>startTime</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The unix timestamp of when this event will start.<br>
                    <span class='px-2 py-1 field1'>endTime</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The unix timestamp of when this event will end.<br>
                    <span class='px-2 py-1 field1'>closingTime</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The unix timestamp of when this event will close and deny further sign-ups.<br>
                    <span class='px-2 py-1 field1'>date</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The raw date string of when this event will start.<br>
                    <span class='px-2 py-1 field1'>time</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The raw time string of when this event will start.<br>
                    <span class='px-2 py-1 field1'>advancedSettings</span><span class='px-2 py-1 field2'><button @click="advancedSettings = !advancedSettings" label>
                            <span style="color: #adb1b6;">&lt;object&gt;</span><v-icon v-if="!advancedSettings" small color="#adb1b6">mdi-chevron-down</v-icon><v-icon v-else small color="#adb1b6">mdi-chevron-up</v-icon>
                          </button></span> - The advanced settings for this event.<br>
                          <div style="border-left: 2px solid grey; line-height: 30px;" class="ml-5 px-3 my-2 mb-5" v-if="advancedSettings">
                            <span class='px-2 py-1 field1'>duration</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The duration of the event in minutes.<br>
                            <span class='px-2 py-1 field1'>deadline</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The deadline in hours before the event. <br>
                            <span class='px-2 py-1 field1'>limit</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The maximum amount of active sign-ups.<br>
                            <span class='px-2 py-1 field1'>lock_at_limit</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether the event will lock when the limit is reached.<br>
                            <span class='px-2 py-1 field1'>limit_per_user</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The maximum amount of sign-ups per member.<br>
                            <span class='px-2 py-1 field1'>lower_limit</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The minimum amount of sign-ups required for the event to happen.<br>
                            <span class='px-2 py-1 field1'>allow_duplicate</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether a member can sign up multiple times to the same class.<br>
                            <span class='px-2 py-1 field1'>horizontal_mode</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether sign-ups will be displayed horizontally instead of vertically.<br>
                            <span class='px-2 py-1 field1'>bench_overflow</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether sign-ups past limits will be benched or simply denied.<br>
                            <span class='px-2 py-1 field1'>queue_bench</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - Changes the bench behaviour to a queue.<br>
                            <span class='px-2 py-1 field1'>vacuum</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - Clears the last 10 non-event messages in the channel upon event creation.<br>
                            <span class='px-2 py-1 field1'>force_reminders</span><span class='px-2 py-1 field2'>&lt;boolean/number&gt;</span> - Force personal reminders. Time in minutes before the event.<br>
                            <span class='px-2 py-1 field1'>pin_message</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether this event should be pinned upon creation.<br>
                            <span class='px-2 py-1 field1'>deletion</span><span class='px-2 py-1 field2'>&lt;boolean/number&gt;</span> - The amount of hours this event will be deleted after it concluded.<br>
                            <span class='px-2 py-1 field1'>mention_mode</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - Mentions the members instead of displaying the plain name.<br>
                            <span class='px-2 py-1 field1'>preserve_order</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - Determines the behaviour of the order numbers when members change their sign-up.<br>
                            <span class='px-2 py-1 field1'>apply_unregister</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether the unregister role will be applied.<br>
                            <span class='px-2 py-1 field1'>apply_specreset</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - whether the reaction/button to reset the saved spec should be applied to the event.<br>
                            <span class='px-2 py-1 field1'>spec_saving</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether the bot should remember a members spec choice and apply it automatically.<br>
                            <span class='px-2 py-1 field1'>font_style</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The font style for the event title. Only applies if the title is shorter than 19 characters.<br>
                            <span class='px-2 py-1 field1'>alt_names</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether members will be allowed to enter a custom name when signing up.<br>
                            <span class='px-2 py-1 field1'>defaults_pre_req</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether a primary role is required to sign up with a default role (bench, late, tentative).<br>
                            <span class='px-2 py-1 field1'>show_on_overview</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether the event will be displayed on the /overview.<br>
                            <span class='px-2 py-1 field1'>mention_leader</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether the leaders name will be displayed as a mention.<br>
                            <span class='px-2 py-1 field1'>attendance</span><span class='px-2 py-1 field2'>&lt;boolean/string&gt;</span> - whether this event will count towards attendance. Enter a string to set an attendance tag.<br>
                            <span class='px-2 py-1 field1'>show_title</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether to show the title of this event.<br>
                            <span class='px-2 py-1 field1'>show_info</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether the info row will be shown (contains date, time and number of sign-ups).<br>
                            <span class='px-2 py-1 field1'>show_leader</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether the event leader will be shown.<br>
                            <span class='px-2 py-1 field1'>show_counter</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether the sign-ups counter will be shown.<br>
                            <span class='px-2 py-1 field1'>show_roles</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether to show the role counters above the sign-up content.<br>
                            <span class='px-2 py-1 field1'>show_content</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether the sign-ups will be displayed.<br>
                            <span class='px-2 py-1 field1'>show_classes</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether the class fields will always be shown.<br>
                            <span class='px-2 py-1 field1'>show_emotes</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether to show the spec emotes in front of sign-ups.<br>
                            <span class='px-2 py-1 field1'>show_numbering</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether to show the order number in front of sign-ups.<br>
                            <span class='px-2 py-1 field1'>show_allowed</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether to show the allowed roles in the footer if any are set.<br>
                            <span class='px-2 py-1 field1'>show_footer</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether to show the event footer.<br>
                            <span class='px-2 py-1 field1'>info_variant</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - whether the info field will be displayed in a short or long format.<br>
                            <span class='px-2 py-1 field1'>date_variant</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - whether the date & time on the event will be shown in the users local or zoned time.<br>
                            <span class='px-2 py-1 field1'>show_countdown</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether to show a countdown to the event start.<br>
                            <span class='px-2 py-1 field1'>disable_archiving</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - If archiving is enabled on your server you can exempt a specific event with this setting.<br>
                            <span class='px-2 py-1 field1'>bold_all</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - Set to false to not count consecutive sign-ups by a user to be counted towards limits.<br>
                            <span class='px-2 py-1 field1'>bench_emote</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id that will be used for the bench role. Set to "remove" to disable the role.<br>
                            <span class='px-2 py-1 field1'>late_emote</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id that will be used for the late role. Set to "remove" to disable the role.<br>
                            <span class='px-2 py-1 field1'>tentative_emote</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id that will be used for the tentative role. Set to "remove" to disable the role.<br>
                            <span class='px-2 py-1 field1'>absence_emote</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id that will be used for the absence role. Set to "remove" to disable the role.<br>
                            <span class='px-2 py-1 field1'>leader_emote</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id that will be used for the leader icon.<br>
                            <span class='px-2 py-1 field1'>signups1_emote</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id that will be used for the signups icon.<br>
                            <span class='px-2 py-1 field1'>signups2_emote</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id that will be used for the signups icon.<br>
                            <span class='px-2 py-1 field1'>date1_emote</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id that will be used for the date icon.<br>
                            <span class='px-2 py-1 field1'>date2_emote</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id that will be used for the date icon.<br>
                            <span class='px-2 py-1 field1'>time1_emote</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id that will be used for the time icon.<br>
                            <span class='px-2 py-1 field1'>time2_emote</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id that will be used for the time icon.<br>
                            <span class='px-2 py-1 field1'>countdown1_emote</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id that will be used for the countdown icon.<br>
                            <span class='px-2 py-1 field1'>countdown2_emote</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id that will be used for the countdown icon.<br>
                            <span class='px-2 py-1 field1'>event_type</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - whether this event will use interactions or reactions.<br>
                            <span class='px-2 py-1 field1'>reminder</span><span class='px-2 py-1 field2'>&lt;number/boolean&gt;</span> - Set the amount of minutes before the event a reminder will be sent.<br>
                            <span class='px-2 py-1 field1'>create_discordevent</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether a discord integrated event should be created (requires premium).<br>
                            <span class='px-2 py-1 field1'>create_thread</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether a thread should be created on the event.<br>
                            <span class='px-2 py-1 field1'>delete_thread</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether the attached thread should be deleted if the event gets deleted.<br>
                            <span class='px-2 py-1 field1'>voice_channel</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The voicechannel used for this event.<br>
                            <span class='px-2 py-1 field1'>temp_voicechannel</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The name for a voicechannel that will be temporarily created.<br>
                            <span class='px-2 py-1 field1'>color</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The embed color for this event.<br>
                            <span class='px-2 py-1 field1'>response</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - Enter text that will be sent to the member when they sign up.<br>
                            <span class='px-2 py-1 field1'>temp_role</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The name of a discord role that will be assigned to the member upon signing up.<br>
                            <span class='px-2 py-1 field1'>forum_tags</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The names of the forum tags that will be applied to the post. Separate multiple names by comma.<br>
                            <span class='px-2 py-1 field1'>allowed_roles</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The role names that are allowed to sign up. Separate multiple names by comma.<br>
                            <span class='px-2 py-1 field1'>banned_roles</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - the role names that are banned from signing up. Separate multiple names by comma.<br>
                            <span class='px-2 py-1 field1'>opt_out</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The id of an existing event to copy the sign-ups from upon event creation.<br>
                            <span class='px-2 py-1 field1'>mentions</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The role/member names to mention upon event creation. Separate multiple names by comma.<br>
                            <span class='px-2 py-1 field1'>image</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The URL of an image that will be displayed at the bottom of the event embed.<br>
                            <span class='px-2 py-1 field1'>thumbnail</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The URL to an image which will be displayed as a thumbnail on the event embed.<br>
                            <span class='px-2 py-1 field1'>use_nicknames</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - whether the members server nickname will be used or the global name.<br>

                          </div>
                    <span class='px-2 py-1 field1'>classes</span><span class='px-2 py-1 field2'><button @click="classes = !classes" label>
                            <span style="color: #adb1b6;">&lt;array of objects&gt;</span><v-icon v-if="!classes" small color="#adb1b6">mdi-chevron-down</v-icon><v-icon v-else small color="#adb1b6">mdi-chevron-up</v-icon>
                          </button></span> - The classes that are applied to this event.<br>
                          <div style="border-left: 2px solid grey; line-height: 30px;" class="ml-5 px-3 my-2 mb-5" v-if="classes">
                            <span class='px-2 py-1 field1'>name</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The name of this class.<br>
                            <span class='px-2 py-1 field1'>limit</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The maximum allowed sign-ups for this class.<br>
                            <span class='px-2 py-1 field1'>emoteId</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id of this class.<br>
                            <span class='px-2 py-1 field1'>type</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The type (primary/default) of this class.<br>
                            <span class='px-2 py-1 field1'>specs</span><span class='px-2 py-1 field2'><button @click="specs = !specs" label>
                            <span style="color: #adb1b6;">&lt;array of objects&gt;</span><v-icon v-if="!specs" small color="#adb1b6">mdi-chevron-down</v-icon><v-icon v-else small color="#adb1b6">mdi-chevron-up</v-icon>
                          </button></span> - The classes that are applied to this event.<br>
                          <div style="border-left: 2px solid grey; line-height: 30px;" class="ml-5 px-3 my-2 mb-5" v-if="specs">
                            <span class='px-2 py-1 field1'>name</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The name of this spec.<br>
                            <span class='px-2 py-1 field1'>emoteId</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id of this spec.<br>
                            <span class='px-2 py-1 field1'>roleName</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The name of the role that this spec belongs to.<br>
                            <span class='px-2 py-1 field1'>roleEmoteId</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id of the role that this spec belongs to.<br>
                          </div>
                          </div>
                    <span class='px-2 py-1 field1'>roles</span><span class='px-2 py-1 field2'><button @click="roles = !roles" label>
                            <span style="color: #adb1b6;">&lt;array of objects&gt;</span><v-icon v-if="!roles" small color="#adb1b6">mdi-chevron-down</v-icon><v-icon v-else small color="#adb1b6">mdi-chevron-up</v-icon>
                          </button></span> - The roles that are applied to this event.<br>
                          <div style="border-left: 2px solid grey; line-height: 30px;" class="ml-5 px-3 my-2 mb-5" v-if="roles">
                            <span class='px-2 py-1 field1'>name</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The name of this role.<br>
                            <span class='px-2 py-1 field1'>limit</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The maximum allowed sign-ups for this role.<br>
                            <span class='px-2 py-1 field1'>emoteId</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id of this role.<br>
                          </div>
                    <span class='px-2 py-1 field1'>signUps</span><span class='px-2 py-1 field2'><button @click="signUps = !signUps" label>
                            <span style="color: #adb1b6;">&lt;array of objects&gt;</span><v-icon v-if="!signUps" small color="#adb1b6">mdi-chevron-down</v-icon><v-icon v-else small color="#adb1b6">mdi-chevron-up</v-icon>
                          </button></span> - The current sign-ups on this event.<br>
                          <div style="border-left: 2px solid grey; line-height: 30px;" class="ml-5 px-3 my-2 mb-5" v-if="signUps">
                            <span class='px-2 py-1 field1'>name</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The name of the user.<br>
                            <span class='px-2 py-1 field1'>id</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The Discord id of the user. <br>
                            <span class='px-2 py-1 field1'>className</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The class name of the sign-up.<br>
                            <span class='px-2 py-1 field1'>classEmoteId</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The class emote id of the sign-up.<br>
                            <span class='px-2 py-1 field1'>specName</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - the spec name of the sign-up.<br>
                            <span class='px-2 py-1 field1'>specEmoteId</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The spec emote id of the sign-up.<br>
                            <span class='px-2 py-1 field1'>roleName</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The role name of the sign-up.<br>
                            <span class='px-2 py-1 field1'>roleEmoteId</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The role emote id of the sign-up.<br>
                            <span class='px-2 py-1 field1'>status</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The status (primary/queued) of the sign-up.<br>
                            <span class='px-2 py-1 field1'>entryTime</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The unix timestamp of the registration time.<br>
                            <span class='px-2 py-1 field1'>position</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The order number of this sign-up.<br>
                          </div>
                    <span class='px-2 py-1 field1'>lastUpdated</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The unix timestamp of when this event was updated last.<br>
                    <span class='px-2 py-1 field1'>softresId</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The softres id attached to this event.<br>
                    <span class='px-2 py-1 field1'>color</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The current embed color in RGB format.<br>
                          </div>

                  </v-expansion-panel-content>
              </v-expansion-panel>
           
                  </v-expansion-panel-content>
              </v-expansion-panel>










<v-expansion-panel active-class="panel" class="my-1">
                  <v-expansion-panel-header style="font-size: 15px;" color="#18191c">
                    <v-row>
                      <v-col cols="auto" class="mb-0" style="min-width: 90px; text-align: center; justify-content: center; padding-top: 35px;">
                        <div style="font-weight: 800; font-size: 20px; color: red; margin: auto; text-align: center;">DELETE</div>
                      </v-col>
                      <v-col>
                    <v-col cols="12" class="mb-n4">
                      <span style="word-break: break-word;">https://raid-helper.dev/api/v2/events/<span style="color: purple;">EVENTID</span>/signups/<span style="color: purple;">SIGNUPID</span></span>
                    </v-col>
                    <v-col cols="12" class="mb-0">
                    <span style="color:gray; font-size: 15px;">Delete a sign-up.</span>
                    </v-col>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content style="background-color: #373a3e;" class="pt-5">
                      <v-row justify="center">
                  <v-btn style="text-transform: none;" elevation="3" depressed x-small color="purple darken-2" class="pa-2 mr-3 mb-8 mt-5" @click="copyText('https://raid-helper.dev/api/v2/events/EVENTID/signups/SIGNUPID')">Copy URL</v-btn>
                  <v-btn style="text-transform: none;" elevation="3" depressed x-small color="purple darken-2" class="pa-2 mr-3 mb-8 mt-5" @click="copyText(curl6)">Copy cURL</v-btn>
                      </v-row>
                   This request is used to delete a sign-up. The event and sign-up are defined in the url. The sign-up can be specified by the id, position number or name of the user.
                   <h1 class="mt-10 mb-7">Request Structure</h1>
                   <v-expansion-panel class="my-1">
                  <v-expansion-panel-header style="font-size: 18px;" color="#18191c">
                    <v-row>
                      <div><span class='px-2 py-1 field2'>Headers</span> <span style="color: grey; font-size: 15px;" class="mx-2"></span></div><br>
                    </v-row>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content style="background-color: #1c1d21a6; line-height: 30px;" class="pt-5">
                    <span class='px-2 py-1 pill1'>Authorization</span><span class='px-2 py-1 pill2'>&lt;apikey&gt;</span>
                          <v-tooltip top max-width="600" transition="v-scale-transition" color="#0f0f0f">
                          <template v-slot:activator="{ on }">
                            <span v-on="on" class='ml-1 px-1 py-1 pill3'><v-icon style="margin-bottom: 2px;" x-small color="yellow">mdi-asterisk</v-icon></span>
                          </template>
                          <span style="color: yellow;">Required Field</span>
                        </v-tooltip> - Your servers api key which you can get and refresh via /apikey.                         
                      <br>
                  </v-expansion-panel-content>
              </v-expansion-panel>
               <h1 class="mt-10 mb-7">Response Structure</h1>

                   <v-expansion-panel class="my-1">
                  <v-expansion-panel-header style="font-size: 18px;" color="#18191c">
                    <v-row>
                      <div><span class='px-2 py-1 field2'>Body</span></div><br>
                    </v-row>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content style="background-color: #1c1d21a6; line-height: 30px;" class="pt-5">
                    <span class='px-2 py-1 field1'>status</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - Indicates the success or failure of the request.<br>
                    <span class='px-2 py-1 field1'>event</span><span class='px-2 py-1 field2'><button @click="event = !event" label>
                            <span style="color: #adb1b6;">&lt;object&gt;</span><v-icon v-if="!event" small color="#adb1b6">mdi-chevron-down</v-icon><v-icon v-else small color="#adb1b6">mdi-chevron-up</v-icon>
                          </button></span> - The event object.<br>
                          <div style="border-left: 2px solid grey; line-height: 30px;" class="ml-5 px-3 my-2 mb-5" v-if="event">
                            <span class='px-2 py-1 field1'>id</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The message id of this event.<br>
                    <span class='px-2 py-1 field1'>serverId</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The server id of this event.<br>
                    <span class='px-2 py-1 field1'>leaderId</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The user id of this events leader.<br>
                    <span class='px-2 py-1 field1'>leaderName</span><span class='px-2 py-1 field2'>&lt;string&gt;</span>- The name of events leader.<br>
                    <span class='px-2 py-1 field1'>channelId</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - This events channel id.<br>
                    <span class='px-2 py-1 field1'>channelName</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - This events channel name.<br>
                    <span class='px-2 py-1 field1'>channelType</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The type of this events channel.<br>
                    <span class='px-2 py-1 field1'>templateId</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The id of this events template.<br>
                    <span class='px-2 py-1 field1'>templateEmoteId</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id of the emote used to represent the template of this event.<br>
                    <span class='px-2 py-1 field1'>title</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The event title.<br>
                    <span class='px-2 py-1 field1'>description</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The event description.<br>
                    <span class='px-2 py-1 field1'>startTime</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The unix timestamp of when this event will start.<br>
                    <span class='px-2 py-1 field1'>endTime</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The unix timestamp of when this event will end.<br>
                    <span class='px-2 py-1 field1'>closingTime</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The unix timestamp of when this event will close and deny further sign-ups.<br>
                    <span class='px-2 py-1 field1'>date</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The raw date string of when this event will start.<br>
                    <span class='px-2 py-1 field1'>time</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The raw time string of when this event will start.<br>
                    <span class='px-2 py-1 field1'>advancedSettings</span><span class='px-2 py-1 field2'><button @click="advancedSettings = !advancedSettings" label>
                            <span style="color: #adb1b6;">&lt;object&gt;</span><v-icon v-if="!advancedSettings" small color="#adb1b6">mdi-chevron-down</v-icon><v-icon v-else small color="#adb1b6">mdi-chevron-up</v-icon>
                          </button></span> - The advanced settings for this event.<br>
                          <div style="border-left: 2px solid grey; line-height: 30px;" class="ml-5 px-3 my-2 mb-5" v-if="advancedSettings">
                            <span class='px-2 py-1 field1'>duration</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The duration of the event in minutes.<br>
                            <span class='px-2 py-1 field1'>deadline</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The deadline in hours before the event. <br>
                            <span class='px-2 py-1 field1'>limit</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The maximum amount of active sign-ups.<br>
                            <span class='px-2 py-1 field1'>lock_at_limit</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether the event will lock when the limit is reached.<br>
                            <span class='px-2 py-1 field1'>limit_per_user</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The maximum amount of sign-ups per member.<br>
                            <span class='px-2 py-1 field1'>lower_limit</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The minimum amount of sign-ups required for the event to happen.<br>
                            <span class='px-2 py-1 field1'>allow_duplicate</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether a member can sign up multiple times to the same class.<br>
                            <span class='px-2 py-1 field1'>horizontal_mode</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether sign-ups will be displayed horizontally instead of vertically.<br>
                            <span class='px-2 py-1 field1'>bench_overflow</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether sign-ups past limits will be benched or simply denied.<br>
                            <span class='px-2 py-1 field1'>queue_bench</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - Changes the bench behaviour to a queue.<br>
                            <span class='px-2 py-1 field1'>vacuum</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - Clears the last 10 non-event messages in the channel upon event creation.<br>
                            <span class='px-2 py-1 field1'>force_reminders</span><span class='px-2 py-1 field2'>&lt;boolean/number&gt;</span> - Force personal reminders. Time in minutes before the event.<br>
                            <span class='px-2 py-1 field1'>pin_message</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether this event should be pinned upon creation.<br>
                            <span class='px-2 py-1 field1'>deletion</span><span class='px-2 py-1 field2'>&lt;boolean/number&gt;</span> - The amount of hours this event will be deleted after it concluded.<br>
                            <span class='px-2 py-1 field1'>mention_mode</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - Mentions the members instead of displaying the plain name.<br>
                            <span class='px-2 py-1 field1'>preserve_order</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - Determines the behaviour of the order numbers when members change their sign-up.<br>
                            <span class='px-2 py-1 field1'>apply_unregister</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether the unregister role will be applied.<br>
                            <span class='px-2 py-1 field1'>apply_specreset</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - whether the reaction/button to reset the saved spec should be applied to the event.<br>
                            <span class='px-2 py-1 field1'>spec_saving</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether the bot should remember a members spec choice and apply it automatically.<br>
                            <span class='px-2 py-1 field1'>font_style</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The font style for the event title. Only applies if the title is shorter than 19 characters.<br>
                            <span class='px-2 py-1 field1'>alt_names</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether members will be allowed to enter a custom name when signing up.<br>
                            <span class='px-2 py-1 field1'>defaults_pre_req</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether a primary role is required to sign up with a default role (bench, late, tentative).<br>
                            <span class='px-2 py-1 field1'>show_on_overview</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether the event will be displayed on the /overview.<br>
                            <span class='px-2 py-1 field1'>mention_leader</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether the leaders name will be displayed as a mention.<br>
                            <span class='px-2 py-1 field1'>attendance</span><span class='px-2 py-1 field2'>&lt;boolean/string&gt;</span> - whether this event will count towards attendance. Enter a string to set an attendance tag.<br>
                            <span class='px-2 py-1 field1'>show_title</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether to show the title of this event.<br>
                            <span class='px-2 py-1 field1'>show_info</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether the info row will be shown (contains date, time and number of sign-ups).<br>
                            <span class='px-2 py-1 field1'>show_leader</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether the event leader will be shown.<br>
                            <span class='px-2 py-1 field1'>show_counter</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether the sign-ups counter will be shown.<br>
                            <span class='px-2 py-1 field1'>show_roles</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether to show the role counters above the sign-up content.<br>
                            <span class='px-2 py-1 field1'>show_content</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether the sign-ups will be displayed.<br>
                            <span class='px-2 py-1 field1'>show_classes</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether the class fields will always be shown.<br>
                            <span class='px-2 py-1 field1'>show_emotes</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether to show the spec emotes in front of sign-ups.<br>
                            <span class='px-2 py-1 field1'>show_numbering</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether to show the order number in front of sign-ups.<br>
                            <span class='px-2 py-1 field1'>show_allowed</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether to show the allowed roles in the footer if any are set.<br>
                            <span class='px-2 py-1 field1'>show_footer</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether to show the event footer.<br>
                            <span class='px-2 py-1 field1'>info_variant</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - whether the info field will be displayed in a short or long format.<br>
                            <span class='px-2 py-1 field1'>date_variant</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - whether the date & time on the event will be shown in the users local or zoned time.<br>
                            <span class='px-2 py-1 field1'>show_countdown</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether to show a countdown to the event start.<br>
                            <span class='px-2 py-1 field1'>disable_archiving</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - If archiving is enabled on your server you can exempt a specific event with this setting.<br>
                            <span class='px-2 py-1 field1'>bold_all</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - Set to false to not count consecutive sign-ups by a user to be counted towards limits.<br>
                            <span class='px-2 py-1 field1'>bench_emote</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id that will be used for the bench role. Set to "remove" to disable the role.<br>
                            <span class='px-2 py-1 field1'>late_emote</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id that will be used for the late role. Set to "remove" to disable the role.<br>
                            <span class='px-2 py-1 field1'>tentative_emote</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id that will be used for the tentative role. Set to "remove" to disable the role.<br>
                            <span class='px-2 py-1 field1'>absence_emote</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id that will be used for the absence role. Set to "remove" to disable the role.<br>
                            <span class='px-2 py-1 field1'>leader_emote</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id that will be used for the leader icon.<br>
                            <span class='px-2 py-1 field1'>signups1_emote</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id that will be used for the signups icon.<br>
                            <span class='px-2 py-1 field1'>signups2_emote</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id that will be used for the signups icon.<br>
                            <span class='px-2 py-1 field1'>date1_emote</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id that will be used for the date icon.<br>
                            <span class='px-2 py-1 field1'>date2_emote</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id that will be used for the date icon.<br>
                            <span class='px-2 py-1 field1'>time1_emote</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id that will be used for the time icon.<br>
                            <span class='px-2 py-1 field1'>time2_emote</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id that will be used for the time icon.<br>
                            <span class='px-2 py-1 field1'>countdown1_emote</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id that will be used for the countdown icon.<br>
                            <span class='px-2 py-1 field1'>countdown2_emote</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id that will be used for the countdown icon.<br>
                            <span class='px-2 py-1 field1'>event_type</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - whether this event will use interactions or reactions.<br>
                            <span class='px-2 py-1 field1'>reminder</span><span class='px-2 py-1 field2'>&lt;number/boolean&gt;</span> - Set the amount of minutes before the event a reminder will be sent.<br>
                            <span class='px-2 py-1 field1'>create_discordevent</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether a discord integrated event should be created (requires premium).<br>
                            <span class='px-2 py-1 field1'>create_thread</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether a thread should be created on the event.<br>
                            <span class='px-2 py-1 field1'>delete_thread</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether the attached thread should be deleted if the event gets deleted.<br>
                            <span class='px-2 py-1 field1'>voice_channel</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The voicechannel used for this event.<br>
                            <span class='px-2 py-1 field1'>temp_voicechannel</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The name for a voicechannel that will be temporarily created.<br>
                            <span class='px-2 py-1 field1'>color</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The embed color for this event.<br>
                            <span class='px-2 py-1 field1'>response</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - Enter text that will be sent to the member when they sign up.<br>
                            <span class='px-2 py-1 field1'>temp_role</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The name of a discord role that will be assigned to the member upon signing up.<br>
                            <span class='px-2 py-1 field1'>forum_tags</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The names of the forum tags that will be applied to the post. Separate multiple names by comma.<br>
                            <span class='px-2 py-1 field1'>allowed_roles</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The role names that are allowed to sign up. Separate multiple names by comma.<br>
                            <span class='px-2 py-1 field1'>banned_roles</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - the role names that are banned from signing up. Separate multiple names by comma.<br>
                            <span class='px-2 py-1 field1'>opt_out</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The id of an existing event to copy the sign-ups from upon event creation.<br>
                            <span class='px-2 py-1 field1'>mentions</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The role/member names to mention upon event creation. Separate multiple names by comma.<br>
                            <span class='px-2 py-1 field1'>image</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The URL of an image that will be displayed at the bottom of the event embed.<br>
                            <span class='px-2 py-1 field1'>thumbnail</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The URL to an image which will be displayed as a thumbnail on the event embed.<br>
                            <span class='px-2 py-1 field1'>use_nicknames</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - whether the members server nickname will be used or the global name.<br>

                          </div>
                    <span class='px-2 py-1 field1'>classes</span><span class='px-2 py-1 field2'><button @click="classes = !classes" label>
                            <span style="color: #adb1b6;">&lt;array of objects&gt;</span><v-icon v-if="!classes" small color="#adb1b6">mdi-chevron-down</v-icon><v-icon v-else small color="#adb1b6">mdi-chevron-up</v-icon>
                          </button></span> - The classes that are applied to this event.<br>
                          <div style="border-left: 2px solid grey; line-height: 30px;" class="ml-5 px-3 my-2 mb-5" v-if="classes">
                            <span class='px-2 py-1 field1'>name</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The name of this class.<br>
                            <span class='px-2 py-1 field1'>limit</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The maximum allowed sign-ups for this class.<br>
                            <span class='px-2 py-1 field1'>emoteId</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id of this class.<br>
                            <span class='px-2 py-1 field1'>type</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The type (primary/default) of this class.<br>
                            <span class='px-2 py-1 field1'>specs</span><span class='px-2 py-1 field2'><button @click="specs = !specs" label>
                            <span style="color: #adb1b6;">&lt;array of objects&gt;</span><v-icon v-if="!specs" small color="#adb1b6">mdi-chevron-down</v-icon><v-icon v-else small color="#adb1b6">mdi-chevron-up</v-icon>
                          </button></span> - The classes that are applied to this event.<br>
                          <div style="border-left: 2px solid grey; line-height: 30px;" class="ml-5 px-3 my-2 mb-5" v-if="specs">
                            <span class='px-2 py-1 field1'>name</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The name of this spec.<br>
                            <span class='px-2 py-1 field1'>emoteId</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id of this spec.<br>
                            <span class='px-2 py-1 field1'>roleName</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The name of the role that this spec belongs to.<br>
                            <span class='px-2 py-1 field1'>roleEmoteId</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id of the role that this spec belongs to.<br>
                          </div>
                          </div>
                    <span class='px-2 py-1 field1'>roles</span><span class='px-2 py-1 field2'><button @click="roles = !roles" label>
                            <span style="color: #adb1b6;">&lt;array of objects&gt;</span><v-icon v-if="!roles" small color="#adb1b6">mdi-chevron-down</v-icon><v-icon v-else small color="#adb1b6">mdi-chevron-up</v-icon>
                          </button></span> - The roles that are applied to this event.<br>
                          <div style="border-left: 2px solid grey; line-height: 30px;" class="ml-5 px-3 my-2 mb-5" v-if="roles">
                            <span class='px-2 py-1 field1'>name</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The name of this role.<br>
                            <span class='px-2 py-1 field1'>limit</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The maximum allowed sign-ups for this role.<br>
                            <span class='px-2 py-1 field1'>emoteId</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The emote id of this role.<br>
                          </div>
                    <span class='px-2 py-1 field1'>signUps</span><span class='px-2 py-1 field2'><button @click="signUps = !signUps" label>
                            <span style="color: #adb1b6;">&lt;array of objects&gt;</span><v-icon v-if="!signUps" small color="#adb1b6">mdi-chevron-down</v-icon><v-icon v-else small color="#adb1b6">mdi-chevron-up</v-icon>
                          </button></span> - The current sign-ups on this event.<br>
                          <div style="border-left: 2px solid grey; line-height: 30px;" class="ml-5 px-3 my-2 mb-5" v-if="signUps">
                            <span class='px-2 py-1 field1'>name</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The name of the user.<br>
                            <span class='px-2 py-1 field1'>id</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The Discord id of the user. <br>
                            <span class='px-2 py-1 field1'>className</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The class name of the sign-up.<br>
                            <span class='px-2 py-1 field1'>classEmoteId</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The class emote id of the sign-up.<br>
                            <span class='px-2 py-1 field1'>specName</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - the spec name of the sign-up.<br>
                            <span class='px-2 py-1 field1'>specEmoteId</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The spec emote id of the sign-up.<br>
                            <span class='px-2 py-1 field1'>roleName</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The role name of the sign-up.<br>
                            <span class='px-2 py-1 field1'>roleEmoteId</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The role emote id of the sign-up.<br>
                            <span class='px-2 py-1 field1'>status</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The status (primary/queued) of the sign-up.<br>
                            <span class='px-2 py-1 field1'>entryTime</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The unix timestamp of the registration time.<br>
                            <span class='px-2 py-1 field1'>position</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The order number of this sign-up.<br>
                          </div>
                    <span class='px-2 py-1 field1'>lastUpdated</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The unix timestamp of when this event was updated last.<br>
                    <span class='px-2 py-1 field1'>softresId</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The softres id attached to this event.<br>
                    <span class='px-2 py-1 field1'>color</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The current embed color in RGB format.<br>
                          </div>

                  </v-expansion-panel-content>
              </v-expansion-panel>
                  </v-expansion-panel-content>
              </v-expansion-panel>









              <v-expansion-panel active-class="panel" class="my-1">
                  <v-expansion-panel-header style="font-size: 15px;" color="#18191c" >
                    <v-row>
                      <v-col cols="auto" class="mb-0" style="min-width: 90px; text-align: center; justify-content: center; padding-top: 35px;">
                        <div style="font-weight: 800; font-size: 20px; color: yellow; margin: auto; text-align: center;">POST</div>
                      </v-col>
                      <v-col>
                    <v-col cols="12" class="mb-n4">
                      <span style="word-break: break-word;">https://raid-helper.dev/api/v2/servers/<span style="color: purple;">SERVERID</span>/channels/<span style="color: purple;">CHANNELID</span>/embed</span>
                    </v-col>
                    <v-col cols="12" class="mb-0">
                    <span style="color:gray; font-size: 15px;">Create a new embed message.</span>
                    </v-col>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content style="background-color: #373a3e;" class="pt-5">
                                                             <v-row justify="center">
                  <v-btn style="text-transform: none;" elevation="3" depressed x-small color="purple darken-2" class="pa-2 mr-3 mb-8 mt-5" @click="copyText('https://raid-helper.dev/api/v2/servers/SERVERID/channels/CHANNELID/embed')">Copy URL</v-btn>
                  <v-btn style="text-transform: none;" elevation="3" depressed x-small color="purple darken-2" class="pa-2 mr-3 mb-8 mt-5" @click="copyText(curl2)">Copy cURL</v-btn>
                      </v-row>	
                   This request is used to create an embed message. The server and channel are defined in the route. All other options are defined in the JSON body. Below you can see the layout of an embed message.


<div class="discord-embed">
  <div class="discord-embed-left-border" style="background-color:#0099ff;"></div>
  <div class="discord-embed-container">
    <div class="discord-embed-content">
      <div>
        <div class="discord-embed-author">
          <img class="discord-embed-author-icon" src="@/assets/imageplaceholder.png" alt="test" title="The author icon"><a href="https://raid-helper.dev/documentation/api">The embed author</a></div>
          <div class="discord-embed-title"><a href="https://raid-helper.dev/documentation/api">The embed title</a></div>
          <div class="discord-embed-description">The embed description here</div>
          <div class="discord-embed-fields"><div class="discord-embed-field">
            <div class="discord-embed-field-title">Regular field title</div>The field value</div>
            <div class="discord-embed-field">
            <div class="discord-embed-field-title">Regular field title</div>The field value</div>
            <div class="discord-embed-field"></div>
            
            
            <div class="discord-embed-field discord-embed-field-inline">
              <div class="discord-embed-field-title">Inline field title</div>The field value</div>
              <div class="discord-embed-field discord-embed-field-inline">
                <div class="discord-embed-field-title">Inline field title</div>The field value</div>
                <div class="discord-embed-field discord-embed-field-inline">
                  <div class="discord-embed-field-title">Inline field title</div>The field value</div></div><img class="discord-embed-image" src="@/assets/imageplaceholder.png" title="The embed image"></div>
                  <img class="discord-embed-thumbnail" src="@/assets/imageplaceholder.png" title="The thumbnail image"></div>
                  <div class="discord-embed-footer"><img class="discord-embed-footer-icon" src="@/assets/imageplaceholder.png" title="The footer icon"><span>The embed footer</span>
                  </div>
                </div>
              </div>


                   <h1 class="mt-10 mb-7">Request Structure</h1>
                   <v-expansion-panel class="my-1">
                  <v-expansion-panel-header style="font-size: 18px;" color="#18191c">
                    <v-row>
                      <div><span class='px-2 py-1 field2'>Headers</span> <span style="color: grey; font-size: 15px;" class="mx-2"></span></div><br>
                    </v-row>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content style="background-color: #1c1d21a6; line-height: 30px;" class="pt-5">
                    <span class='px-2 py-1 pill1'>Authorization</span><span class='px-2 py-1 pill2'>&lt;apikey&gt;</span>
                          <v-tooltip top max-width="600" transition="v-scale-transition" color="#0f0f0f">
                          <template v-slot:activator="{ on }">
                            <span v-on="on" class='ml-1 px-1 py-1 pill3'><v-icon style="margin-bottom: 2px;" x-small color="yellow">mdi-asterisk</v-icon></span>
                          </template>
                          <span style="color: yellow;">Required Field</span>
                        </v-tooltip>
                         - Your servers api key which you can get and refresh via /apikey.                         
                      <br>
                      <span class='px-2 py-1 pill1'>Content-Type</span><span class='px-2 py-1 pill2'>application/json; charset=utf-8</span>
                            <v-tooltip top max-width="600" transition="v-scale-transition" color="#0f0f0f">
                          <template v-slot:activator="{ on }">
                            <span v-on="on" class='ml-1 px-1 py-1 pill3'><v-icon style="margin-bottom: 2px;" x-small color="yellow">mdi-asterisk</v-icon></span>
                          </template>
                          <span style="color: yellow;">Required Field</span>
                        </v-tooltip> - Defines the content type.                         
                          <br>
                  </v-expansion-panel-content>
              </v-expansion-panel>
                   <v-expansion-panel class="my-1">
                  <v-expansion-panel-header style="font-size: 18px;" color="#18191c">
                    <v-row>
                      <div><span class='px-2 py-1 field2'>Body</span> <span style="color: grey; font-size: 15px;" class="mx-2"></span></div><br>
                    </v-row>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content style="background-color: #1c1d21a6; line-height: 30px;" class="pt-5">

                    <span class='px-2 py-1 field1'>mentions</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The roles you want to ping, separate multiple entries with a comma.<br>
                  
                 <span class='px-2 py-1 field1'>title</span><span class='px-2 py-1 field2'><button @click="title = !title" label>
                            <span style="color: #adb1b6;">&lt;object&gt;</span><v-icon v-if="!title" small color="#adb1b6">mdi-chevron-down</v-icon><v-icon v-else small color="#adb1b6">mdi-chevron-up</v-icon>
                          </button></span> - The title field of this embed.<br>
                          <div style="border-left: 2px solid grey; line-height: 30px;" class="ml-5 px-3 my-2 mb-5" v-if="title">
                            <span class='px-2 py-1 field1'>text</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The title text.<br>
                            <span class='px-2 py-1 field1'>URL</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The URL the title will link to.<br>
                          </div>
                    <span class='px-2 py-1 field1'>description</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The description for this embed.<br>
                    <span class='px-2 py-1 field1'>imageURL</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The image URL for this embed.<br>
                    <span class='px-2 py-1 field1'>thumbnailURL</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The thumbnail URL for this embed.<br>
                    <span class='px-2 py-1 field1'>color</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The embed hex color.<br>

                    <span class='px-2 py-1 field1'>fields</span><span class='px-2 py-1 field2'><button @click="fields = !fields" label>
                            <span style="color: #adb1b6;">&lt;array of objects&gt;</span><v-icon v-if="!fields" small color="#adb1b6">mdi-chevron-down</v-icon><v-icon v-else small color="#adb1b6">mdi-chevron-up</v-icon>
                          </button></span> - The embed fields.<br>
                          <div style="border-left: 2px solid grey; line-height: 30px;" class="ml-5 px-3 my-2 mb-5" v-if="fields">
                            <span class='px-2 py-1 field1'>name</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The field name.<br>
                            <span class='px-2 py-1 field1'>value</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The field value.<br>
                            <span class='px-2 py-1 field1'>inline</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether the field is to be inline.<br>
                          </div>

                    <span class='px-2 py-1 field1'>author</span><span class='px-2 py-1 field2'><button @click="author = !author" label>
                            <span style="color: #adb1b6;">&lt;object&gt;</span><v-icon v-if="!author" small color="#adb1b6">mdi-chevron-down</v-icon><v-icon v-else small color="#adb1b6">mdi-chevron-up</v-icon>
                          </button></span> - The author field of this embed.<br>
                          <div style="border-left: 2px solid grey; line-height: 30px;" class="ml-5 px-3 my-2 mb-5" v-if="author">
                            <span class='px-2 py-1 field1'>name</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The name of the author.<br>
                            <span class='px-2 py-1 field1'>URL</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The URL the author field will point to.<br>
                            <span class='px-2 py-1 field1'>iconURL</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The URL of an image that will be displayed.<br>
                          </div>
                         
                 <span class='px-2 py-1 field1'>footer</span><span class='px-2 py-1 field2'><button @click="footer = !footer" label>
                            <span style="color: #adb1b6;">&lt;object&gt;</span><v-icon v-if="!footer" small color="#adb1b6">mdi-chevron-down</v-icon><v-icon v-else small color="#adb1b6">mdi-chevron-up</v-icon>
                          </button></span> - The footer field of this embed.<br>
                          <div style="border-left: 2px solid grey; line-height: 30px;" class="ml-5 px-3 my-2 mb-5" v-if="footer">
                            <span class='px-2 py-1 field1'>text</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The footer text.<br>
                            <span class='px-2 py-1 field1'>iconURL</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The URL of an image that will be displayed.<br>
                          </div>

                  </v-expansion-panel-content>
              </v-expansion-panel>
                   <h1 class="mt-10 mb-7">Response Structure</h1>

                   <v-expansion-panel class="my-1">
                  <v-expansion-panel-header style="font-size: 18px;" color="#18191c">
                    <v-row>
                      <div><span class='px-2 py-1 field2'>Body</span></div><br>
                    </v-row>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content style="background-color: #1c1d21a6; line-height: 30px;" class="pt-5">
                    <span class='px-2 py-1 field1'>status</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - Indicates the success or failure of the request.<br>
                    <span class='px-2 py-1 field1'>embed</span><span class='px-2 py-1 field2'><button @click="event = !event" label>
                            <span style="color: #adb1b6;">&lt;object&gt;</span><v-icon v-if="!event" small color="#adb1b6">mdi-chevron-down</v-icon><v-icon v-else small color="#adb1b6">mdi-chevron-up</v-icon>
                          </button></span> - The embed object.<br>
                          <div style="border-left: 2px solid grey; line-height: 30px;" class="ml-5 px-3 my-2 mb-5" v-if="event">
                    
                    <span class='px-2 py-1 field1'>mentions</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The roles you want to ping, separate multiple entries with a comma.<br>
                    <span class='px-2 py-1 field1'>title</span><span class='px-2 py-1 field2'><button @click="title = !title" label>
                            <span style="color: #adb1b6;">&lt;object&gt;</span><v-icon v-if="!title" small color="#adb1b6">mdi-chevron-down</v-icon><v-icon v-else small color="#adb1b6">mdi-chevron-up</v-icon>
                          </button></span> - The title field of this embed.<br>
                          <div style="border-left: 2px solid grey; line-height: 30px;" class="ml-5 px-3 my-2 mb-5" v-if="title">
                            <span class='px-2 py-1 field1'>text</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The title text.<br>
                            <span class='px-2 py-1 field1'>URL</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The URL the title will link to.<br>
                          </div>                    
                    <span class='px-2 py-1 field1'>description</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The description for this embed.<br>
                    <span class='px-2 py-1 field1'>imageURL</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The image URL for this embed.<br>
                    <span class='px-2 py-1 field1'>thumbnailURL</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The thumbnail URL for this embed.<br>
                    <span class='px-2 py-1 field1'>color</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The embed hex color.<br>

                    <span class='px-2 py-1 field1'>fields</span><span class='px-2 py-1 field2'><button @click="fields = !fields" label>
                            <span style="color: #adb1b6;">&lt;array of objects&gt;</span><v-icon v-if="!fields" small color="#adb1b6">mdi-chevron-down</v-icon><v-icon v-else small color="#adb1b6">mdi-chevron-up</v-icon>
                          </button></span> - The embed fields.<br>
                          <div style="border-left: 2px solid grey; line-height: 30px;" class="ml-5 px-3 my-2 mb-5" v-if="fields">
                            <span class='px-2 py-1 field1'>name</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The field name.<br>
                            <span class='px-2 py-1 field1'>value</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The field value.<br>
                            <span class='px-2 py-1 field1'>inline</span><span class='px-2 py-1 field2'>&lt;boolean&gt;</span> - whether the field is to be inline.<br>
                          </div>

                    <span class='px-2 py-1 field1'>author</span><span class='px-2 py-1 field2'><button @click="author = !author" label>
                            <span style="color: #adb1b6;">&lt;object&gt;</span><v-icon v-if="!author" small color="#adb1b6">mdi-chevron-down</v-icon><v-icon v-else small color="#adb1b6">mdi-chevron-up</v-icon>
                          </button></span> - The author field of the embed.<br>
                          <div style="border-left: 2px solid grey; line-height: 30px;" class="ml-5 px-3 my-2 mb-5" v-if="author">
                            <span class='px-2 py-1 field1'>name</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The name of the author.<br>
                            <span class='px-2 py-1 field1'>URL</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The URL the author field will point to.<br>
                            <span class='px-2 py-1 field1'>iconURL</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The URL of an image that will be displayed.<br>
                          </div>
                         
                 <span class='px-2 py-1 field1'>footer</span><span class='px-2 py-1 field2'><button @click="footer = !footer" label>
                            <span style="color: #adb1b6;">&lt;object&gt;</span><v-icon v-if="!footer" small color="#adb1b6">mdi-chevron-down</v-icon><v-icon v-else small color="#adb1b6">mdi-chevron-up</v-icon>
                          </button></span> - The footer field of the embed.<br>
                          <div style="border-left: 2px solid grey; line-height: 30px;" class="ml-5 px-3 my-2 mb-5" v-if="footer">
                            <span class='px-2 py-1 field1'>text</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The footer text.<br>
                            <span class='px-2 py-1 field1'>iconURL</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The URL of an image that will be displayed.<br>
                          </div>
  </div>

                  </v-expansion-panel-content>
              </v-expansion-panel>
           
                  </v-expansion-panel-content>
              </v-expansion-panel>









              <v-expansion-panel active-class="panel" class="my-1">
                  <v-expansion-panel-header style="font-size: 15px;" color="#18191c">
                    <v-row>
                      <v-col cols="auto" class="mb-0" style="min-width: 90px; text-align: center; justify-content: center; padding-top: 35px;">
                        <div style="font-weight: 800; font-size: 20px; color: #2596be; margin: auto; text-align: center;">GET</div>
                      </v-col>
                      <v-col>
                    <v-col cols="12" class="mb-n4">
                      <span style="word-break: break-word;">https://raid-helper.dev/api/v2/servers/<span style="color: purple;">SERVERID</span>/entities/<span style="color: purple;">ENTITYID</span>/dkp</span>
                    </v-col>
                    <v-col cols="12" class="mb-0">
                    <span style="color:gray; font-size: 15px;">Fetch the current DKP of members in your server.</span>
                    </v-col>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content style="background-color: #373a3e;" class="pt-5">
                                                                                 <v-row justify="center">
                  <v-btn style="text-transform: none;" elevation="3" depressed x-small color="purple darken-2" class="pa-2 mr-3 mb-8 mt-5" @click="copyText('https://raid-helper.dev/api/v2/servers/SERVERID/entities/ENTITYID/dkp')">Copy URL</v-btn>
                  <v-btn style="text-transform: none;" elevation="3" depressed x-small color="purple darken-2" class="pa-2 mr-3 mb-8 mt-5" @click="copyText('curl --request GET \
  --url https://raid-helper.dev/api/v2/servers/SERVERID/entities/ENTITYID/dkp \
  --header \'Authorization: enter your token here\' \
  --header \'Content-Type: application/json\'')">Copy cURL</v-btn>
                      </v-row>	
                   This request is used to fetch the current DKP value of a member or the members of an entity in your server. Entities are for example: A Discord user, a Discord server, a Discord role or a Raid-Helper event.
                   If a server ID is provided, the bot will include members that have had their DKP altered before. If an event ID is provided, the bot will include all sign-ups except Absent/Declined. 
                   If a role ID is provided, the bot will include all members with that role.

                   <h1 class="mt-10 mb-7">Request Structure</h1>
                   <v-expansion-panel class="my-1">
                  <v-expansion-panel-header style="font-size: 18px;" color="#18191c">
                    <v-row>
                      <div><span class='px-2 py-1 field2'>Headers</span> <span style="color: grey; font-size: 15px;" class="mx-2"></span></div><br>
                    </v-row>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content style="background-color: #1c1d21a6; line-height: 30px;" class="pt-5">
                    <span class='px-2 py-1 pill1'>Authorization</span><span class='px-2 py-1 pill2'>&lt;apikey&gt;</span>
                          <v-tooltip top max-width="600" transition="v-scale-transition" color="#0f0f0f">
                          <template v-slot:activator="{ on }">
                            <span v-on="on" class='ml-1 px-1 py-1 pill3'><v-icon style="margin-bottom: 2px;" x-small color="yellow">mdi-asterisk</v-icon></span>
                          </template>
                          <span style="color: yellow;">Required Field</span>
                        </v-tooltip> - Your servers api key which you can get and refresh via /apikey.                         
                      <br>
                      <span class='px-2 py-1 pill1'>Content-Type</span><span class='px-2 py-1 pill2'>application/json; charset=utf-8</span>
                            <v-tooltip top max-width="600" transition="v-scale-transition" color="#0f0f0f">
                          <template v-slot:activator="{ on }">
                            <span v-on="on" class='ml-1 px-1 py-1 pill3'><v-icon style="margin-bottom: 2px;" x-small color="yellow">mdi-asterisk</v-icon></span>
                          </template>
                          <span style="color: yellow;">Required Field</span>
                        </v-tooltip> - Defines the content type.                         
                          <br>
                  </v-expansion-panel-content>
              </v-expansion-panel>
                   
                   <h1 class="mt-10 mb-7">Response Structure</h1>

                   <v-expansion-panel class="my-1">
                  <v-expansion-panel-header style="font-size: 18px;" color="#18191c">
                    <v-row>
                      <div><span class='px-2 py-1 field2'>Body</span></div><br>
                    </v-row>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content style="background-color: #1c1d21a6; line-height: 30px;" class="pt-5">
                                 <span class='px-2 py-1 field1'>result</span><span class='px-2 py-1 field2'><button @click="result = !result" label>
                            <span style="color: #adb1b6;">&lt;array of objects&gt;</span><v-icon v-if="!result" small color="#adb1b6">mdi-chevron-down</v-icon><v-icon v-else small color="#adb1b6">mdi-chevron-up</v-icon>
                          </button></span> - the array containing each member of the requested entity.<br>
                          <div style="border-left: 2px solid grey; line-height: 30px;" class="ml-5 px-3 my-2 mb-5" v-if="result">
                            <span class='px-2 py-1 field1'>name</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The members name.<br>
                            <span class='px-2 py-1 field1'>id</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The members ID.<br>
                            <span class='px-2 py-1 field1'>dkp</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The members current dkp value.<br>
                          </div>

                  </v-expansion-panel-content>
              </v-expansion-panel>
           
                  </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel active-class="panel" class="my-1">
                  <v-expansion-panel-header style="font-size: 15px;" color="#18191c">
                    <v-row>
                      <v-col cols="auto" class="mb-0" style="min-width: 90px; text-align: center; justify-content: center; padding-top: 35px;">
                        <div style="font-weight: 800; font-size: 20px; color: #339933; margin: auto; text-align: center;">PATCH</div>
                      </v-col>
                      <v-col>
                    <v-col cols="12" class="mb-n4">
                      <span style="word-break: break-word;">https://raid-helper.dev/api/v2/servers/<span style="color: purple;">SERVERID</span>/entities/<span style="color: purple;">ENTITYID</span>/dkp</span>
                    </v-col>
                    <v-col cols="12" class="mb-0">
                    <span style="color:gray; font-size: 15px;">Manipulate the current DKP of members in your server.</span>
                    </v-col>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content style="background-color: #373a3e;" class="pt-5">
                                                                                                     <v-row justify="center">
                  <v-btn style="text-transform: none;" elevation="3" depressed x-small color="purple darken-2" class="pa-2 mr-3 mb-8 mt-5" @click="copyText('https://raid-helper.dev/api/v2/servers/SERVERID/entities/ENTITYID/dkp')">Copy URL</v-btn>
                  <v-btn style="text-transform: none;" elevation="3" depressed x-small color="purple darken-2" class="pa-2 mr-3 mb-8 mt-5" @click="copyText(curl3)">Copy cURL</v-btn>
                      </v-row>	
                   This request is used to manipulate the current DKP value of a member or the members of an entity in your server. Entities are for example: A Discord user, a Discord server, a Discord role or a Raid-Helper event.
                   If a server ID is provided, the bot will include all members that have had their DKP altered before. If an event ID is provided, the bot will include all sign-ups except Absent/Declined. 
                   If a role ID is provided, the bot will include all members with that role.

                   <h1 class="mt-10 mb-7">Request Structure</h1>

                    <v-expansion-panel class="my-1">
                  <v-expansion-panel-header style="font-size: 18px;" color="#18191c">
                    <v-row>
                      <div><span class='px-2 py-1 field2'>Headers</span> <span style="color: grey; font-size: 15px;" class="mx-2"></span></div><br>
                    </v-row>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content style="background-color: #1c1d21a6; line-height: 30px;" class="pt-5">
                    <span class='px-2 py-1 pill1'>Authorization</span><span class='px-2 py-1 pill2'>&lt;apikey&gt;</span>
                          <v-tooltip top max-width="600" transition="v-scale-transition" color="#0f0f0f">
                          <template v-slot:activator="{ on }">
                            <span v-on="on" class='ml-1 px-1 py-1 pill3'><v-icon style="margin-bottom: 2px;" x-small color="yellow">mdi-asterisk</v-icon></span>
                          </template>
                          <span style="color: yellow;">Required Field</span>
                        </v-tooltip> - Your servers api key which you can get and refresh via /apikey.                         
                      <br>
                      <span class='px-2 py-1 pill1'>Content-Type</span><span class='px-2 py-1 pill2'>application/json; charset=utf-8</span>
                            <v-tooltip top max-width="600" transition="v-scale-transition" color="#0f0f0f">
                          <template v-slot:activator="{ on }">
                            <span v-on="on" class='ml-1 px-1 py-1 pill3'><v-icon style="margin-bottom: 2px;" x-small color="yellow">mdi-asterisk</v-icon></span>
                          </template>
                          <span style="color: yellow;">Required Field</span>
                        </v-tooltip> - Defines the content type.                         
                          <br>
                  </v-expansion-panel-content>
              </v-expansion-panel>
                   <v-expansion-panel class="my-1">
                  <v-expansion-panel-header style="font-size: 18px;" color="#18191c">
                    <v-row>
                      <div><span class='px-2 py-1 field2'>Body</span> <span style="color: grey; font-size: 15px;" class="mx-2"></span></div><br>
                    </v-row>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content style="background-color: #1c1d21a6; line-height: 30px;" class="pt-5">

                    <span class='px-2 py-1 pill1'>operation</span><span class='px-2 py-1 pill2'>&lt;string&gt;</span>
                          <v-tooltip top max-width="600" transition="v-scale-transition" color="#0f0f0f">
                          <template v-slot:activator="{ on }">
                            <span v-on="on" class='ml-1 px-1 py-1 pill3'><v-icon style="margin-bottom: 2px;" x-small color="yellow">mdi-asterisk</v-icon></span>
                          </template>
                          <span style="color: yellow;">Required Field</span>
                        </v-tooltip> - The operation that will be applied (add, subtract, multiply, divide, split, set).
                      <br>
                    <span class='px-2 py-1 pill1'>value</span><span class='px-2 py-1 pill2'>&lt;string&gt;</span>
                          <v-tooltip top max-width="600" transition="v-scale-transition" color="#0f0f0f">
                          <template v-slot:activator="{ on }">
                            <span v-on="on" class='ml-1 px-1 py-1 pill3'><v-icon style="margin-bottom: 2px;" x-small color="yellow">mdi-asterisk</v-icon></span>
                          </template>
                          <span style="color: yellow;">Required Field</span>
                        </v-tooltip> - The value that will be used in the calculation.
                      <br>
                    <span class='px-2 py-1 field1'>description</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - An optional description that will be shown on the log message if a dkp_channel is set.<br>

                  </v-expansion-panel-content>
              </v-expansion-panel>
                   
                   <h1 class="mt-10 mb-7">Response Structure</h1>

                   <v-expansion-panel class="my-1">
                  <v-expansion-panel-header style="font-size: 18px;" color="#18191c">
                    <v-row>
                      <div><span class='px-2 py-1 field2'>Body</span></div><br>
                    </v-row>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content style="background-color: #1c1d21a6; line-height: 30px;" class="pt-5">
                                 <span class='px-2 py-1 field1'>result</span><span class='px-2 py-1 field2'><button @click="result = !result" label>
                            <span style="color: #adb1b6;">&lt;array of objects&gt;</span><v-icon v-if="!result" small color="#adb1b6">mdi-chevron-down</v-icon><v-icon v-else small color="#adb1b6">mdi-chevron-up</v-icon>
                          </button></span> - the array containing each member of the requested entity.<br>
                          <div style="border-left: 2px solid grey; line-height: 30px;" class="ml-5 px-3 my-2 mb-5" v-if="result">
                            <span class='px-2 py-1 field1'>name</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The members name.<br>
                            <span class='px-2 py-1 field1'>id</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The members ID.<br>
                            <span class='px-2 py-1 field1'>dkp</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The members current dkp value.<br>
                          </div>

                  </v-expansion-panel-content>
              </v-expansion-panel>
           
                  </v-expansion-panel-content>
              </v-expansion-panel>

              









              <v-expansion-panel active-class="panel" class="my-1">
                  <v-expansion-panel-header style="font-size: 15px;" color="#18191c">
                    <v-row>
                      <v-col cols="auto" class="mb-0" style="min-width: 90px; text-align: center; justify-content: center; padding-top: 35px;">
                        <div style="font-weight: 800; font-size: 20px; color: #2596be; margin: auto; text-align: center;">GET</div>
                      </v-col>
                      <v-col>
                    <v-col cols="12" class="mb-n4">
                      <span style="word-break: break-word;">https://raid-helper.dev/api/v2/servers/<span style="color: purple;">SERVERID</span>/attendance</span>
                    </v-col>
                    <v-col cols="12" class="mb-0">
                    <span style="color:gray; font-size: 15px;">Fetch the attendance statistics for your server.</span>
                    </v-col>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content style="background-color: #373a3e;" class="pt-5">
                      <v-row justify="center">
                  <v-btn style="text-transform: none;" elevation="3" depressed x-small color="purple darken-2" class="pa-2 mr-3 mb-8 mt-5" @click="copyText('https://raid-helper.dev/api/v2/servers/SERVERID/attendance')">Copy URL</v-btn>
                  <v-btn style="text-transform: none;" elevation="3" depressed x-small color="purple darken-2" class="pa-2 mr-3 mb-8 mt-5" @click="copyText('curl --request GET   --url https://raid-helper.dev/api/v2/servers/SERVERID/attendance   --header \'Authorization: enter your token here\'   --header \'Content-Type: application/json; charset=utf-8\'')">Copy cURL</v-btn>
                      </v-row>	
                   This request is used to fetch the attendance statistics for your server. Only closed events are included in the attendance calculation. Roles named Bench, Late, Tentative, Absence, Maybe and Declined are
                   not included in the calculation. You can narrow the query down by the timeframe viewed, the channels the events are in and by the attendance tag used on the events. 
                   <h1 class="mt-10 mb-7">Request Structure</h1>
                   <v-expansion-panel class="my-1">
                  <v-expansion-panel-header style="font-size: 18px;" color="#18191c">
                    <v-row>
                      <div><span class='px-2 py-1 field2'>Headers</span> <span style="color: grey; font-size: 15px;" class="mx-2"></span></div><br>
                    </v-row>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content style="background-color: #1c1d21a6; line-height: 30px;" class="pt-5">
                    <span class='px-2 py-1 pill1'>Authorization</span><span class='px-2 py-1 pill2'>&lt;apikey&gt;</span>
                          <v-tooltip top max-width="600" transition="v-scale-transition" color="#0f0f0f">
                          <template v-slot:activator="{ on }">
                            <span v-on="on" class='ml-1 px-1 py-1 pill3'><v-icon style="margin-bottom: 2px;" x-small color="yellow">mdi-asterisk</v-icon></span>
                          </template>
                          <span style="color: yellow;">Required Field</span>
                        </v-tooltip> - Your servers api key which you can get and refresh via /apikey.                         
                      <br>
                      <span class='px-2 py-1 pill1'>Content-Type</span><span class='px-2 py-1 pill2'>application/json; charset=utf-8</span> - Defines the content type.                         
                          <br>
                    <span class='px-2 py-1 field1'>TagFilter</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The attendance tags to filter by. All tags are included if none provided.<br>
                    <span class='px-2 py-1 field1'>ChannelFilter</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The channel ids to filter by. All channels are included if none provided.<br>
                    <span class='px-2 py-1 field1'>TimeFilterStart</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The unix timestamp of the earliest event to include.<br>
                    <span class='px-2 py-1 field1'>TimeFilterEnd</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The unix timestamp of the latest event to include.<br>
                    
                  </v-expansion-panel-content>
              </v-expansion-panel>
             
                   <h1 class="mt-10 mb-7">Response Structure</h1>

                   <v-expansion-panel class="my-1">
                  <v-expansion-panel-header style="font-size: 18px;" color="#18191c">
                    <v-row>
                      <div><span class='px-2 py-1 field2'>Body</span></div><br>
                    </v-row>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content style="background-color: #1c1d21a6; line-height: 30px;" class="pt-5">
                    
                    <span class='px-2 py-1 field1'>tagFilter</span><span class='px-2 py-1 field2'>&lt;array of strings&gt;</span> - The attendance tags to filter by. Default is to include all tags that are not set to false.<br>
                    <span class='px-2 py-1 field1'>channelFilter</span><span class='px-2 py-1 field2'>&lt;array of strings&gt;</span> - The channelIds to filter by. default is to include all event channels.<br>
                    <span class='px-2 py-1 field1'>timeFilterStart</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The unix timestamp of the earliest event to include.<br>
                    <span class='px-2 py-1 field1'>timeFilterEnd</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The unix timestamp of the latest event to include.<br>
                    <span class='px-2 py-1 field1'>result</span><span class='px-2 py-1 field2'><button @click="result = !result" label>
                            <span style="color: #adb1b6;">&lt;array of objects&gt;</span><v-icon v-if="!result" small color="#adb1b6">mdi-chevron-down</v-icon><v-icon v-else small color="#adb1b6">mdi-chevron-up</v-icon>
                          </button></span> - the array containing the attendance statistics for the queried events.<br>
                          <div style="border-left: 2px solid grey; line-height: 30px;" class="ml-5 px-3 my-2 mb-5" v-if="result">
                            <span class='px-2 py-1 field1'>id</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The members ID.<br>
                            <span class='px-2 py-1 field1'>name</span><span class='px-2 py-1 field2'>&lt;string&gt;</span> - The members name.<br>
                            <span class='px-2 py-1 field1'>attended</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The members amount of attended events.<br>
                            <span class='px-2 py-1 field1'>percentage</span><span class='px-2 py-1 field2'>&lt;number&gt;</span> - The members attendance percentage of the queried events.<br>
                          </div>

                  </v-expansion-panel-content>
              </v-expansion-panel>
           
                  </v-expansion-panel-content>
              </v-expansion-panel>
                     </v-expansion-panels>
  </v-card-text>
  </v-card>

            <v-snackbar elevation="0" color="green" v-model="snackbar" timeout="1000" ><span style="font-size:15px;">Copied to clipboard!</span></v-snackbar>

</v-container>
</template>
<script>


export default {
  data() {
    return {
      snackbar: false,
      postedEvents: false,
      scheduledEvents: false,
      showTemplates: false,
      advancedSettings: false,
      event: false,
      signUps: false,
      classLimits: false,
      roleLimits: false,
      roles: false,
      classes: false,
      specs: false,
      title: false,
      author: false,
      footer: false,
      fields: false,
      result: false,
      softres: false,
      softresInstance: false,
      softresFaction: false,
      softresBotBehaviour: false,
      announcement: false,
      curl1: "curl --request POST \
  --url https://raid-helper.dev/api/v2/servers/SERVERID/channels/CHANNELID/event \
  --header 'Authorization: enter your token here' \
  --header 'Content-Type: application/json' \
  --data '{ \"leaderId\": \"LEADERID\"}'",
      curl2: "curl --request POST \
  --url https://raid-helper.dev/api/v2/servers/SERVERID/channels/CHANNELID/embed \
  --header 'Authorization: enter your token here' \
  --header 'Content-Type: application/json' \
  --data '{ \"description\": \"DESCRIPTION\"}'",
      curl3: "curl --request PATCH \
  --url https://raid-helper.dev/api/v2/servers/SERVERID/entities/ENTITYID/dkp \
  --header 'Authorization: TOKEN' \
  --header 'Content-Type: application/json' \
  --data '{ \"operation\": \"add\", \"value\": 50}'",
      curl4: "curl --request POST \
  --url https://raid-helper.dev/api/v2/events/EVENTID/signups \
  --header 'Authorization: TOKEN' \
  --header 'Content-Type: application/json' \
  --data '{ \"userId\": \"USERID\", \"className\": \"CLASSNAME\", \"specName\": \"SPECNAME\"}'",
      curl5: "curl --request PATCH \
  --url https://raid-helper.dev/api/v2/events/EVENTID \
  --header 'Authorization: TOKEN' \
  --header 'Content-Type: application/json' \
  --data '{ \"title\": \"TITLE\"}'",
        curl6: "curl --request DELETE \
  --url https://raid-helper.dev/api/v2/events/EVENTID \
  --header 'Authorization: TOKEN'",
      templates: [

            {
                id: 1,
                name: "Standard",
                image: "831430831809626142",
            },

            {
                id: 2,
                name: "WoW Classic",
                image: "599020379125841954",
            },
            {
                id: 3,
                name: "WoW TBC",
                image: "599020379125841954",
            },

            {
                id: 4,
                name: "WoW WotLK",
                image: "732179749480300546",
            },
            {
                id: 37,
                name: "WoW Classic: SoD",
                image: "599020379125841954",
            },
            {
                id: 38,
                name: "WoW Cataclysm",
                image: "599020379125841954",
            },

            {
                id: 5,
                name: "WoW Retail 1",
                image: "599020379125841954",
            },

            {
                id: 6,
                name: "WoW Retail 2",
                image: "599020379125841954",
            },

            {
                id: 7,
                name: "Default RPG",
                image: "598966084091248646",
            },


            {
                id: 8,
                name: "Basic",
                image: "596014739398721536",
            },

            {
                id: 9,
                name: "Custom Colors",
                image: "606127767528144917",
            },

            {
                id: 10,
                name: "Final Fantasy XIV",
                image: "600228478297571328",
            },

            {
                id: 11,
                name: "The Elder Scrolls Online",
                image: "683822339431071756",
            },

            {
                id: 12,
                name: "Guild Wars 2",
                image: "686307981549502508",
            },

            {
                id: 13,
                name: "Star Wars: TOR",
                image: "794787500945571851",
            },

            {
                id: 14,
                name: "League of Legends",
                image: "796548085475311617",
            },

            {
                id: 15,
                name: "Lord of the Rings Online",
                image: "796586962785533962",
            },

            {
                id: 16,
                name: "Destiny 2",
                image: "796891777683554345",
            },


            {
                id: 17,
                name: "Custom Template 17",
                image: "795081328588226580",
            },
            {
                id: 18,
                name: "Custom Template 18",
                image: "795081328588226580",
            },
            {
                id: 19,
                name: "Custom Template 19",
                image: "795081328588226580",
            },
            {
                id: 20,
                name: "Custom Template 20",
                image: "795081328588226580",
            },
            {
                id: 21,
                name: "Custom Template 21",
                image: "795081328588226580",
            },
            {
                id: 22,
                name: "Custom Template 22",
                image: "795081328588226580",
            },


            {
                id: 23,
                name: "Poll",
                image: "830737466532429855",
            },

            {
                id: 24,
                name: "Sorted Strawpoll",
                image: "830736955053703199",
            },

            {
                id: 25,
                name: "Unsorted Strawpoll",
                image: "747844490705895454",
            },
            {
                id: 26,
                name: "Swords of Legends",
                image: "869099081862086667",
            },
            {
                id: 27,
                name: "Lost Ark",
                image: "877979009038827591",
            },
            {
                id: 28,
                name: "Dark Age of Camelot",
                image: "878305940858892348",
            },
            {
                id: 29,
                name: "New World 1",
                image: "869104112048934932",
            },
            {
                id: 30,
                name: "New World 2",
                image: "869104112048934932",
            },
            {
                id: 39,
                name: "Tarisland",
                image: "1250504567678963722",
            },
                        {
                id: 40,
                name: "Throne & Liberty",
                image: "1283209903883812875",
            },
            
        ],
    }
  },
  methods: {
      copyText(value) {
      navigator.clipboard.writeText(value);
      this.snackbar = true;
    },
  },
  mounted() {
  },
  created(){
    document.title = "Raid-Helper - API Documentation"
  },

  
};
</script>
<style >
 
@import url(https://fonts.googleapis.com/css?family=Roboto:400,500,700);

a,a.header-anchor:hover{
    text-decoration:none
}
 
.discord-embed{
    color:#dcddde;
    display:flex;
    justify-content: center;
    margin-top:28px;
    margin-bottom:8px;
    font-size:13px;
    line-height:150%
}

.discord-embed .discord-embed-left-border{
    background-color:#202225;
    flex-shrink:0;
    width:4px;
    border-radius:4px 0 0 4px
}

.discord-embed .discord-embed-container{
    background-color:#2f3136;
    display:flex;
    flex-direction:column;
    max-width:420px;
    padding:8px 16px 16px;
    border:1px solid rgba(46,48,54,.6);
    border-radius:0 4px 4px 0
}

.discord-embed .discord-embed-content{
    display:flex;
}
.discord-embed .discord-embed-content .discord-embed-author{
    color:#fff;
    display:flex;
    align-items:center;
    font-weight:500;
    margin-top:8px
}

.discord-embed .discord-embed-content .discord-embed-author a{
    color:#fff;
    font-weight:500
}
.discord-embed .discord-embed-content .discord-embed-author .discord-embed-author-icon{
    width:24px;
    height:24px;
    margin-right:8px;
    border-radius:50%
}
.discord-embed .discord-embed-content .discord-embed-title{
    color:#fff;
    font-size:16px;
    font-weight:600;
    margin-top:8px
}
.discord-embed .discord-embed-content .discord-embed-title a{
    color:#00b0f4;
    font-weight:600
}
.discord-embed .discord-embed-content .discord-embed-description{
    margin-top:8px
}
.discord-embed .discord-embed-content .discord-embed-image{
    max-width:70%;
    margin-top:16px;
    border-radius:4px
}
.discord-embed .discord-embed-content .discord-embed-thumbnail{
    max-width:80px;
    max-height:60px;
    margin-left:16px;
    margin-top:8px;
    border-radius:4px;

}
.discord-embed .discord-embed-footer{
    color:#ffffff;
    display:flex;
    align-items:center;
    font-size:.85em;
    margin-top:8px
}
.discord-embed .discord-embed-footer .discord-embed-footer-icon{
    flex-shrink:0;
    width:20px;
    height:20px;
    margin-right:8px;
    border-radius:50%
}

.discord-embed .discord-embed-field{
    min-width:100%;
    margin-top:5px
}
.discord-embed .discord-embed-field.discord-embed-field-inline{
    flex-grow:1;
    flex-basis:auto;
    min-width:40px
}
.discord-embed .discord-embed-field .discord-embed-field-title{
    color:#ffffff;
    font-weight:700;
    margin-bottom:2px
}

.discord-embed .discord-embed-fields{
    display:flex;
    flex-wrap:wrap;
    margin-top:8px
}


.pill1 {
  background-color: #181a1c; 
  color: #dcd0d0; 
  border: 2px solid #272121; 
  border-right: 0px; 
  border-top-left-radius: 4px; 
  border-bottom-left-radius: 4px;
}

.pill2 {
  background-color: #292b2f; 
  color: #adb1b6; 
  border: 2px solid #212327; 
  border-left: 0px; 
  border-top-right-radius: 4px; 
  border-bottom-right-radius: 4px;
}

.pill3 {
  background-color: #292b2f; 
  color: #adb1b6; 
  border: 2px solid #272121; 
  border-radius: 4px; 
}
 
</style>